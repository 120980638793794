/*global kakao*/
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import useDidMountEffect from '../bin/common';
import styled from '@emotion/styled';

const KakaoMap = forwardRef((props, ref) => {
    const [map, setMap] = useState();
    const [marker, setMarker] = useState();
    const [addr, setAddr] = useState('');
    const [addr2, setAddr2] = useState('');
    const [zoneCode, setZoneCode] = useState('');
    const [logitude, setLogitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [mapInfo, setMapInfo] = useState(props?.openMapMode?.items);
    const address = props?.addressList;

    const handleAddrAndZoneCode = (fullAddr, zoneCode = '') => {
        setAddr(fullAddr);
        setZoneCode(zoneCode);
    };
    const handleLogitudeAndLatitude = (logitude, latitude) => {
        setLogitude(logitude);
        setLatitude(latitude);
    };
    useEffect(() => {
        // console.log(props?.openMapMode?.items);
        if (!mapInfo) return;
        setAddr(mapInfo.addr1);
        setZoneCode(mapInfo.postal_cd);
        setLogitude(mapInfo.logitude);
        setLatitude(mapInfo.latitude);
        setAddr2(mapInfo.addr2 === null ? '' : mapInfo.addr2);
    }, [mapInfo]);

    const onEnterKeyPress = (e) => {
        if (e.key == 'Enter') {
            onClickSearchButton();
        }
    };
    // Modal과 같이 사용하므로, Modal에 붙어 있는 확인버튼 눌렀을 때
    // 부모 컴포넌트로 데이터 넘기기 위한 함수
    const setAddrData = () => {
        const subAddr = document.getElementById('addr2').value;
        if (props.hasOwnProperty('setAddrInfo')) {
            if (addr == '') {
                alert('주소를 입력해 주세요');
                return;
            }


            const isDuplicate = address?.some((item) => item?.addr_seq !== mapInfo?.addr_seq && item?.addr1 === addr && (item?.addr2 === null && '') === addr2);
            if (isDuplicate) {
                alert('중복된 주소가 있습니다.');
                return;
            }

            props.setOpendMap(false);
            props.setAddrInfo(addr, subAddr, zoneCode, logitude, latitude, props.openMapMode);
        }

    };
    useImperativeHandle(ref, () => ({
        setAddrData
    }));

    const onClickSearchButton = () => {
        const searchAddr = document.getElementById('addr').value;
        if (searchAddr) {
            const geocoder = new window.kakao.maps.services.Geocoder();
            geocoder.addressSearch(
                searchAddr,  // 검색된 주소
                function (result: any, status: any) {
                    if (status === window.kakao.maps.services.Status.OK) {

                        if (result[0]?.road_address != null && Object.keys(result[0]?.road_address).length === 0) {
                            alert('주소가 옳바르지 않습니다. 다시 확인해 주세요');
                            return;
                        }
                        const addr = !!result[0].road_address
                            ? result[0].road_address.address_name.replace('특별시', '').replace('광역시', '').replace('특별자치도', '') +
                            (result[0].road_address.building_name ? ` (${result[0].road_address.building_name})` : '')
                            : result[0].address.address_name.replace('특별시', '').replace('광역시', '').replace('특별자치도', '');
                        const zoneCode = !!result[0].road_address ? result[0].road_address.zone_no : '';
                        handleAddrAndZoneCode(addr, zoneCode);
                        handleLogitudeAndLatitude(result[0].x, result[0].y);
                    }
                }
            );
        }
    };

    // 카카오맵 불러오기
    useEffect(() => {
        window.kakao.maps.load(() => {
            const container = document.getElementById('map');

            const options = {
                center: new window.kakao.maps.LatLng(37.5683629, 126.9784229),
                level: 3,
            };
            const _map = new window.kakao.maps.Map(container, options);
            setMap(_map);

            const _marker = new window.kakao.maps.Marker({position: _map.getCenter()});
            _marker.setMap(_map);
            setMarker(_marker);

        });
    }, []);

    // 맵 클릭시 맵과 마커 좌표 세팅
    useDidMountEffect(() => {
        if (map !== undefined) {

            window.kakao.maps.event.addListener(
                map,
                'click',
                function (mouseEvent: any) {
                    const geocoder = new window.kakao.maps.services.Geocoder();
                    geocoder.coord2Address(
                        mouseEvent.latLng.getLng(),
                        mouseEvent.latLng.getLat(),
                        (result: any, status: any) => {
                            if (status === window.kakao.maps.services.Status.OK) {
                                const addr = !!result[0].road_address
                                    ? result[0].road_address.address_name.replace('특별시', '').replace('광역시', '').replace('특별자치도', '') +
                                    (result[0].road_address.building_name ? ` (${result[0].road_address.building_name})` : '')
                                    : result[0].address.address_name.replace('특별시', '').replace('광역시', '').replace('특별자치도', '');
                                const zoneCode = !!result[0].road_address ? result[0].road_address.zone_no : '';

                                handleAddrAndZoneCode(addr, zoneCode);
                                handleLogitudeAndLatitude(mouseEvent.latLng.getLng(), mouseEvent.latLng.getLat());
                            }
                        });

                    setAddr2('');
                });
        }
    }, [map]);

    // 어떠한 입력으로 인해 좌표 변경 시 변경 좌표로 맵 이동과 마커 위치 변경
    useDidMountEffect(() => {
        if (map !== undefined) {
            const currentPos = new kakao.maps.LatLng(latitude, logitude);
            document.getElementById('addr').value = addr;
            map.panTo(currentPos);
            // 결과값으로 받은 위치를 마커로 표시합니다
            marker.setMap(null);
            marker.setPosition(currentPos);
            marker.setMap(map);
        }
    }, [logitude]);

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
                <Input id={'addr'} placeholder={'주소 또는 동으로 검색 후, 지도에서 위치를 선택해 주세요'}
                       onKeyDown={(e) => onEnterKeyPress(e)}/>
                <Button style={{marginTop: '3px'}} onClick={() => onClickSearchButton()}>검색</Button>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
                <Input id="addr2" maxLength={50} placeholder={'상세 주소를 입력해 주세요'} defaultValue={addr2}
                       style={{width: '100%'}}/>
            </div>

            <div id="map" style={{width: '500px', height: '500px'}}></div>
        </div>
    );
});

const Input = styled.input`
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    appearance: none;
    box-sizing: border-box;
    width: 90%;
    border: 0px;
    outline: none;
    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #FFF;
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    margin-bottom: 12px;

    :focus {

    }
`;
const Button = styled.button`
    display: flex;
    height: 32px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3279F5;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-decoration: none;
    outline: none;
    border: none;
    cursor: pointer;
`;
export default KakaoMap;