import styled from '@emotion/styled';
import ReactModal from 'react-modal';
import {ModalButton} from './ModalButton';
import {useWindowSize} from 'react-use';
import {useCallback, useEffect, useState} from 'react';
import {CompanyPlan} from '../bin/enum';
import axiosInstance from '../library/axios-index';
import {useNavigate} from 'react-router-dom';
import {companyState} from '../library/recoil/atoms/companyState';
import {useRecoilState} from 'recoil';
import {HOST_URL} from '../bin/env';

const customStyles = {
    overlay: {
        zIndex: '9998',
        background: 'rgba(0,0,0,0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        position: 'relative',
        marginTop: '50px',
        maxWidth: '100%',
        width: '60%',
        height: 'fit-content',
        maxHeight: '100%',
        borderRadius: 0,
        padding: 0,
        inset: 0
    },
};

const items = [
    '근태 관리',
    '노무 관리',
    '공사 일보',
    '지출 관리',
    '외주 관리',
    '장비 관리',
    '자재 관리',
    '구인 공고 (유료)',
];


function PlanSelectModal({
                             children,
                             opend,
                             closeModal,
                             header,
                             buttons,
                             okText,
                             cancelText = '취소',
                             handleOk,
                             maxHeightCheck,
                             widthCheck,
                             heightCheck
                         }) {
    const {width, height} = useWindowSize();
    const navigate = useNavigate();

    const [companyValue, setCompanyValue] = useRecoilState(companyState);

    const selectItemStyle = {
        style: {color: '#ffffff', backgroundColor: '#3279f5'},
        imageColor: 'white',
        lineColor: '#E2E8F0',
        itemColor: '#fff',
        textColor: '#fff'
    };
    const defaultItemStyle = {
        style: {color: '#333333', backgroundColor: '#ffffff'},
        imageColor: 'gray',
        lineColor: '#E2E8F0',
        itemColor: '#333',
        textColor: '#3279F5'
    };

    const [leftItemStyle, setLeftItemStyle] = useState(selectItemStyle);
    const [rightItemStyle, setRightItemStyle] = useState(defaultItemStyle);
    const [info, setInfo] = useState(null);


    // M0015D0001	베이직
    // M0015D0002	프리미엄
    // M0015D0003	엔터프라이즈
    // const [companyPlan, setCompanyPlan] = useState(CompanyPlan.PREMIUM);

    const onClickSelectButton = useCallback(async (e) => {

        let companyPlan = CompanyPlan.PREMIUM;

        if (e.target.id == 'leftSelect') {

            if (info.plan == CompanyPlan.PREMIUM) {
                return;
            }

            setLeftItemStyle(selectItemStyle);
            setRightItemStyle(defaultItemStyle);
            companyPlan = CompanyPlan.PREMIUM;
        } else {
            setLeftItemStyle(defaultItemStyle);
            setRightItemStyle(selectItemStyle);
            if (info.plan == CompanyPlan.ENTERPRISE) {
                return;
            }
            companyPlan = CompanyPlan.ENTERPRISE;
        }

        document.body.style.overflow = 'unset';

        if (companyValue?.stamp_img !== '' && companyValue?.biz_reg_img !== '' && companyValue?.corp_nm !== '' && companyValue?.ceo_nm !== '' && companyValue?.corp_reg_num !== '' && companyValue?.biz_reg_num !== '' && companyValue?.biz_type !== '' && companyValue?.biz_sector !== '' && companyValue?.addr1 !== '' && companyValue?.manager_nm !== '' && companyValue?.email_addr !== '' && companyValue?.manager_phone_num !== '') {
            // 정보가 았울땨
            try {
                const result = await fetchModify(companyValue, companyPlan);
                if (result.code === 200) {
                    setCompanyValue((prevState) => ({
                        ...prevState,
                        plan_id: companyPlan
                    }));

                    navigate('/welcome', {
                        state: {
                            plan: companyPlan,
                        },
                        replace: true,
                    });
                }

            } catch (e) {
                alert(e);
                throw e;
            }
        } else {
            // 정보가 없을때
            navigate('/company-info', {
                state: {
                    plan: companyPlan,
                },
                replace: true,
            });

        }


        // console.log(companyPlan)
    }, [leftItemStyle, rightItemStyle, info]);

    const fetchModify = async (formData, companyPlan) => {
        const token = localStorage.getItem('admin_token');
        const {data} = await axiosInstance.post(`${HOST_URL}/company/modifyInfo`, {
            lang: 'ko',
            uuid: 'string',
            token,
            ...formData,
            corp_seq: formData.corp_seq,
            biz_reg_num: formData.biz_reg_num1 + formData.biz_reg_num2 + formData.biz_reg_num3,
            corp_reg_num: formData.corp_reg_num1 + formData.corp_reg_num2,
            plan_id: companyPlan
        });

        return data;

    };
    useEffect(() => {

        (async () => {
            const token = localStorage.getItem('admin_token');
            const {data: {result}} = await axiosInstance.post(`/company/verifyUser`, {
                lang: 'ko',
                uuid: 'string',
                token
            });
            setInfo(result);
            if (result.plan == CompanyPlan.BASIC) {
                setLeftItemStyle(selectItemStyle);
                setRightItemStyle(defaultItemStyle);
            } else if (result.plan == CompanyPlan.PREMIUM) {
                setLeftItemStyle(defaultItemStyle);
                setRightItemStyle(selectItemStyle);
            }

        })();

    }, [opend]);

    return (
        <ReactModal
            isOpen={opend}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            style={{
                overlay: customStyles.overlay,
                content: {
                    ...customStyles.content,
                    width: widthCheck,
                    marginTop: 0,
                    height: heightCheck,
                    maxHeight: maxHeightCheck + 'px',
                    ...(width < 992 ? {
                        width: '100%',
                        maxWidth: '100%',
                    } : {}), ...(height < maxHeightCheck ? {
                        height: '90%'
                    } : {})
                }
            }}
            ariaHideApp={false}
        >
            <Padding>
                <Header>

                </Header>


                <Content>
                    <Container>
                        <Header>현장이 많으신가요?</Header>
                        <Header>
                            현장을 추가하시려면 등급을<span style={{fontWeight: '700'}}>&nbsp;업그레이드&nbsp;</span> 하셔야 합니다.
                        </Header>
                        <div style={{display: 'flex'}}>
                            <Box style={{marginRight: '30px', ...leftItemStyle.style}}>
                                <BoxTitle>Premium</BoxTitle>
                                <h2>프리미엄</h2>
                                <h5>5개 현장까지</h5>
                                <div
                                    style={{
                                        height: '1px',
                                        width: '160px',
                                        margin: '10px',
                                        backgroundColor: leftItemStyle.lineColor,
                                    }}
                                />
                                <div style={{display: 'flex'}}>
                                    <h1 style={{marginBottom: '5px'}}>2,900원</h1>
                                    <div
                                        style={{fontSize: '12px', marginTop: '23px', marginLeft: '5px'}}
                                    >
                                        {' '}
                                        / 월
                                    </div>
                                </div>
                                <h5 style={{marginBottom: '20px'}}>근로자당</h5>
                                <h4>체계적인 현장관리에 관심이</h4>
                                <h4>
                                    있으면서 평균{' '}
                                    <span
                                        style={{color: leftItemStyle.textColor, fontWeight: '600'}}>현장의 수가 5개 이하</span>
                                </h4>
                                <h4>인 중소기업에 추천합니다</h4>
                                <div
                                    style={{
                                        height: '1px',
                                        width: '160px',
                                        margin: '10px',
                                        backgroundColor: leftItemStyle.lineColor,
                                    }}
                                />

                                <List>
                                    {items.map((item, index) => (
                                        <Item key={index} style={{color: leftItemStyle.itemColor}}>
                                            <Checkmark
                                                src={`/assets/icons/check-${leftItemStyle.imageColor}.svg`}/> {item}
                                        </Item>
                                    ))}
                                </List>

                                <BoxButton id={'leftSelect'} onClick={(e) => onClickSelectButton(e)}>선 택</BoxButton>
                                <h5 style={{marginTop: '20px'}}>최소 인원 10명</h5>
                            </Box>

                            <Box style={{...rightItemStyle.style}}>
                                <BoxTitle>Enterprise</BoxTitle>
                                <h2>엔터프라이즈</h2>
                                <h5>현장 무제한</h5>
                                <div
                                    style={{
                                        height: '1px',
                                        width: '160px',
                                        margin: '10px',
                                        backgroundColor: rightItemStyle.lineColor,
                                    }}
                                />
                                <div style={{display: 'flex'}}>
                                    <h1 style={{marginBottom: '5px'}}>3,900원</h1>
                                    <div
                                        style={{fontSize: '12px', marginTop: '23px', marginLeft: '5px'}}
                                    >
                                        {' '}
                                        / 월
                                    </div>
                                </div>
                                <h5 style={{marginBottom: '20px'}}>근로자당</h5>
                                <h4>체계적인 현장관리에 관심이</h4>
                                <h4>
                                    있으면서 평균{' '}
                                    <span style={{color: rightItemStyle.textColor, fontWeight: '600'}}>
                                    현장의 수가 5개 초과
                                </span>
                                </h4>
                                <h4>하는 중소/중견기업에 추천합니다.</h4>
                                <div
                                    style={{
                                        height: '1px',
                                        width: '160px',
                                        margin: '10px',
                                        backgroundColor: rightItemStyle.lineColor,
                                    }}
                                />

                                <List>
                                    {items.map((item, index) => (
                                        <Item key={index} style={{color: rightItemStyle.itemColor}}>
                                            <Checkmark
                                                src={`/assets/icons/check-${rightItemStyle.imageColor}.svg`}/> {item}
                                        </Item>
                                    ))}
                                </List>
                                <BoxButton id={'rightSelect'} onClick={(e) => onClickSelectButton(e)}>선 택</BoxButton>
                                <h5 style={{marginTop: '20px'}}>최소 인원 10명</h5>
                            </Box>
                        </div>


                        {/*footer  */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#818791',
                            fontSize: '14px',
                            marginTop: '50px'
                        }}>
                            <Checkmark src="/assets/icons/alert-circle-gray.svg"/>
                            VAT 제외 금액이며, 회사 단위로 반영됩니다.
                        </div>
                    </Container>
                </Content>

                <Footer>
                    <div>
                    </div>

                    <ButtonGroup>
                        {
                            cancelText !== '' ? <ModalButton onClick={closeModal}>{cancelText}</ModalButton> :
                                <div></div>
                        }

                    </ButtonGroup>
                </Footer>
            </Padding>
        </ReactModal>
    );
}

const Padding = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-top: 12px;
    margin-bottom: 6px;
    padding: 16px 24px;

    & > div {
        width: 33.3%;

        //&:nth-child(2) {

        &:nth-of-type(2) {
            text-align: center;
        }
    }
`;

const Content = styled.div`
    height: 100%;
    overflow-y: auto;
    padding: 0 24px;

    -ms-overflow-style: none;
    -moz-overflow-style: none;
    -webkit-overflow-style: none;
    -o-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
    }
`;

const Container = styled.div`
    background: #f7fafc;
    //height: 78vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;

`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
`;

const SidebarButton = styled.img`
    cursor: pointer;
`;

export const BoxTitle = styled.div`
    width: 88px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebf2fe;
    color: #3279f5;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 20px;

    &.white-text {
        color: #3279f5;
        background-color: #ffffff;
    }
`;

export const BoxButton = styled.div`
    width: 160px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin-top: 20px;
    cursor: pointer;

    color: #3279f5;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;

    border-radius: 4px;
    border-style: solid;
    border-color: #3279f5;
    border-width: 1px;
`;

export const Box = styled.div`
    width: 200px;
    height: 495px;
    opacity: 100%;
    color: #333333;
    background-color: #ffffff;
    border-radius: 16px;
    margin-top: 15px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 26px;
        font-weight: 700;
        margin-top: 10px;
    }

    h2 {
        margintop: 20px;
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: 500;
    }

    h4 {
        height: 20px;
        font-size: 11px;
        font-weight: 400;
        margin: 0;
        //color: #4a5568;
    }

    h5 {
        font-size: 10px;
        font-weight: 400;
        margin: 0;
        //color: #4a5568;
    }
`;


export const List = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 10px;
    list-style-type: none;
    padding: 0;
`;

export const Item = styled.li`
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #333; /* 검정색 텍스트 */

    &.white-text {
        color: #fff; /* 흰색 텍스트 */
    }
`;

const Checkmark = styled.img`
    width: 14px;
    margin-right: 5px;
`;

export default PlanSelectModal;