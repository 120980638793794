import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import {InputBox} from '../../library/styled-components';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, {Quill} from 'react-quill';
import {ImageActions} from '@xeger/quill-image-actions';
import {ImageFormats} from '@xeger/quill-image-formats';
import FileUpload from '../../compontents/FileUpload';
import {htmlToText} from '../../bin/common';
import {useMutation} from '@tanstack/react-query';
import {CreateFAQ} from '../../library/admin-react-query';

Quill.register('modules/imageFormats', ImageFormats);
Quill.register('modules/imageActions', ImageActions);

const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
    'float',
    'height',
    'width'
];

const DashBoardForm = forwardRef((props, ref) => {
    const [editor, setEditor] = useState('');
    const [files, setFiles] = useState([]);

    const quillRef = useRef(null);
    const {register, control, handleSubmit, reset, setValue, getValues, setFocus, formState: {errors}} = useForm({
        defaultValues:
            {}
    });

    const dashBordData = props?.data;
    const modify = props.modify;
    const finish = props?.finish;
    // src만 추출
    const srcArray = [];
    // blopArray로 변환
    const blopArray = [];
    //FAQ 목록 API
    const {mutate: CreateFAQMutate, data: CreateFAQData} = useMutation({
        mutationKey: ['CreateFAQ'],
        mutationFn: CreateFAQ,
        onSuccess: (data) => {
            console.log('생성', data);
            if (data.done) {
                alert('추가 되었습니다.');
                finish();
            }

        },
        onError: (error) => {
            throw error;
        }
    });


    useEffect(() => {
        if (modify === false) return;
        setValue('subject', dashBordData.title);
        setEditor((dashBordData?.subject));
    }, []);
    // 최종 src url 저장할곳
    const urlArray = [];
    const gainSource = /(<img[^>]*src\s*=\s*[\"']?([^>\"']+)[\"']?[^>]*>)/g;

    const onSubmit = async (e) => {
        if (htmlToText(editor) === '') {
            alert('내용을 입력해주세요');
            return;
        }


        console.log('서버 주소 저장된 어레이: ', urlArray);
        console.log('editor', editor);
        console.log('files', files);

        const items = {
            subject: e.subject,
            content: editor,
            attachment1: files[0]?.photo || '',
            attachment2: files[1]?.photo || '',
            attachment3: files[2]?.photo || '',
            attachment4: files[3]?.photo || '',
            attachment5: files[4]?.photo || '',
        };

        if (modify) {
            // 수정
            console.log('수정');
        } else {
            // 생성
            CreateFAQMutate(items);
        }
    };

    const onError = (errors) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = errorKeys[0];
            setFocus(firstError);
            if (errors[firstError].type === 'noSpaces') {
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }
    };


    const modules = useMemo(() => {
        return {
            imageActions: {},
            imageFormats: {},
            toolbar: {
                container: [
                    [{header: [1, 2, false]}], // header 설정
                    ['bold', 'italic', 'underline', 'strike'], // 굵기, 기울기, 밑줄 등 부가 tool 설정
                    [{align: []}, {color: []}, {background: []}], // 정렬, 글자 색, 글자 배경색 설정
                    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}], // 리스트, 인덴트 설정
                    ['image'], // 링크, 이미지, 비디오 업로드 설정
                    ['clean'] // toolbar 설정 초기화 설정
                ],
            },
        };
    }, []);

    const onChagecontent = (e) => {
        setEditor(e);
    };


    return (
        <>
            <FieldGroup className="dashboard-form" ref={ref} onSubmit={handleSubmit(onSubmit, onError)}>
                <InputBox fulled>
                    <h6>등록일</h6>
                    <h5 style={{margin: 0}}>{modify ? dashBordData?.create_dt : dayjs().format('YYYY년 M월 D일')}</h5>
                </InputBox>
                <InputBox fulled>
                    <h6>제목</h6>
                    <input name="subject" type="text"
                           {...register('subject', {
                               validate: {
                                   register: (value) => value !== '' || '제목을 입력해주세요',
                                   noSpaces: (value) => value?.trim() !== '' || '제목을 입력해주세요',
                               }
                           })}
                    />
                </InputBox>
                <InputBox fulled>
                    <h6>내용</h6>
                    <div style={{minHeight: '370px'}} className="editor-wrap">
                        <ReactQuill theme="snow" value={editor} onChange={onChagecontent}
                                    modules={modules}
                                    formats={formats}
                                    ref={quillRef}
                        />
                    </div>
                </InputBox>
                <InputBox fulled>
                    <h6>첨부파일</h6>
                    <FileUpload files={files} setFiles={setFiles}/>
                </InputBox>
            </FieldGroup>
        </>
    );
});

const FieldGroup = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;

    > div {
        row-gap: 5px;
        display: flex;
        flex-direction: column;
    }

    textarea {
        width: 100%;
        min-height: 80px;
        border-radius: 6px;
        border: 1px solid #CBD5E0;
        box-sizing: border-box;
        // margin-top: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        resize: none;
        padding: 8px 12px;
        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    input {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    label {
        margin-left: 0;

        > span,
        input {
            padding: 0;

        }

        .MuiFormControlLabel-label {
            font-size: 15px;
        }

    }

    .editor-wrap > div {
        height: 370px;

        .ql-container {
            height: calc(100% - 42px);
        }
    }

    .ql-toolbar.ql-snow .ql-formats {
        margin-right: 0px;
    }

`;
export default DashBoardForm;
