import React from 'react';
import {InputBox, Select} from '../../library/styled-components';
import {addCommas, numberOnly, stringNumberToInt} from '../../bin/common';
import styled from '@emotion/styled';
import {Controller, useForm} from 'react-hook-form';
import axiosInstance from '../../library/axios-index';

const constManagerYnOptions = [
    {value: 'Y', label: '예'},
    {value: 'N', label: '아니오'},
];
const hireTypeOptions = [
    {value: 'F', label: '정규직'},
    {value: 'T', label: '계약직'},
    {value: 'D', label: '일용직'}
];


const salaryTypeOptions = [
    {value: 'Y', label: '연봉'},
    {value: 'M', label: '월급'},
    {value: 'D', label: '일급'}
];

const fieldsOrder = ['user_nm'
    , 'id_num',
    'phone_num',
    'personnel_num',
    'const_manager_yn',
    'team_seq',
    'rank_seq',
    'salary_amt',
    'bank_nm',
    'bank_acct',
    'join_dt'];


const ModifyEmployForm = ({modifyEmployComplete, employ, formRef, ...props}) => {
    const {ranks} = props;
    const {register, control, handleSubmit, reset, setValue, getValues, setFocus, formState: {errors}} = useForm({
        defaultValues: {
            user_nm: employ?.user_nm,
            id_num: employ?.id_num,
            phone_num: employ?.phone_num,
            personnel_num: employ?.personnel_num,
            addr1: employ?.addr1,
            addr2: employ?.addr2,
            const_manager_yn: constManagerYnOptions.find((e) => e.value === employ?.const_manager_yn),
            team_seq: {
                value: employ.team_seq,
                label: props?.teams.find(e => e.team_seq === employ.team_seq)?.team_nm
            },
            rank_seq: {
                value: employ.rank_seq,
                label: props?.ranks.find(e => e.rank_seq === employ.rank_seq)?.position_nm
            },
            hire_type: hireTypeOptions.find((e) => e.value === employ?.hire_type),
            salary_type: salaryTypeOptions.find((e) => e.value === employ.salary_type),
            salary_amt: employ?.salary_amt,
            bank_nm: employ?.bank_nm && {
                value: employ?.bank_nm,
                label: employ?.bank_nm
            },
            bank_acct: employ?.bank_acct,
            join_dt: employ?.join_dt,
            leave_dt: null,
        }
    });

    const onSubmit = async (e) => {
        delete e.addr1;
        delete e.addr2;
        e.const_manager_yn = e.const_manager_yn['value'];
        e.team_seq = e.team_seq['value'];
        e.rank_seq = e.rank_seq['value'];
        e.salary_amt = stringNumberToInt(e.salary_amt);
        e.hire_type = e.hire_type['value'];
        e.salary_type = e.salary_type['value'];
        e.bank_nm = e.bank_nm['value'];
        e.leave_dt = e.leave_dt === '' ? null : e.leave_dt;
        
        const token = localStorage.getItem('admin_token');
        const {data: {result: done}} = await axiosInstance.post(`/company/modifyEmploy`, {
            lang: 'ko',
            uuid: 'string',
            token,
            cu_seq: employ.cu_seq,
            ...e
        });

        modifyEmployComplete();
    };


    const onError = (errors) => {

        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = fieldsOrder.find(key => errorKeys.includes(key));
            setFocus(firstError);

            if (errors[firstError].type === 'noSpaces') {
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }
    };

    return (
        <FieldGroup ref={formRef} className="modify-employ-form" onSubmit={handleSubmit(onSubmit, onError)}>
            <InputBox>
                <h6>이름 <span>*</span></h6>
                <input name="user_nm"
                       type="text" {...register('user_nm', {
                    validate: {
                        register: (value) => value !== '' || '이름을 입력해 주세요.',
                        noSpaces: (value) => value?.trim() !== '' || '이름을 입력해 주세요.',
                    }
                })}  />
            </InputBox>

            <InputBox>
                <h6>주민등록번호 <span>*</span></h6>
                <input name="id_num" readOnly={true} style={{backgroundColor: '#7180960f'}} {...register('id_num')} />
            </InputBox>

            <InputBox>
                <h6>연락처 <span>*</span></h6>
                <input name="phone_num"
                       maxLength={11}
                       type="text" {...register('phone_num', {
                    required: '연락처를 입력해 주세요', pattern: {
                        value: /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{5}$/,
                        message: '연락처 형식이 올바르지 않습니다'
                    }
                })} onChange={(e) => {
                    numberOnly(e);
                }}/>

            </InputBox>

            <InputBox>
                <h6>사원번호 <span>*</span></h6>
                <input name="personnel_num"
                       {...register('personnel_num', {
                           validate: {
                               register: (value) => value !== '' || '사원번호를 입력해 주세요.',
                               noSpaces: (value) => value?.trim() !== '' || '사원번호를 입력해 주세요.',
                           }
                       })}
                       maxLength={25}/>
            </InputBox>

            <InputBox fulled>
                <h6>주소 <span>*</span></h6>
                <h5>{employ.addr1} {employ.addr2}</h5>
            </InputBox>

            <InputBox style={{marginTop: '3px'}}>
                <h6>현장 관리자 여부 <span>*</span></h6>
                <Controller
                    name="const_manager_yn"
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <Select
                            {...field}
                            options={constManagerYnOptions}
                        />
                    )}
                />

            </InputBox>
            <InputBox></InputBox>

            <InputBox>
                <h6>부서 <span>*</span></h6>

                <Controller
                    name="team_seq"
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <Select
                            {...field}
                            placeholder={'부서를 선택해주세요'}
                            options={props?.teams.map((e) => {
                                return {value: e.team_seq, label: e.team_nm};
                            })}
                        />
                    )}
                />

            </InputBox>

            <InputBox>
                <h6>직급 <span>*</span></h6>

                <Controller
                    name="rank_seq"
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <Select
                            {...field}
                            placeholder={'직급을 선택해주세요'}
                            options={ranks?.map((e) => {
                                return {value: e.rank_seq, label: e.position_nm};
                            })}
                        />
                    )}
                />
            </InputBox>

            <InputBox>
                <h6>고용형태 <span>*</span></h6>
                <Controller
                    name="hire_type"
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <Select
                            {...field}
                            placeholder={'고용형태를 선택해주세요'}
                            options={hireTypeOptions}
                        />
                    )}
                />


            </InputBox>

            <InputBox>
                <h6>은행 <span>*</span></h6>
                <h5>{employ.bank_nm} {employ.bank_acct}</h5>
            </InputBox>

            <InputBox>
                <h6>급여조건 <span>*</span></h6>
                <Controller
                    name="salary_type"
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                        <Select
                            {...field}
                            options={salaryTypeOptions}
                        />
                    )}
                />


            </InputBox>

            <InputBox>
                <h6>급여 <span>*</span></h6>
                <input name="salary_amt" type={'text'} {...register('salary_amt')}
                       onChange={(e) => {
                           numberOnly(e);
                           e.target.value = stringNumberToInt(e.target.value);
                           e.target.value = addCommas(e.target.value);
                       }}/>
            </InputBox>

            <InputBox>
                <h6>입사일 <span>*</span></h6>
                <input name="join_dt" type={'date'} onKeyDown={(e) => e.preventDefault()}
                       {...register('join_dt')}/>
            </InputBox>
            <InputBox>
                <h6>퇴사일</h6>
                <input name="leave_dt" type={'date'} onKeyDown={(e) => e.preventDefault()}
                       {...register('leave_dt')}   />
            </InputBox>


            <InputBox><br/></InputBox>
        </FieldGroup>
    );
};
const FieldGroup = styled.form`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 14px;
`;

export default ModifyEmployForm;
