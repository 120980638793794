import React, {useEffect, useState} from 'react';
import AxiosNavigate from '../../library/axios-navigate';
import {Header} from '../../compontents/Header';
import {PagingFooter} from '../../compontents/PagingFooter';
import Pagination from 'react-js-pagination';
import styled from '@emotion/styled';
import {useLocation} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {planState} from '../../library/recoil/atoms/planState';
import SearchBox from '../../compontents/Search';
import SelectedFilter from '../../compontents/SelectedFilter';
import PagesList from '../../compontents/PagesList';
import {useMutation} from '@tanstack/react-query';
import {getAdminPaymentList, paymentReceipt} from '../../library/axios-query';
import moment from 'moment';
import ButtonComponent from '../../compontents/Button';
import {getCompanyFilter} from '../../library/admin-react-query';
//ButtonComponent

const paymentOptions = [
    {value: 0, label: '전체 내역'},
    {value: 1, label: '결제완료'},
    {value: 2, label: '미결제'},
];

const PaymentInformationAdmin = () => {
    const [page, setPage] = useState(1);
    const location = useLocation();
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(20);

    const [corpFilter, setCorpFilter] = useState(0);
    const [paymentFilter, setPaymentFilter] = useState(0);

    const [searchTxt, setSearchTxt] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [companyOptions, setCompanyOptions] = useState(null);
    const planDataState = useRecoilValue(planState);

    // 회사 목록 필터 어드민용
    const {mutate: getCompanyFilterItem} = useMutation({
        mutationKey: ['getCompanyFilter'],
        mutationFn: getCompanyFilter,
        onSuccess: (data) => {
            if (companyOptions === null) {
                data.unshift({value: null, label: '전체 회사'});
                setCompanyOptions(data);
            }
        },
        onSettled: () => {

        },
        onError: (error) => {
            throw error;
        }
    });


    const dataFilter = (id) => {
        const filterData = planDataState?.filter((e, i) => {
            if (e.dtl_cd_id === id) {
                return e.dtl_cd_nm;
            }
        });
        return filterData[0].dtl_cd_nm;
    };

    // 결제 데이터 불러오기
    const {mutate: getPaymentList, data: getPaymentListData} = useMutation({
        mutationKey: ['paymentList', page, limit, corpFilter, paymentFilter, searchTxt],
        mutationFn: getAdminPaymentList,
        onSuccess: (data) => {
            setTotalCount(data.total);
        }
    });

    useEffect(() => {

        const paymentList = {
            siteNm: searchTxt.replaceAll(' ', ''),
            corpFilter: corpFilter,
            page: page,
            limit: limit,
            paymentCompleteFilter: paymentFilter,
        };

        getPaymentList(paymentList);
    }, [searchTxt, page, limit, corpFilter, paymentFilter]);

    useEffect(() => {
        getCompanyFilterItem(2);
    }, []);
    // 부트페이 영수증 정보 조회
    const {mutate: paymentReceiptItem, data: paymentReceiptList} = useMutation({
        mutationKey: ['paymentReceipt'],
        mutationFn: paymentReceipt,
        onSuccess: async (data) => {
            window.open(data?.receipt_url);
        }
    });


//클릭시 명세서 보기
    const paymentReceiptInfo = (event, pgNum) => {
        event.preventDefault();
        const receiptInfo = {
            site_seq: null,
            receipt_id: pgNum
        };
        paymentReceiptItem(receiptInfo);
    };
    return (
        <AxiosNavigate>
            <div>
                <Header>
                    <div>
                        결제이력
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', columnGap: '16px'}}>

                        <SelectedFilter style={{width: '200px'}}
                                        placeholder={companyOptions ? companyOptions[0].label : ''}
                                        options={companyOptions} onChange={(e) => {
                            setCorpFilter(e.value === null ? 0 : e.value);
                            setPaymentFilter(0);
                            setSearchTxt('');
                            setPage(1);
                        }}
                        >
                        </SelectedFilter>
                        <SearchBox placeholder={'현장명 검색'}
                                   onKeyDown={(e) => {
                                       if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
                                           if (e.target.value.length > 0 && e.target.value.trim().length === 0) {
                                               alert('현장명을 입력해 주세요');
                                               setSearchValue(e.target.value.trim());
                                               return;
                                           }
                                           setCorpFilter(0);
                                           setSearchTxt(e.target.value);
                                           setSearchValue(e.target.value.trim());
                                           setPaymentFilter(0);
                                           setPage(1);
                                       }
                                   }}
                                   value={searchValue} onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        ></SearchBox>

                        <SelectedFilter style={{width: '200px'}}
                                        placeholder={paymentOptions ? paymentOptions[0].label : ''}
                                        options={paymentOptions} onChange={(e) => {
                            setPaymentFilter(e.value);
                            setPage(1);
                            setLimit(20);
                            setSearchTxt('');
                        }}>
                        </SelectedFilter>
                    </div>

                </Header>

                {/*  걀제 이략 테이블  */}
                <TableContainer>
                    <TableWrapper>
                        <Table id={'payment-table'}>
                            <thead>
                            <tr>
                                <th rowSpan={2}>구분</th>
                                <th rowSpan={3} width={'160px'}>회사명</th>
                                <th rowSpan={3} width={'160px'}>현장명</th>
                                <th rowSpan={4}>대상 기간</th>
                                <th rowSpan={1}>결제구분</th>
                                <th rowSpan={1}>현장 수</th>
                                <th rowSpan={1} style={{width: '70px'}}>정산 대상 <br/> 인력(명)</th>
                                <th rowSpan={2}>일사 수수료</th>
                                <th rowSpan={2}>결제액<br/>(부가세 포함)</th>
                                <th rowSpan={3}>결제일</th>
                                <th rowSpan={3}>거래 명세서</th>
                            </tr>
                            </thead>
                            <tbody>
                            {getPaymentListData?.paymentList?.map((e, i) => (
                                <tr key={i}>
                                    <td>{i + limit * (page - 1) + 1}</td>
                                    <td style={{whiteSpace: 'break-spaces'}}>{e.corp_nm}</td>
                                    <td style={{whiteSpace: 'break-spaces'}}>{e.site_nm}</td>
                                    <td>{moment(e.pay_dt).format('YYYY년 M월')}</td>
                                    <td>{e.plan_id !== null ? `플랜 (${dataFilter(e.plan_id)})` : '인력채용'}</td>
                                    <td>{e.plan_id !== null ? e.site_cnt : '1'}</td>
                                    <td>{e.user_cnt}</td>
                                    <td>{e.fee_amt.toLocaleString()}</td>
                                    <td>{e.pg_amt.toLocaleString()}</td>
                                    <td>{e.done_dt === null || moment(e.done_dt).format('YYYY년 M월 DD일')}</td>
                                    <td>{e.done_yn === 'Y' && <ButtonComponent style={{
                                        height: '32px',
                                        margin: 'auto',
                                        padding: '0 7px',
                                        lineHeight: '32px',
                                        fontWeight: '300'
                                    }} onClick={(event) => {
                                        paymentReceiptInfo(event, e.pg_num);
                                    }}>명세서 보기</ButtonComponent>}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </TableWrapper>
                </TableContainer>
                {/* 페이징 */}
                <PagingFooter>
                    <div></div>
                    <Pagination
                        prevPageText={<Icon src="/assets/icons/chevron-left.svg"/>}
                        nextPageText={<Icon src="/assets/icons/chevron-right.svg"/>}
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        hideFirstLastPages={true}
                        onChange={(e) => setPage(e)}
                    />
                    <PagesList
                        // options={options}

                        onChange={(e) => {
                            setPage(1);
                            setLimit(e.value);
                        }}
                    ></PagesList>
                </PagingFooter>
            </div>
        </AxiosNavigate>
    );
};

const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0px 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: center;
        padding: 8px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #F7FAFC;
    }

    tr:last-child td {
        border-bottom: none;
    }


    td:first-of-type {
        text-align: center;
    }

    tr {
        height: 49px;
    }
`;


export default PaymentInformationAdmin;
