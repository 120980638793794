import styled from '@emotion/styled';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import axiosInstance from '../library/axios-index';
import {addCommas} from '../bin/common';

function Welcome() {
    const navigate = useNavigate();
    const location = useLocation();

    const [text, setText] = useState({
        header: 'Basic',
        title: '베이직(Free)',
        siteCount: '1개 현장',
        amount: '0원',
        contentStart: '현장관리 솔루션을 사용해본 적 없고',
        contentMid: '현장의 수가 1개',
        contentEnd: '현장사무실에 추천합니다.'
    });

    useEffect(() => {
        const planInfo = {...location.state};
        if (planInfo) {
            (async () => {
                let planData = await axiosInstance.post(`/code/codeList`, {
                    lang: 'ko',
                    uuid: 'string',
                    mst_cd_id: 'M0015',
                });

                planData = planData.data.result.find((e) => e.dtl_cd_id === planInfo.plan);
                text.header = planData.dtl_cd_desc;
                text.title = planData.dtl_cd_nm;
                text.amount = addCommas(planData.attribute1) + '원';

                text.siteCount = Number(planData.attribute2) <= 5 ? planData.attribute2 + '개 현장' : '현장 무제한';
                text.contentStart = planData.dtl_cd_id == 'M0015D0001' ? '현장관리 솔루션을 사용해본 적 없고' : '체계적인 현장관리에 관심이 있으면서';

                if (planData.attribute2 <= 1) {
                    text.contentMid = '현장의 수가 1개';
                    text.contentEnd = '현장사무실에 추천합니다';
                } else if (planData.attribute2 <= 5) {
                    text.contentMid = '현장의 수가 5개 이하';
                    text.contentEnd = '중소기업에 추천합니다.';
                } else {
                    text.contentMid = '현장의 수가 5개 초과';
                    text.contentEnd = '중소/중견기업에 추천합니다.';
                }
                setText((prevState) => ({...prevState, text}));

            })();


        }

    }, []);
    return (
        <Container>
            <Header>
                <h1 style={{marginBottom: '15px'}}>환영합니다!</h1>
                {/*<h2 style={{ marginBottom: "15px" }}>회원가입을 축하합니다.</h2>*/}
            </Header>
            <Background>
                <div style={{marginTop: '20px'}}/>
                <h4>스마트폰에서 일사 앱으로 현장을 관리하세요.</h4>
                <h4>
                    (앱 첫화면 상단 오른쪽{' '}
                    <span style={{color: '#3279F5'}}>“관리자로 전환"</span>을 클릭해
                    현장을 앱으로 관리할 수 있습니다.)
                </h4>
                <Box>
                    <BoxTitle>{text?.header}</BoxTitle>
                    <h2>{text?.header}</h2>
                    <h5>{text?.siteCount}</h5>
                    <div
                        style={{
                            height: '1px',
                            width: '160px',
                            margin: '10px',
                            backgroundColor: '#E2E8F0',
                        }}
                    />
                    <div style={{display: 'flex'}}>
                        <h1 style={{marginBottom: '5px'}}>{text?.amount}</h1>
                        <div
                            style={{fontSize: '12px', marginTop: '23px', marginLeft: '5px'}}
                        >
                            {' '}
                            / 월
                        </div>
                    </div>
                    <h5 style={{marginBottom: '20px'}}>근로자당</h5>
                    <h4>{text.contentStart}</h4>
                    <h4>
                        운영중인{' '}
                        <span style={{color: '#3279F5', fontWeight: '600'}}>
              {text.contentMid}
            </span>
                        인
                    </h4>
                    <h4>{text.contentEnd}</h4>
                    <BoxButton onClick={
                        () => {
                            localStorage.setItem('admin_token', '');
                            navigate('/login');
                        }
                    }>시작하기</BoxButton>
                </Box>
                <div style={{marginTop: '30px'}}/>
                <h5 style={{display: 'flex', justifyContent: 'center',}}>
                    <Icon src="/assets/icons/alert-circle-gray.svg"/>
                    &nbsp;&nbsp;근로계약서가 유효하려면&nbsp;<span style={{fontWeight: '700'}}>‘법인 직인'</span>&nbsp;등록이 필수입니다.
                </h5>
                <h5>아래 사용메뉴얼을 참고하세요.</h5>
                <Manual onClick={
                    () => {
                        window.open('https://bucket-9uc28v.s3.ap-northeast-2.amazonaws.com/ilsa/manual/ilsa_setup_manual.pdf');
                    }
                }>사용메뉴얼</Manual>
            </Background>
        </Container>
    );
}

export default Welcome;

const Container = styled.div`
    background: #f7fafc;
    height: 100vh;
    overflow: hidden;
`;

export const Header = styled.div`
    height: 120px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    h1 {
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        margin: 0;
    }

    h2 {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin: 0;
    }
`;

export const Background = styled.div`
    height: 100%;
    width: 100%;
    background-color: #ebf2fe;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;

    h4 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-top: 10px;
    }

    h5 {
        font-size: 12px;
        font-weight: 400;
        color: #818791;
        margin-top: 5px;
    }
`;

export const Box = styled.div`
    width: 200px;
    height: 321px;
    //opacity: 0;
    color: #333333;
    background-color: #ffffff;
    border-radius: 16px;
    margin-top: 20px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 26px;
        font-weight: 700;
        margin-top: 10px;
    }

    h2 {
        margintop: 20px;
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: 500;
    }

    h4 {
        height: 20px;
        font-size: 11px;
        font-weight: 400;
        margin: 0;
        color: #4a5568;
    }

    h5 {
        font-size: 10px;
        font-weight: 400;
        margin: 0;
        color: #4a5568;
    }
`;

export const BoxTitle = styled.div`
    width: 62px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebf2fe;
    color: #3279f5;
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 20px;
`;

export const BoxButton = styled.div`
    width: 160px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3279f5;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
`;

export const Manual = styled.div`
    width: 84px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E2E8F0;
    margin-top: 20px;
    cursor: pointer;

    color: #818791;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;

    border-radius: 4px;
    border-style: solid;
    border-color: #CBD5E0;
    border-width: 1px;
`;

const Icon = styled.img`
    width: 14px;
`;

