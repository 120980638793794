import React, {useEffect, useState} from 'react';
import AxiosNavigate from '../../library/axios-navigate';
import {Header} from '../../compontents/Header';
import styled from '@emotion/styled';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CompanyPlan} from '../../bin/enum';
import PlanSelectModal from '../../compontents/PlanSelectModal';
import ReactSelect from 'react-select';
import {PagingFooter} from '../../compontents/PagingFooter';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import {companyInfoList, paymentConfirm, paymentReceipt, planPaymentList} from '../../library/axios-query';
import SideModal from '../../compontents/SideModal';
import {Link} from 'react-router-dom';
import ButtonComponent from '../../compontents/Button';
import {useCookies} from 'react-cookie';
import Bootpay from '@bootpay/client-js';
import {REACT_APP_BOOTPAY_APPLICATION_ID} from '../../bin/env';
import {BlockScrolling} from '../../bin/common';
import PagesList from '../../compontents/PagesList';
import {useRecoilValue} from 'recoil';
import {planState} from '../../library/recoil/atoms/planState';
import EmptyContainer from '../../compontents/EmptyContainer';

const options = [
    {value: 20, label: '20'},
    {value: 30, label: '30'},
    {value: 50, label: '50'},
    {value: 100, label: '100'},
];
const PaymentInformation = () => {
    const planDataList = useRecoilValue(planState);
    const [openPlanSelect, setOpenPlanSelect] = useState(false);
    const token = localStorage.getItem('admin_token');
    const [page, setPage] = useState(1);

    const [totalCount, setTotalCount] = useState(0);
    const [cookies] = useCookies(['role']);
    const [opendDetailEmploy, setOpendDetailEmploy] = useState(false);
    const [limit, setLimit] = useState(options[0].value);

    const adminCheck = cookies.role.admin;
    const queryClient = useQueryClient();

    BlockScrolling(opendDetailEmploy);
    BlockScrolling(openPlanSelect);
    // 결제 이력
    const {mutate: paymentListItem, data: paymentListDate} = useMutation({
        mutationKey: ['paymentListInfor'],
        mutationFn: planPaymentList,
        onSuccess: (data) => {
            setTotalCount(data.totalCount);
            queryClient.invalidateQueries('paymentListInfor');
        },
        onError: (error) => {
            throw error;
        }
    });

    // 세금계산서(회사) 정보 && 등급 정보(회사 정보 안에 등급 정보도 있음)
    const {mutate: companyInfoItem, data: companyInfo} = useMutation({
        mutationKey: ['companyInfo'],
        mutationFn: companyInfoList
    });

    // 부트페이 결제 완료 처리
    const {mutate: paymentConfirmItem, data: paymentConfirmList} = useMutation({
        mutationFn: paymentConfirm,
        mutationKey: 'paymentConfirm',
        onSuccess: async (data) => {
            if (data.done === true) {
                Bootpay.destroy();
                window.location.reload();
                // queryClient.invalidateQueries('paymentListInfor');
            }
        },
        onError: (error) => {
            throw error;
        }
    });
    // 부트페이 영수증 정보 조회
    const {mutate: paymentReceiptItem, data: paymentReceiptList} = useMutation({
        mutationKey: ['paymentReceipt'],
        mutationFn: paymentReceipt,
        onSuccess: async (data) => {
            const dataItem = {
                receipt_id: data.receipt_id,
                price: data.price
            };

            if (data.status_locale === '결제완료' || data.status_locale === '가상계좌발급완료') {
                const pg_url = data.receipt_url;
                window.open(pg_url);
                return;
            }

            if ('카드' === data.method) {
                paymentConfirmItem(dataItem);
            } else {
                const confirmedData = await Bootpay.confirm();
                if (confirmedData.event === 'issued') {
                    Bootpay.destroy();
                    // queryClient.invalidateQueries('paymentListInfor');
                    window.location.reload();

                }
            }

        }
    });
// 플랜 정보
    useEffect(() => {
        if (!token) return;
        companyInfoItem();
        //   planDataItem('M0015');
    }, [token]);

    useEffect(() => {
        const payment = {
            page: page,
            limit: limit,
        };

        paymentListItem(payment);
    }, [page, limit]);
    const dataFilter = (id) => {
        const filterData = planDataList.filter((e, i) => {
            if (e.dtl_cd_id === id) {
                return e.dtl_cd_nm;
            }
        });
        return filterData[0].dtl_cd_nm;
    };
//클릭시 명세서 보기
    const paymentReceiptInfo = (event, companyInfo) => {
        event.preventDefault();
        const receiptInfo = {
            site_seq: null,
            receipt_id: companyInfo
        };

        paymentReceiptItem(receiptInfo);
    };

    const paymentRequest = async (information, companyInfo) => {
        const nowTimestamp = new Date().getTime();
        const depositExpirationDate = moment(new Date()).add(25, 'days').format('YYYY-MM-DD hh:mm:ss');
       
        if (!information || !companyInfo) return;
        try {
            const response = await Bootpay.requestPayment({
                'application_id': `${REACT_APP_BOOTPAY_APPLICATION_ID}`,
                'price': information.pg_amt,
                'order_name': `플랜 이용료${moment(information.pay_dt).format(' ' +
                    '(YYYY년 M월)')}`,
                'order_id': `${nowTimestamp}_${information.pg_seq}`,
                'company_name': '넷스프린트',
                'pg': '다날',
                'gateway_url': 'https://gw.bootpay.co.kr',
                'method': ['카드', '가상계좌'],
                'tax_free': 0,
                'metadata': {
                    'rp_seq': null,
                    'pay_cd': 'M0019D0001',
                    'pg_seq': information.pg_seq
                },
                'user': {
                    'username': companyInfo.manager_nm,
                    'phone': companyInfo.manager_phone_num,
                    'email': companyInfo.email_addr,
                },
                extra: {
                    separately_confirmed: true,
                    deposit_expiration: depositExpirationDate
                },
            });

            switch (response.event) {
                case 'issued':
                    Bootpay.destroy();
                    window.location.reload();

                    break;
                case 'done':

                    break;
                case 'confirm':
                    const data = {
                        receipt_id: response.receipt_id,
                    };

                    paymentReceiptItem(data);

                    break;
                default:
                    break;
            }
        } catch (e) {
            switch (e.event) {
                case 'cancel':
                    // 사용자가 결제창을 닫을때 호출
                    console.error('Error fetching data:', e.message);
                    alert(e.message);
                    break;
                case 'error':
                    // 결제 승인 중 오류 발생시 호출
                    console.log(e.error_code);
                    console.error('Error fetching data:', e.error_code);
                    alert(e.error_code);
                    break;
            }
        }

    };
    const division = (event, companyInfo, information) => {
        if (!companyInfo.corp_nm || !companyInfo.corp_reg_num || !companyInfo.ceo_nm || !companyInfo.biz_sector || !companyInfo.biz_type || !companyInfo.addr1 || !companyInfo.manager_nm || !companyInfo.manager_phone_num || !companyInfo.email_addr) {
            alert('세금계산서 정보 등록후 결제를 진행하시기 바랍니다.');
            setOpendDetailEmploy(true);
            return;
        }
        paymentRequest(information, companyInfo);
    };

    return (
        <AxiosNavigate>
            <div style={{paddingBottom: '30px'}}>
                <Header>
                    <div>
                        결제정보
                    </div>
                </Header>
                {/* 등급 정보 */}
                <RatingContainer>
                    <div>
                        <span className="title">등급 정보</span>
                        <RatingInformation>
                            <span>
                                 {`현재 '${companyInfo?.planName}(${companyInfo?.planName === '베이직' ? '무료' : '유료'})' 서비스를 사용중입니다.`}
                            </span>
                            {adminCheck ? (<button onClick={() => {
                                if (companyInfo?.plan_id !== CompanyPlan.ENTERPRISE) {
                                    setOpenPlanSelect(true);
                                }
                            }}>등급 변경하기
                            </button>) : ''}
                        </RatingInformation>
                    </div>
                </RatingContainer>
                <div style={{padding: '0 32px'}}>
                    <Board>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column'
                            }}
                        >
                            <BoardHeader>
                                결제 이력
                                <button onClick={(e) => {
                                    setOpendDetailEmploy(true);
                                }}>세금계산서 정보</button>
                            </BoardHeader>
                            {/*  걀제 이략 테이블  */}
                            <TableContainer>
                                <TableWrapper>
                                    <Table id={'payment-table'}>
                                        <thead>
                                        <tr>
                                            <th rowSpan={2}>구분</th>
                                            <th rowSpan={4}>대상 기간</th>
                                            <th rowSpan={1}>플랜이름</th>
                                            <th rowSpan={1}>현장 수</th>
                                            <th rowSpan={2}>정산 대상 인력(명)</th>
                                            <th rowSpan={2}>일사 수수료</th>
                                            <th rowSpan={2}>결제액<br/>(부가세 포함)</th>
                                            <th rowSpan={2}>결제하기</th>
                                            <th rowSpan={3}>결제일</th>
                                            <th rowSpan={3}>거래 명세서</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {paymentListDate?.paymentList?.length === 0 ? <tr style={{height: '500px'}}>
                                            <td colSpan={11}>
                                                <EmptyContainer falseText={'결제정보가'}
                                                                style={{background: '#fff'}}></EmptyContainer>
                                            </td>
                                        </tr> : paymentListDate?.paymentList?.map((e, i) => (
                                            <tr key={i}>
                                                <td>{i + limit * (page - 1) + 1}</td>
                                                <td>{moment(e.pay_dt).format('YYYY년 M월')}</td>
                                                <td>{dataFilter(e.plan_id)}</td>
                                                <td>{e.site_cnt}</td>
                                                <td>{e.user_cnt}</td>
                                                <td>{e.fee_amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                                <td>{e.pg_amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                                <td>{e.done_yn === 'N' ? e.pg_num === null ? (
                                                    <ButtonComponent style={{
                                                        padding: '0 7px',
                                                        height: '32px',
                                                        margin: 'auto',
                                                        lineHeight: '32px',
                                                        fontWeight: '300'
                                                    }} onClick={(event) => {
                                                        event.preventDefault();
                                                        division(event, companyInfo, e);

                                                    }}>결제하기</ButtonComponent>

                                                ) : (<ButtonComponent style={{
                                                    padding: '0 7px',
                                                    height: '32px',
                                                    margin: 'auto',
                                                    lineHeight: '32px',
                                                    fontWeight: '300',
                                                    backgroundColor: '#569674'
                                                }} onClick={(event) => {
                                                    event.preventDefault();
                                                    division(event, companyInfo, e);
                                                    // paymentRequst(e, companyInfo);
                                                }}>결제변경</ButtonComponent>) : ''}</td>

                                                {/*<td>{e.done_yn === 'N' ? e.pg_num === null ? '' : }</td>*/}
                                                <td>{`${e.pg_num !== null ? (e.done_dt ? moment(e.done_dt).format('YYYY년 M월 DD일') : '입금대기중') : ''} `}</td>
                                                <td>{e.pg_num !== null ? (e.done_yn === 'Y' ? (
                                                    <ButtonComponent style={{
                                                        height: '32px',
                                                        margin: 'auto',
                                                        padding: '0 7px',
                                                        lineHeight: '32px',
                                                        fontWeight: '300'
                                                    }} onClick={(event) => {
                                                        // paymentReceiptInfo(event, companyInfo);
                                                        paymentReceiptInfo(event, e.pg_num);
                                                    }}>명세서 보기</ButtonComponent>) : (
                                                    <ButtonComponent style={{
                                                        height: '32px',
                                                        margin: 'auto',
                                                        padding: '0 7px',
                                                        lineHeight: '32px',
                                                        fontWeight: '300',
                                                        backgroundColor: '#569674'
                                                    }} onClick={(event) => {
                                                        paymentReceiptInfo(event, e.pg_num);
                                                    }}>계좌 확인</ButtonComponent>
                                                )) : ''}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </TableWrapper>
                            </TableContainer>
                            {/* 페이징 */}
                            <PagingFooter style={{position: 'relative', padding: '10px 0 50px', marginLeft: '0px'}}>
                                {/*<PageGroup style={{margin: 'auto'}}>*/}
                                <Pagination
                                    prevPageText={<Icon src="/assets/icons/chevron-left.svg"/>}
                                    nextPageText={<Icon src="/assets/icons/chevron-right.svg"/>}
                                    activePage={page}
                                    itemsCountPerPage={limit}
                                    totalItemsCount={totalCount === 0 ? 1 : totalCount}
                                    pageRangeDisplayed={5}
                                    hideFirstLastPages={true}
                                    onChange={(e) => {
                                        setPage(e);
                                    }}
                                />
                                {/*</PageGroup>*/}
                                <PagesList options={options}
                                           onChange={(e) => {
                                               setPage(1);
                                               setLimit(e.value);
                                           }} style={{right: '0', height: '38px', width: 'auto'}}
                                ></PagesList>
                                <span>* 일사 서비스 수수료의 "근로자 수"는 대상 기간동안 퇴사한 근로자를 포함합니다. <br/>* 플랜은 일사 수수료 청구일 기준입니다.</span>
                            </PagingFooter>
                        </div>
                    </Board>
                </div>
            </div>

            <PlanSelectModal
                opend={openPlanSelect}
                closeModal={() => {
                    setOpenPlanSelect(false);
                }}
                handleOk={() => {
                }}
                widthCheck={'50%'}
                maxHeightCheck={'797'}

            >
                <div>test</div>
            </PlanSelectModal>
            {/*  세금계산서 정보   */}

            {
                companyInfo && <SideModal
                    opend={opendDetailEmploy}
                    closeModal={() => setOpendDetailEmploy(false)}
                    header={'세금계산서 신청 정보'}
                >
                    <Content>
                        <figure>
                            <h6>회사명(법인명)</h6>
                            <h5 className={`${!companyInfo?.corp_nm ? 'red' : ''}`}>{!companyInfo?.corp_nm ? '등록 필요' : companyInfo.corp_nm}</h5>
                        </figure>
                        <figure>
                            <h6>사업자등록번호</h6>
                            <h5 className={`${!companyInfo?.corp_reg_num ? 'red' : ''}`}>{!companyInfo?.corp_reg_num ? '등록 필요' : companyInfo.corp_reg_num}</h5>
                        </figure>
                        <figure>
                            <h6>대표자명</h6>
                            <h5 className={`${!companyInfo?.ceo_nm ? 'red' : ''}`}>{!companyInfo?.ceo_nm ? '등록 필요' : companyInfo.ceo_nm}</h5>
                        </figure>
                        <figure>
                            <h6>업태</h6>
                            <h5 className={`${!companyInfo?.biz_type ? 'red' : ''}`}>{!companyInfo?.biz_type ? '등록 필요' : companyInfo.biz_type}</h5>
                        </figure>
                        <figure>
                            <h6>종목</h6>
                            <h5 className={`${!companyInfo?.biz_sector ? 'red' : ''}`}>{!companyInfo?.biz_sector ? '등록 필요' : companyInfo.biz_sector}</h5>
                        </figure>
                        <figure>
                            <h6>사업자주소</h6>
                            <h5 className={`${!companyInfo?.addr1 ? 'red' : ''}`}>{!companyInfo?.addr1 ? '등록 필요' : companyInfo.addr1}</h5>
                        </figure>
                        <figure>
                            <h6>담당자명</h6>
                            <h5 className={`${!companyInfo?.manager_nm ? 'red' : ''}`}>{!companyInfo?.manager_nm ? '등록 필요' : companyInfo.manager_nm}</h5>
                        </figure>
                        <figure>
                            <h6>담당자 연락처</h6>
                            <h5 className={`${!companyInfo?.manager_phone_num ? 'red' : ''}`}>{!companyInfo?.manager_phone_num ? '등록 필요' : companyInfo.manager_phone_num}</h5>
                        </figure>
                        <figure>
                            <h6>세금계산서 이메일</h6>
                            <h5 className={`${!companyInfo?.email_addr ? 'red' : ''}`}>{!companyInfo?.email_addr ? '등록 필요' : companyInfo.email_addr}</h5>
                        </figure>

                    </Content>
                    <Link to="/setting/company">
                        <ModalLabel>
                            <span>세금계산서 신청 정보 는 "일사 웹 관리자 &gt; 설정 &gt; 회사정보"에서 수정 할 수 있습니다. <br/>기결제건에 대해서는 소급 적용되지 않습니다.</span>
                        </ModalLabel>
                    </Link>
                </SideModal>
            }

        </AxiosNavigate>
    );
};

export default PaymentInformation;

const RatingContainer = styled.div`
    padding: 0 32px;
    margin-top: 25px;
    margin-bottom: 13px;

    > div {
        padding: 20px 24px 32px 24px;
        border-radius: 16px;
        border: 1px solid #E2E8F0;
        background: #FFF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

        .title {
            font-size: 18px;
            font-weight: 700;
        }
    }
`;
const RatingInformation = styled.div`
    width: 100%;
    height: 43px;
    border-radius: 8px;
    background: #F7FAFC;
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    && span {
        font-size: 16px;
        color: #4A5568;
        font-weight: 500;
    }

    && button {
        width: 120px;
        height: 28px;
        background: #fff;
        border-radius: 6px;
        color: #3279F5;
        font-weight: 600;
        font-size: 14px;
        border: solid 1px #3279F5
    }
`;

const Board = styled.div`
    border-radius: 16px;
    background: #f7fafc;
    margin-top: 24px;
    padding: 20px 24px 32px;

    @media only screen and (min-width: 992px) {
        margin-top: 0;
    }
`;

const Panel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 32px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #cbd5e0;
    overflow: hidden;
    background: #fff;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;
    padding: 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;

const BoardButton = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1.125px solid #e2e8f0;
    background: #fff;

    color: #4a5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;

    margin-top: 5px;
`;

const Select = styled(ReactSelect)`
    color: #4a5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    border-radius: 6px;
    background: #fff;
    margin-top: 5px;

    div {
        cursor: pointer;
        color: #4a5568;
        border-color: #e2e8f0;
    }
`;

const Icon = styled.img`
    width: 16px;
`;

const BoardHeader = styled.div`
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        position: relative;
        border-radius: 90px;
        border: 1px solid #718096;
        cursor: pointer;
        background: #fff;
        color: #718096;
        padding: 6px 16px;
        font-size: 13px;
        font-weight: 600;
    }


`;

const TableContainer = styled.div`
    //padding: 0 32px;
    margin-top: 20px;
`;

const TableWrapper = styled.div`
    border: 1px solid #cbd5e0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th,
    td {
        text-align: left;
        padding: 8px;
        font-size: 13px;
        font-weight: 300;
        white-space: nowrap;
    }

    tr {
        height: 47px;
    }

    th {
        border-bottom: 1px solid #cbd5e0;
        border-right: 1px solid #cbd5e0;
        background-color: #e2e8f0;
        text-align: center;
        font-size: 13px;
        color: #000;
    }

    td:last-child,
    th:last-child {
        border-right: none;
    }

    tr:last-child td {
        border-bottom: none;
    }

    td:nth-of-type(4) {
        color: #3279F5;
    }

    td:nth-of-type(6),
    td:nth-of-type(7) {
        text-align: right;
    }

    td:first-of-type {
        text-align: center;
    }

    td {
        text-align: center;
        background: #fff;
        border-bottom: 1px solid #cbd5e0;
        border-right: 1px solid #cbd5e0;
    }

    td span {
        //width: 69px;
        //height: 32px;
        //gap: 8px;
        //background: #3279F5;
        //text-align: center;
        //margin: auto;
        //line-height: 32px;
        //color: #fff;
        //display: block;
        //cursor: pointer;
        //box-sizing: border-box;
        //border-radius: 6px;
    }
`;


const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;
const Content = styled.div`
    figure {
        margin: 0;
        display: flex;
        align-items: center;
        height: 34px;
    }

    h6, h5 {
        margin: 0;
    }

    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        width: 120px;
        min-width: 120px;
    }

    h5 {
        color: rgba(0, 0, 0, 0.92);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    h5.red {
        color: red;
    }

    hr {
        background: #EDF2F7;
        color: #EDF2F7;
        border: 1px solid #EDF2F7;
        margin: 12px 0;
    }
`;
const ModalLabel = styled.div`
    border-radius: 16px;
    background: #f7fafc;
    padding: 20px 24px;
    position: absolute;
    bottom: 20px;
    left: 32px;
    right: 32px;

    span {
        color: #718096;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
    }
`;