import React, {forwardRef} from 'react';
import styled from '@emotion/styled';

const Button = styled.button`
    display: flex;
    //width: 100%;

    //padding: 0 12px;
    background: #3279F5;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    font-size: 14px;
    font-style: normal;
    box-sizing: border-box;
    //font-weight: 600;
    border-style: solid;
    line-height: 18px;
    cursor: pointer;
    border-width: 1px;
    border-color: transparent;
    //border: none;
    text-decoration: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #fff;

    span {
        align-items: center;
        gap: 9px;
        display: flex;
    }
`;

const FullButton = styled(Button)`
    width: 100%;
`;
const ButtonComponent = forwardRef((props, ref) => {
    const {children, variant} = props;
  
    if (variant === 'full') {
        return <FullButton ref={ref} {...props}>{children}</FullButton>;
    }


    return (<Button ref={ref} {...props} >
        <span>{children}</span>
    </Button>);
});
// const ButtonComponent = ({variant, children, ...props}) => {
//     if (variant === 'full') {
//         return <FullButton {...props}>{children}</FullButton>;
//     }
//
//     return (
//
//         <Button {...props}>
//             <span>{children}</span>
//         </Button>
//
//     );
// };

export default ButtonComponent;