import styled from '@emotion/styled';
//import ReactModal from 'react-modal';
import {useWindowSize} from 'react-use';
import Button from './Button';
import {useCookies} from 'react-cookie';
import React, {Fragment, useEffect, useState} from 'react';
import ReactModal from 'react-modal-resizable-draggable';
import moment from 'moment';


// handleOk 이 없으면 버튼 타입 type="submit" 적용
function NoticeModal({
                         header,
                         noticeData,
                         widthCheck,
                         noCloseModal,
                     }) {

    const {width} = useWindowSize();
    const [cookies, setCookies] = useCookies(['notice']);
    const [opened, setOpened] = useState([]);
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    useEffect(() => {
        noticeData?.filter((e, i) => {
            if (cookies[`notice_${e.pop_seq}`] !== e.pop_seq) {
                setOpened(prevState => ({...prevState, [e.pop_seq]: true}));
            } else {
                setOpened(prevState => ({...prevState, [e.pop_seq]: false}));
            }
        });
    }, [noticeData]);

    const handleClose = (id) => {
        setOpened(prevState => ({...prevState, [id]: false}));
    };

    const renderTextWithLinks = (text) => {
        if (!text) return;
        const parts = text.split(urlRegex);
        return parts.map((part, i) => (
            urlRegex.test(part) ? <a href={part} key={i} target={'_blank'}>{part}</a> : part
        ));
    };

    return (
        <>
            {
                noticeData.map((e, i) => (
                    <NoticeWrap
                        initWidth={'100%'}
                        initHeight={500}
                        className={'my-modal-custom-class'}
                        isOpen={opened[e.pop_seq]}
                        disableResize={true}
                        top={'85px'}
                        left={'250px'}
                        onRequestClose={() => {
                            handleClose(e.pop_seq);
                        }}
                        key={e.pop_seq}
                    >
                        <Padding>
                            <Header>
                                {typeof header === 'string' ?
                                    <h4 style={{
                                        color: '#171923',
                                        fontSize: '15px',
                                        margin: 0
                                    }}>{e.subject}</h4> : e.subject}
                                <div onClick={() => {
                                    handleClose(e.pop_seq);
                                }}>
                                    <img alt={'XButton'} src="/assets/icons/left-icon.svg"/>
                                </div>
                            </Header>
                            <Content>
                                {
                                    e.type === 'M0023D0001' ?
                                        renderTextWithLinks(e?.content1)
                                        : e?.content2?.length === 0 ?
                                            <span>데이터가 없습니다</span> :
                                            <>
                                                {
                                                    renderTextWithLinks(e?.content1)
                                                }
                                                {
                                                    <TableContainer>
                                                        <TableWrapper>
                                                            <Table>
                                                                <thead>
                                                                <tr>
                                                                    <th>현장명</th>
                                                                    <th>사용자명</th>
                                                                    <th>신분증 등록 여부</th>
                                                                    <th>계좌번호 등록 여부</th>
                                                                    <th>입사일</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    e?.content2?.map((e, i) => (
                                                                        <tr key={i}>
                                                                            <td>{e.site_nm}</td>
                                                                            <td>{e.user_nm}</td>
                                                                            <td>{e.reg_id_card}</td>
                                                                            <td>{e.reg_bank_acct}</td>
                                                                            <td>{moment(e.join_dt).format('YYYY년 M월 D일')}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </Table>
                                                        </TableWrapper>
                                                    </TableContainer>
                                                }

                                            </>
                                }
                            </Content>
                            <Footer>
                                <Button style={{
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    borderRight: '1px solid #a5a5a5',
                                    borderRadius: '0'
                                }} onClick={(event) => {
                                    event.stopPropagation();
                                    setCookies(`notice_${e.pop_seq}`, e.pop_seq, {maxAge: 60 * 60 * 24});
                                    handleClose(e.pop_seq);
                                }}>오늘
                                    그만보기</Button>
                                <Button style={{backgroundColor: '#fff', color: '#000'}} onClick={(event) => {
                                    event.stopPropagation();
                                    handleClose(e.pop_seq);
                                }}>닫기</Button>
                            </Footer>
                        </Padding>
                    </NoticeWrap>
                ))
            }

        </>
    );
}

const NoticeWrap = styled(ReactModal)`
    @media only screen and (max-width: 992px) {
        left: 50% !important;
        top: 50% !important;
        width: 100% !important;
        transform: translate(-50%, -50%);
        max-width: calc(100vw - 48px);
    }
`;
const Padding = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 19px;
    position: relative;

    & > div {
        width: 25px;
        position: absolute;
        right: 19px;
        height: 25px;
        z-index: 99;
        cursor: pointer;

        img {
            width: inherit;
        }

        &:nth-of-type(2) {
            //&:nth-child(2) {
            text-align: center;
        }
    }


`;

const Content = styled.div`
    overflow-y: auto;
    word-break: break-all;
    height: inherit;
    box-sizing: border-box;
    white-space: break-spaces;
    width: 100%;
    display: block;
    padding: 10px 19px;
    font-size: 13px;
    line-height: 17px;

    a {
        text-decoration: none;
        color: #3279f5;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Footer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    padding: 10px 14px;
    box-sizing: border-box;
    height: 50px;
`;

const SidebarButton = styled.img`
    cursor: pointer;
`;

const TableContainer = styled.div`
    margin-top: 12px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 2px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: center;
        padding: 5px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #F7FAFC;
    }

    tr:last-child td {
        border-bottom: none;
    }

    thead {
        height: 27px;
    }
`;

export default NoticeModal;