import React from 'react';
import styled from '@emotion/styled';
import ButtonComponent from './Button';
import {isNumber} from 'chart.js/helpers';

const CardInformation = (prorps) => {
    const {title, contract, total, handleEdit, handleHistoryChange, contract_type} = prorps;
    
    return (
        <CardWrapper onClick={e => handleHistoryChange()}>
            <NameWrapper>{title}<ButtonComponent
                style={{
                    background: '#fff',
                    color: '#4a5568',
                    border: '1px solid #E2E8F0',
                    height: '30px'
                }} onClick={(event) => handleEdit(event)}>정보수정</ButtonComponent></NameWrapper>
            <InformationWrapper>
                <div><em>계약
                    금액</em><span>{contract_type === 'U' ? '단가계약' : Number(contract).toLocaleString() + '원'}</span></div>
                <div>
                    <em>기성누계 {contract_type === 'U' || contract === '0' ? '' : `(${isNumber((Number(total) / Number(contract)) * 100) && ((Number(total) / Number(contract)) * 100).toFixed(1) > 0 ? ((Number(total) / Number(contract)) * 100).toFixed(1) : 0}%)`}</em><span>{Number(total).toLocaleString()}원</span>
                </div>
            </InformationWrapper>
        </CardWrapper>
    );
};
const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
    color: rgba(0, 0, 0, 0.92);
`;
const InformationWrapper = styled.div`
    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:first-of-type {
            margin-bottom: 7px;
        }

        em {
            color: #718096;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
        }

        span {
            color: rgba(0, 0, 0, 0.92);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
        }
    }
`;
const CardWrapper = styled.div`
    padding: 13px 15px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);


`;
export default CardInformation;
