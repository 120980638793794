import styled from '@emotion/styled';
import dayjs from 'dayjs';
import {forwardRef} from 'react';
import {addCommas} from '../../bin/common';


const LaborEmployPagePrint = forwardRef((props, ref) => {

    const employees = props.data.employees;
    const jobPositon = props.data.jobPositon;
    const position = props.data.position;
    const rank = props.data.rank;
    const nations = props.data.nations;
    const company = props.data.company;

    return (
        <Container ref={ref}>

            <Header>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Title>근로자 정보</Title>
                    <Text>
                        <strong>회사명</strong> <span>{company}</span>
                    </Text>
                </div>

                <div>
                </div>
            </Header>

            <TableContainer className={'table-wrapper'}>
                <TableWrapper>
                    <Table>
                        <thead>
                        <tr>
                            <th rowSpan={2} style={{textAlign: 'center'}} width={'30px'}>구분</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>이름</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>국적</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>주민등록번호<br/>(외국인관리번호)</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>나이</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>현장 명</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>직종</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>역할</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>직급</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>소속</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>입사일</th>
                            <th rowSpan={2} style={{textAlign: 'center'}}>퇴사일</th>
                            <th colSpan={3} style={{textAlign: 'center'}}>급여</th>
                        </tr>
                        <tr>
                            <th style={{textAlign: 'center'}}>일급</th>
                            <th style={{textAlign: 'center'}}>월급</th>
                            <th style={{textAlign: 'center'}}>기타</th>
                        </tr>
                        </thead>
                        <tbody>
                        {employees.map((e, i) => <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                                <div style={{
                                    width: '152px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>&nbsp;{e.user_nm}
                                </div>
                            </td>
                            <td style={{textAlign: 'left'}}>{nations[e.nationality]}</td>
                            <td>{e.id_num}</td>
                            <td style={{textAlign: 'center'}}>{e.birth_day ? +dayjs().format('YYYY') - +dayjs(e.birth_day).format('YYYY') : 0}세</td>
                            <td style={{textAlign: 'left'}}>{e.site_nm}</td>
                            <td style={{textAlign: 'left'}}>{jobPositon[e.job_position]}</td>
                            <td style={{textAlign: 'left'}}>{position[e.role_nm]}</td>
                            <td style={{textAlign: 'left'}}>{rank[e.rank_nm]}</td>
                            <td style={{textAlign: 'left'}}>{e.class_nm}</td>
                            <td style={{textAlign: 'center'}}>{dayjs(e.join_dt).format('YYYY년 M월 D일')}</td>
                            <td style={{textAlign: 'center'}}>{e.leave_dt ? dayjs(e.leave_dt).format('YYYY년 M월 D일') : ''}</td>
                            <td className={'table-right'}>{e.salary_type === 'D' ? addCommas(e.salary_amt) : '0'}</td>
                            <td className={'table-right'}>{e.salary_type === 'M' ? addCommas(e.salary_amt) : '0'}</td>
                            <td className={'table-right'}>{e.salary_type === 'C' ? addCommas(e.salary_amt) : '0'}</td>
                        </tr>)}
                        </tbody>
                    </Table>
                </TableWrapper>
            </TableContainer>

        </Container>
    );
});

const Container = styled.div`
    //border: 1px solid #E2E8F0;
    // border: 1px solid #E2E8F0;

    background: #FFF;
    padding: 20px 24px;


    @page {
        size: A4 landscape !important;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    @media print {

        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
    display: flex;
`;

const Badge = styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: flex-start;
    border-radius: 6px;
    background: #F7FAFC;

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const Label = styled.div`
    color: #718096;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    margin-right: 4px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
`;

const TableContainer = styled.div`
`;
const TableWrapper = styled.div`
    // border-radius: 8px;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #000;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;

        &:not(:last-child) {
            //border-bottom: none;

        }
    }

    th {
        background-color: #EDF2F7;
        color: #4A5568;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;

    }

    td {
        color: #1A202C;
        padding: 5px;
    }

    tr:last-child td {
        border-bottom: none;
    }

    tfoot th {
        border-bottom: none;
        font-weight: bold;
        text-align: right;
    }

    tr {
        height: 20px;
    }
`;


export default LaborEmployPagePrint;