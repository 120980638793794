import React, {useRef, useState} from 'react';
import AxiosNavigate from '../../library/axios-navigate';
import {Header} from '../../compontents/Header';
import MonthCalendar from '../../compontents/MonthCalendar';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import ButtonComponent from '../../compontents/Button';
import {useMutation} from '@tanstack/react-query';
import {downloadRepost} from '../../library/axios-query';
import moment from 'moment';

const LaborDownloadReport = () => {
    const belongRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-01'));


    const downloadFile = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    };

    // blob에서 에러 파싱
    const parseBlobError = async (error) => {
        const blob = error.response.data;
        const text = await blob.text();
        try {
            return JSON.parse(text);
        } catch (e) {
            return {message: 'An unknown error occurred'};
        }
    };


    const {mutate: downloadRepostItem} = useMutation({
        mutationFn: downloadRepost,
        mutationKey: ['downloadRepost'],
        onSuccess: (data) => {
            downloadFile(data, `${moment(selectedDate).format('YYYY년_M월')}_4대보험_신고.zip`); // 원하는 파일 이름으로 설정하세요
        },
        onError: async (error) => {
            if (error.response && error.response.data) {
                const errorData = await parseBlobError(error);
                alert(errorData.message);
            }
        }
    });

    return (
        <AxiosNavigate>
            <div>
                <Header>신고자료</Header>
                <Content>
                    <Info>
                        <CalendarContainer style={{paddingLeft: '16px'}}>
                            <MonthCalendar onChangeDate={(date) => {
                                setSelectedDate(dayjs(date).format('YYYY-MM-01'));
                                if (belongRef.current) {
                                    belongRef.current.clearValue();
                                }
                            }}/>
                        </CalendarContainer>
                    </Info>
                    <Board>
                        <ButtonComponent style={{
                            padding: '0 7px',
                            height: '32px',
                            //  margin: 'auto',
                            lineHeight: '32px',
                            fontWeight: '300'
                        }} onClick={(event) => {
                            event.preventDefault();
                            downloadRepostItem(selectedDate);
                        }}>{'신고자료 다운로드'}</ButtonComponent>
                    </Board>

                </Content>

            </div>
        </AxiosNavigate>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 718px;
    @media only screen and (min-width: 992px) {
        flex-direction: row;
    }
`;
const Icon = styled.img`
    width: 16px;
`;
const Info = styled.div`
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (min-width: 992px) {
        flex-direction: column;
    }
`;
const Board = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    margin-top: 24px;
    padding: 15px;
    box-sizing: border-box;

    @media only screen and (min-width: 992px) {
        margin-top: 0;
    }
`;
const Panel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 32px;
`;
const CalendarContainer = styled.div`
    width: 100%;
    padding: 20px 24px 32px 24px;
    border-radius: 16px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    @media only screen and (min-width: 768px) {
        width: 30%;
    }

    @media only screen and (min-width: 992px) {
        width: 280px;
    }
`;
export default LaborDownloadReport;
