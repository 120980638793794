import React, {forwardRef} from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

const LaborInsuranceDetailPrint = forwardRef((props, ref) => {
    const reportData = props?.data?.getInsuranceReportData;
    const selectedDate = props?.data['date'];

    return (
        <Container ref={ref}>
            <Header>
                <div style={{width: '100%'}}>
                    <Title>4대보험 상세내역</Title>
                    <HeaderGroup>
                        <div><span>대상 기간</span> <h3>{dayjs(selectedDate).format('YYYY년 MM월')}</h3></div>
                        <div><span>총액</span>
                            <h3>{reportData?.total?.total_amt.toLocaleString()}원</h3>
                        </div>
                        <div></div>
                        <div></div>
                        <div><span>국민연금</span>
                            <h3>{reportData?.total?.sum_of_national_pension.toLocaleString()}원</h3>
                        </div>
                        <div><span>건강보험</span>
                            <h3>{reportData?.total?.sum_of_health_insh.toLocaleString()}원</h3></div>
                        <div><span>고용보험</span>
                            <h3>{reportData?.total?.employ_insu.toLocaleString()}원</h3></div>
                        <div><span>산재보험</span>
                            <h3>{reportData?.total?.accident_insu.toLocaleString()}원</h3></div>
                    </HeaderGroup>

                    <TableContainer>
                        <TableWrapper>
                            <Table id={'report-table'}>
                                <thead>
                                <tr>
                                    <th rowSpan={2}>현장명</th>
                                    <th colSpan={3}>국민연금</th>
                                    <th colSpan={3}>건강보험</th>
                                    <th rowSpan={2}>고용보험</th>
                                    <th rowSpan={2}>산재보험</th>
                                </tr>
                                <tr>
                                    <th>회사분</th>
                                    <th>개인분</th>
                                    <th>계</th>
                                    <th>회사분</th>
                                    <th>개인분</th>
                                    <th className={'print_border'}>계</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    reportData?.insuranceReport?.map((e, i) => (
                                        <tr key={e.site_seq}>
                                            <td style={{textAlign: 'start'}}>{e.site_nm}</td>
                                            <td className={'table-right'}>{e.company_national_pension.toLocaleString()}</td>
                                            <td className={'table-right'}>{e.personal_national_pension.toLocaleString()}</td>
                                            <td className={'table-right'}>{e.sum_of_national_pension.toLocaleString()}</td>
                                            <td className={'table-right'}>{e.company_health_insu.toLocaleString()}</td>
                                            <td className={'table-right'}>{e.personal_health_insu.toLocaleString()}</td>
                                            <td className={'table-right'}>{e.sum_of_health_insu.toLocaleString()}</td>
                                            <td className={'table-right'}>{e.employ_insu.toLocaleString()}</td>
                                            <td className={'table-right'}>{e.accident_insu.toLocaleString()}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                                <tfoot>
                                <tr style={{height: '24px'}}>
                                    <th>합계</th>
                                    <th className={'table-right'}>{reportData?.total?.company_national_pension.toLocaleString()}</th>
                                    <th className={'table-right'}>{reportData?.total?.personal_national_pension.toLocaleString()}</th>
                                    <th className={'table-right bold'}>{reportData?.total?.sum_of_national_pension.toLocaleString()}</th>
                                    <th className={'table-right'}>{reportData?.total?.company_health_insu.toLocaleString()}</th>
                                    <th className={'table-right'}>{reportData?.total?.personal_health_insu.toLocaleString()}</th>
                                    <th className={'table-right bold'}>{reportData?.total?.sum_of_health_insh.toLocaleString()}</th>
                                    <th className={'table-right bold'}>{reportData?.total?.employ_insu.toLocaleString()}</th>
                                    <th className={'table-right bold'}>{reportData?.total?.accident_insu.toLocaleString()}</th>
                                </tr>
                                </tfoot>
                            </Table>
                        </TableWrapper>
                    </TableContainer>
                </div>
                <div>
                </div>
            </Header>
        </Container>
    );
});

const Container = styled.div`
    background: #FFF;
    padding: 20px 24px;

    @page {
        size: A4 portrait !important;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    @media print {

        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }

        .table-wrapper {
            //break-inside: auto;
            break-after: page;
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    column-gap: 10px;
    box-sizing: border-box;
    margin-top: 15px;

    > div {
        display: flex;
        align-items: center;
        column-gap: 10px;

        span {
            width: 60px;
            display: block;
        }

        h3 {
            margin: 0;
            font-size: 16px;
            white-space: nowrap;
        }
    }
`;

const Badge = styled.div`
    display: flex;
    padding: 6px 6px;
    //align-items: flex-start;
    border-radius: 6px;
    background: #F7FAFC;
    align-items: center;

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const Label = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 8px;
    text-wrap: nowrap;
    margin-right: 8px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;

    font-style: normal;
    font-weight: 450;
    //line-height: 8px;

`;

const TableContainer = styled.div`
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    // border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
    border-radius: 6px;
    border: 1px solid #000;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;

        &:not(:last-child) {
            //border-bottom: none;

        }
    }

    th {
        background-color: #EDF2F7;
        color: #4A5568;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;

    }

    td {
        color: #1A202C;
        padding: 5px;
    }

    tr:last-child td {
        //border-bottom: none;
    }

    tfoot th {
        border-bottom: none;
        // font-weight: bold;
        text-align: right;
    }

    tr {
        height: 20px;
    }
`;
export default LaborInsuranceDetailPrint;
