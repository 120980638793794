import styled from '@emotion/styled';
import dayjs from 'dayjs';
import {forwardRef} from 'react';

const ReportPagePrint = forwardRef((props, ref) => {
    const report = props.data.report;

    return (
        <Container ref={ref}>
            <Header>
                <div>
                    <Title>공사일보</Title>
                    <HeaderGroup>
                        <Badge>
                            <Label>작성일</Label>
                            <Text>{dayjs(report.rpt_dt).format('YYYY년 M월 D일')}</Text>
                        </Badge>

                        <Badge>
                            <Label>작성자</Label>
                            <Text>{report.manager_nm}</Text>
                        </Badge>

                        <Badge>
                            <Label>현장명</Label>
                            <Text>{report.site_nm}</Text>
                        </Badge>
                    </HeaderGroup>
                </div>
                <CheckGroup>
                    <div><span>작성</span>
                        <div></div>
                    </div>
                    <div><span>검토</span>
                        <div></div>
                    </div>
                    <div><span>승인</span>
                        <div></div>
                    </div>
                </CheckGroup>

            </Header>

            <TableContainer>
                <TableWrapper>
                    <Table>
                        <thead style={{height: 'auto'}}>
                        <tr>
                            <th colSpan={3} width={'50%'}><Cell>금일업무</Cell></th>
                            <th colSpan={3} width={'50%'}><Cell>명일업무</Cell></th>
                        </tr>
                        </thead>
                        <tr>
                            <td colSpan={3}><LargeCell>{report.todo}</LargeCell></td>
                            <td colSpan={3}><LargeCell>{report.tobe}</LargeCell></td>
                        </tr>
                        <tr>
                            <th colSpan={6}><Cell>현장사진</Cell></th>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <LargeCell>
                                    <ImageList style={{
                                        gridTemplateRows: '1fr',
                                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'
                                    }}>
                                        {
                                            report.images?.map((e, i) =>
                                                <ImageCard key={i}>
                                                    <ImageItem key={e.img_seq} src={e.rpt_img}/>
                                                </ImageCard>
                                            )
                                        }
                                    </ImageList>
                                </LargeCell></td>
                        </tr>
                        <tr>
                            <th colSpan={6}><Cell>출역인원</Cell></th>
                        </tr>
                        <tr>
                            <th colSpan={3}><Cell>구분</Cell></th>
                            {/*<th colSpan={2}><Cell>업체명</Cell></th>*/}
                            <th colSpan={3}><Cell>금일</Cell></th>
                        </tr>
                        <tr>
                            <td colSpan={3}><Cell>직영</Cell></td>
                            {/*<td colSpan={2}><Cell></Cell></td>*/}
                            <td colSpan={3}><Cell>{report.laborer[0].direct_cnt}</Cell></td>
                        </tr>
                        <tr>
                            {/*외주 추가 시 루프로 할 수 있게 수정해야 함.*/}
                            <td colSpan={3}><Cell>외주</Cell></td>
                            {/*<td colSpan={2}><Cell></Cell></td>*/}
                            <td colSpan={3}><Cell>{report.laborer[0].outsourcing_cnt}</Cell></td>
                        </tr>
                        <tr>
                            <td colSpan={3}><Cell>기타</Cell></td>
                            {/*<td colSpan={2}><Cell></Cell></td>*/}
                            <td colSpan={3}><Cell>{report.laborer[0].ect_cnt}</Cell></td>
                        </tr>
                        <tr>
                            <td colSpan={4}><Cell>누계</Cell></td>
                            <td colSpan={2}><Cell>{report.laborer[0].total_cnt}</Cell></td>
                        </tr>
                        {/*<tr><td colSpan={6}></td></tr>*/}
                        <tr>
                            <th colSpan={6}><Cell>출역장비</Cell></th>
                        </tr>
                        <tr>
                            <th colSpan={3}><Cell>장비명</Cell></th>
                            {/*<th colSpan={2}><Cell>업체명</Cell></th>*/}
                            <th colSpan={3}><Cell>공수</Cell></th>
                        </tr>

                        {report?.detail?.equipment?.map((e, i) =>
                            <tr key={i}>
                                <td colSpan={3}><Cell>{e.equip_nm}</Cell></td>
                                {/*<td colSpan={2}><Cell>{e.partner_nm}</Cell></td>*/}
                                <td colSpan={3}><Cell>{e.effort}</Cell></td>
                            </tr>
                        )
                        }
                        {/*<tr><td colSpan={6}></td></tr>*/}
                        <tr>
                            <th colSpan={6}><Cell>반입자재</Cell></th>
                        </tr>
                        <tr>
                            <th colSpan={3}><Cell>자재명</Cell></th>
                            {/*<th colSpan={2}><Cell>업체명</Cell></th>*/}
                            <th colSpan={3}><Cell>수량</Cell></th>
                        </tr>

                        {report?.detail?.material?.map((e, i) =>
                            <tr key={i}>
                                <td colSpan={3}><Cell>{e.item}</Cell></td>
                                {/*<td colSpan={2}><Cell>{e.corp_nm}</Cell></td>*/}
                                <td colSpan={3}><Cell>{e.quantity}</Cell></td>
                            </tr>
                        )
                        }
                        {/*<tr><td colSpan={6}></td></tr>*/}
                        <tr>
                            <th colSpan={3}><Cell>현안</Cell></th>
                            <th colSpan={3}><Cell>대응방안</Cell></th>
                        </tr>
                        <tr>
                            <td colSpan={3}><LargeCell>{report.issue}</LargeCell></td>
                            <td colSpan={3}><LargeCell>{report.react}</LargeCell></td>
                        </tr>
                    </Table>
                </TableWrapper>
            </TableContainer>

        </Container>
    );

});
const CheckGroup = styled.div`
    display: flex;
    column-gap: 7px;

    > div {
        width: 43px;
        height: 43px;
        border-radius: 2px;
        background-color: #F7FAFC;
        overflow: hidden;
    }

    span {
        background-color: #EDF2F7;
        line-height: 13px;
        text-align: center;
        display: block;
        color: #4A5568;
        font-size: 7px;
        font-weight: 500;
    }

    @media print {
        > div {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }
    }
`;
const Container = styled.div`
    //   border: 1px solid #E2E8F0;
    //border: 1px solid #FFFFFF;
    background: #FFF;
    padding: 20px 24px;

    @page {
        size: A4 portrait !important;
    }
    @media print {
        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }

        .table-wrapper {
            break-after: page;
        }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
    display: flex;
`;

const Badge = styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: flex-start;
    border-radius: 6px;
    background: #F7FAFC;

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const Label = styled.div`
    color: #718096;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    margin-right: 4px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
`;

const Signature = styled.div`
    display: flex;
    width: 43px;
    height: 43px;
    padding-bottom: 0px;
    justify-content: center;
    align-items: flex-start;
    border-radius: 2px;
    background: #F7FAFC;

    &:not(:last-child) {
        margin-right: 6px;
    }
`;

const SignatureTitle = styled.div`
    color: #4A5568;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 7px;
    font-style: normal;
    font-weight: 500;
    line-height: 7px;
    display: flex;
    width: 100%;
    height: 13px;
    justify-content: center;
    align-items: center;
    background: #EDF2F7;
`;

const TableContainer = styled.div`
`;

const TableWrapper = styled.div`
    // border-radius: 8px;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #000;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;

        &:not(:last-child) {
            //border-bottom: none;

        }
    }

    th {
        background-color: #EDF2F7;
        color: #4A5568;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;

    }

    td {
        color: #1A202C;
        padding: 5px;
    }

    tbody tr:last-child td {
        border-bottom: none;
    }


    tr {
        height: 20px;
    }
`;


const Cell = styled.div`
    display: flex;
    height: 24px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
`;

const LargeCell = styled.div`
    min-height: 120px;
    padding: 16px;
    text-align: left;
    white-space: pre-line;
`;

const ImageList = styled.div`
    display: grid;
    place-items: center;
    //margin-bottom: 6px;
`;
const ImageCard = styled.div`
    width: 100%;
`;
const ImageItem = styled.img`
    width: 95%;
    border-radius: 4px;
`;

export default ReportPagePrint;

