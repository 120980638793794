import styled from '@emotion/styled';
import React, {forwardRef} from 'react';

const LaborAttendanceStatusPagePrint = forwardRef((props, ref) => {

    const report = props.data.report;
    const title = props.data.title;

    // 모두 더하는 함수
    function calculateSum(attendanceList, key) {
        if (typeof attendanceList[0][key] === 'float') {
            return parseFloat(attendanceList.reduce((total, item) => total + parseFloat(item[key].toFixed(2)), 0).toFixed(2));
        } else if (typeof attendanceList[0][key] === 'number') {
            return attendanceList.reduce((total, item) => total + item[key], 0).toLocaleString();
        }
    }

    return (
        <Container ref={ref}>
            <Header>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Title>출역현황</Title>
                    <Text>
                        <strong>{title}</strong>
                    </Text>
                </div>
                <div>
                </div>
            </Header>
            <TableContainer className={'table-wrapper'}>
                <TableWrapper>
                    <Table>
                        <thead>
                        <tr>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>현장명</th>
                            <th rowSpan={2}>이름</th>
                            <th rowSpan={2}>출역일수</th>
                            <th colSpan={3}>공수</th>
                            <th colSpan={3}>금액</th>
                        </tr>
                        <tr>
                            <th>금일</th>
                            <th>월누계</th>
                            <th>총누계</th>
                            <th>금일</th>
                            <th>월누계</th>
                            <th>총누계</th>
                        </tr>
                        </thead>
                        <tbody>
                        {report.map((v, index) => {
                            if (report.length !== 1 && index === report.length - 1) return;
                            if (v.attendanceList.length === 0) return;
                            return (<React.Fragment key={index}>
                                {v.attendanceList.map((e, i) => {
                                    {
                                        if (i === 0) {
                                            return <tr key={i}>
                                                <td rowSpan={v.attendanceList.length + 1}>
                                                    <div style={{padding: '8px 0'}}>{index + 1}</div>
                                                </td>
                                                <td style={{textAlign: 'left'}}
                                                    rowSpan={v.attendanceList.length}>{v.site_nm}</td>
                                                <td style={{textAlign: 'left'}}>{e.cmpny_nm}</td>
                                                <td>{e.attend_cnt}</td>
                                                <td>{e.man_day}</td>
                                                <td>{e.month_man_day}</td>
                                                <td>{e.total_man_day}</td>
                                                <td className={'table-right'}>{e.salary_amt.toLocaleString()}원</td>
                                                <td className={'table-right'}>{e.month_salary_amt.toLocaleString()}원</td>
                                                <td className={'table-right'}>{e.total_salary_amt.toLocaleString()}원</td>
                                            </tr>;

                                        } else {
                                            return <tr key={i}>
                                                <td style={{textAlign: 'left'}}>{e.cmpny_nm}</td>
                                                <td>{e.attend_cnt}</td>
                                                <td>{e.man_day}</td>
                                                <td>{e.month_man_day}</td>
                                                <td>{e.total_man_day}</td>
                                                <td className={'table-right'}>{e.salary_amt.toLocaleString()}원</td>
                                                <td className={'table-right'}>{e.month_salary_amt.toLocaleString()}원</td>
                                                <td className={'table-right'}>{e.total_salary_amt.toLocaleString()}원</td>
                                            </tr>;
                                        }
                                    }
                                })}
                                <tr>
                                    <td colSpan={2}>소계</td>
                                    <td>{calculateSum(v.attendanceList, 'attend_cnt')}</td>
                                    <td>{calculateSum(v.attendanceList, 'man_day')}</td>
                                    <td>{calculateSum(v.attendanceList, 'month_man_day')}</td>
                                    <td>{calculateSum(v.attendanceList, 'total_man_day')}</td>
                                    <td className={'table-right'}>{calculateSum(v.attendanceList, 'salary_amt')}원</td>
                                    <td className={'table-right'}>{calculateSum(v.attendanceList, 'month_salary_amt')}원</td>
                                    <td className={'table-right'}>{calculateSum(v.attendanceList, 'total_salary_amt')}원</td>
                                </tr>
                            </React.Fragment>);
                        })}
                        </tbody>
                        {/* footer */}
                        <tfoot>
                        <tr>
                            <th colSpan={6}>합계</th>
                            <th>{report[report.length - 1].attendanceList[0].total_man_day}</th>
                            <th className={'table-right'}>{report[report.length - 1].attendanceList[0].salary_amt.toLocaleString()}원</th>
                            <th className={'table-right'}>{report[report.length - 1].attendanceList[0].month_salary_amt.toLocaleString()}원</th>
                            <th className={'table-right'}>{report[report.length - 1].attendanceList[0].total_salary_amt.toLocaleString()}원</th>
                        </tr>
                        </tfoot>

                    </Table>
                </TableWrapper>
            </TableContainer>

        </Container>
    );
});

const Container = styled.div`
    //border: 1px solid #CBD5E0;
    border: 1px solid #FFFFFF;
    background: #FFF;
    padding: 20px 24px;


    @page {
        size: A4 landscape !important;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    @media print {

        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;

        }

        th, td {
            box-sizing: border-box !important;
        }

        .table-wrapper {
            //break-inside: auto;
            break-after: page;
        }

        body {
            margin: 0;
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
`;

const TableContainer = styled.div`
`;

const TableWrapper = styled.div`
    // border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
    border-radius: 6px;
    border: 1px solid #000;
    box-sizing: border-box;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        border-bottom: 1px solid #000;
        box-sizing: border-box;
        border-right: 1px solid #000;

        &:not(:last-child) {
            //border-bottom: none;

        }
    }

    th {
        background-color: #EDF2F7;
        color: #4A5568;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;

    }

    td {
        color: #1A202C;
        padding: 5px;
    }

    tr:last-child td {
        //border-bottom: none;
    }

    tfoot th {
        border-bottom: none !important;
        font-weight: bold;
        text-align: right;
    }

    tr {
        height: 20px;
    }
`;

export default LaborAttendanceStatusPagePrint;