import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from '@emotion/styled';
import axios from 'axios';
import {HOST_URL} from '../bin/env';

const FileUpload = ({files, setFiles}) => {

    // const [files, setFiles] = useState([]);
    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {

        const formData = new FormData();
        acceptedFiles?.map(e => {
            formData.append('files', e);
        });
        formData.append('filePath', 'board');

        const {data: {result}} = await axios({
            method: 'post',
            url: `${HOST_URL}/upload`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        //  console.log(result);


        let newFiles = [];
        acceptedFiles.filter(file => {
                if (!files.some(existingFile => existingFile.name == file.name && existingFile.size === file.size)) {
                    newFiles.push(file);
                }
            }
        );

        const totalFiles = newFiles.length + files.length;
        if (totalFiles > 5) {
            alert('최대 5개의 파일만 선택할 수 있습니다.');
        }
        const allowedNewFiles = newFiles.slice(0, 5 - files.length);

        allowedNewFiles?.map((e, i) => {
            setFiles((prevState => ([...prevState, {
                name: e.name,
                photo: result[i],
                size: e.size
            }])));

        });

    }, [files]);

    const onDropRejected = useCallback((e) => {
        //  alert('파일 1개씩만 올려주세요');
        if (e[0].errors[0].code === 'file-invalid-type') {
            alert('올바르지 않은 파일 입니다');
            return;
        }

        if (e[0].errors[0].code === 'file-too-large') {
            alert('5MB 이하 파일만 선택할 수 있습니다');
            return;
        }

        if (e[0].errors[0].code === 'too-many-files') {
            alert('최대 5개의 파일만 선택할 수 있습니다');
            return;
        }
    }, []);


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDropRejected,
        maxSize: 5 * 1024 * 1024, // 최대 용량 옵션 값 추가
        accept: {
            'image/*': [], // jpg, jpeg, gif, png
            'application/pdf': [], // pdf
            'application/msword': [], // doc
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // docx
            'application/vnd.ms-powerpoint': [], // ppt
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': [], // pptx
            'application/vnd.ms-excel': [], // xls
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // xlsx
            'text/plain': [], // txt
            'text/csv': [], // csv
            'application/x-hwp': [], // hwp
            'application/zip': [] // zip
        },
        //   maxFiles: 5,
        onDrop
    });

    return (
        <div>
            <div {...getRootProps()} style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <div style={{
                    height: '138px',
                    width: 'calc(100% - 110px)',
                    boxSizing: 'border-box',
                    padding: '6px 12px',
                    display: 'flex',
                    gap: '7px',
                    alignItems: files?.length !== 0 ? 'normal' : 'center',
                    borderRadius: '6px',
                    backgroundColor: '#EDF2F7',
                    flexDirection: 'column',
                    overflowY: 'auto'
                }}>
                    {isDragActive || files?.length === 0 ?
                        <span style={{
                            textAlign: 'center',
                            margin: 'auto',
                            fontSize: '14px',
                            cursor: 'pointer'
                        }}>파일을 여기에 드래그하거나 클릭하여 선택하세요. <br/> (5MB 이하, 최대 5개) <br/>업로드 가능한 파일 확장자 : hwp, doc, docx, ppt, pptx, xls, xlsx, txt, csv, jpg, jpeg, gif, png, pdf, zip </span> : files?.map((e, i) => {
                            return (<FileWrap key={e.name} onClick={e => {
                                e.stopPropagation();
                            }}> <span>
                                {e.name}
                            </span>
                                <img alt={'XButton'} src="/assets/icons/left-icon.svg" onClick={event => {
                                    event.stopPropagation();
                                    const selected = e.name;

                                    setFiles(prevState => prevState.filter((file, index) => file.name !== selected));
                                }}/>
                            </FileWrap>);
                        })}
                </div>
                <input {...getInputProps()} />
                {
                    <span style={{
                        backgroundColor: '#3279F5',
                        color: '#fff',
                        boxSizing: 'border-box',
                        cursor: 'pointer',
                        height: '36px',
                        padding: '6px 12px',
                        display: 'block',
                        fontSize: '14px',
                        lineHeight: '25px',
                        borderRadius: '6px'
                    }}>파일업로드</span>
                }
            </div>
        </div>
    );

};
const FileWrap = styled.div`
    padding: 2px 5px;
    box-sizing: border-box;
    background-color: #fff;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    align-items: center;
    column-gap: 15px;
    color: #333;

    img {
        width: 20px;
        height: 20px;
    }

    span {
        display: block;
        word-break: break-all;
    }
`;
export default FileUpload;
