import styled from '@emotion/styled';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Header} from '../../compontents/Header';
import Modal from '../../compontents/Modal';
import Calendar from '../../compontents/Calendar';
import LaborAttendanceStatusPagePrint from '../../compontents/print/LaborAttendanceStatusPrint';
import {useReactToPrint} from 'react-to-print';
import {HOST_URL} from '../../bin/env';
import dayjs from 'dayjs';
import DownLoadExcel from '../../library/handle-excel';
import SiteContainers from '../../compontents/SiteContainer';
import axiosInstance from '../../library/axios-index';
import AxiosNavigate from '../../library/axios-navigate';
import {BlockScrolling, resetPageStyle, setPageStyle} from '../../bin/common';
import Loading from '../../compontents/Loading';
import SearchBox from '../../compontents/Search';

// 출역 현황
function LaborAttendanceStatus() {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: () => {
            const style = `@page { size: A4 landscape !important; }`;
            setPageStyle(style);
            return style;
        },
        onAfterPrint: resetPageStyle,
    });

    const [opendExport, setOpendExport] = useState(false);
    const [loading, setLoading] = useState(true);

    const [sites, setSites] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [report, setReport] = useState(undefined);
    const [titleName, setTitleName] = useState('');
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedSite, setSelectedSite] = useState(0);
    const [selectedType, setSelectedType] = useState('E');
    const [searchName, setSearchName] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [calendarData, setCalendarData] = useState([]);

    const handleAllSites = useCallback(async () => {

        const token = localStorage.getItem('admin_token');

        const {data: {result}} = await axiosInstance.post(`/admin/searchSite`, {
            lang: 'ko',
            uuid: 'string',
            token,
            search_txt: ''
        });

        setSites(result);
        setSiteList(result);
        setSelectedSite(0);
    }, []);

    BlockScrolling(opendExport);
    // 현장 검색
    const handleSearchSite = (value) => {
        const list = siteList.filter((e) => e.site_nm.toLowerCase().replaceAll(' ', '').includes(value.toLowerCase().replaceAll(' ', '')));
        setSites(list);
    };

    const handleSelectedSite = (e) => {
        setSelectedSite(e);
    };

    const loadReport = async () => {

        const token = localStorage.getItem('admin_token');

        const {data: {result}} = await axiosInstance.post(`${HOST_URL}/admin/laborAttendanceStatusList`, {
            lang: 'ko',
            uuid: 'stirng',
            token,
            site_seq: selectedSite,
            work_dt: selectedDate,
            view_type: selectedType,
            search_txt: searchName,
        });

        setCalendarData(result.attendanceDate);

        if (!Object.keys(result).length) {
            alert('출역 현황이 없습니다');
            setSelectedSite(0);
            setReport(undefined);
            return;
        }

        setTitleName(result.titleName);
        setReport(result.attendanceStatusList);
        // setSearchValue('');
        // setTimeout(() => {
        //     setLoading(false);
        // }, 700);
    };

    const handleExcel = () => {
        if (report.length < 2) {
            alert('출역 현황이 없습니다.');
            return;
        }


        DownLoadExcel({txt: '출역현황', date: dayjs(selectedDate).format('YYYY-MM-DD'), site: titleName});
    };

    const handleOpenPrintModal = useCallback(() => {
        setOpendExport(true);

    }, []);

    // 모두 더하는 함수
    function calculateSum(attendanceList, key) {
        if (typeof attendanceList[0][key] === 'float') {
            return parseFloat(attendanceList.reduce((total, item) => total + parseFloat(item[key].toFixed(2)), 0).toFixed(2));
        } else if (typeof attendanceList[0][key] === 'number') {
            return attendanceList.reduce((total, item) => total + item[key], 0).toLocaleString();
        }
    }

    useEffect(() => {

        (async () => {
            if (!selectedDate) return;
            setLoading(true);
            loadReport();
        })();
    }, [selectedDate, selectedSite, selectedType, searchName]);

    useEffect(() => {
        (async () => {
            handleAllSites();
        })();

    }, []);

    // 데이터 렌더링 완료 0.5초 후에 로딩 제거
    useEffect(() => {
        if (!report) return;
        setTimeout(() => {
            setLoading(false);
        }, 700);
    }, [report, selectedType]);
    return (
        <AxiosNavigate>
            <div>
                <Header>출역 현황</Header>
                <Content>
                    <Info>
                        <CalendarContainer style={{paddingLeft: '16px'}}>
                            <Calendar
                                onChangeDate={(e) => {
                                    setSelectedDate(e);
                                }}
                                data={calendarData}
                            />
                        </CalendarContainer>
                        <SiteContainers
                            data={{
                                sites,
                                handleAllSites,
                                handleSearchSite,
                                handleSelectedSite
                            }}
                        />
                    </Info>

                    {loading ? <Loading></Loading> :
                        report
                            ?
                            <Board>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '0 32px'
                                }}>
                                    <BoardHeader>
                                        <h2>{titleName}</h2>
                                    </BoardHeader>
                                    <div>
                                        <ButtonGroup>
                                            <Button onClick={handleExcel}><Icon
                                                src="/assets/icons/excel.svg"/> 엑셀파일</Button>
                                            <Button onClick={handleOpenPrintModal}><Icon
                                                src="/assets/icons/printer.svg"/> 출력하기</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>

                                <Panel>
                                    <div>
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => setSelectedType('E')}
                                                style={{
                                                    background: selectedType === 'E' ? '#3279F5' : '#fff',
                                                    color: selectedType === 'E' ? '#fff' : '#4A5568'
                                                }}
                                            >소속별</Button>
                                            <Button
                                                onClick={() => setSelectedType('I')}
                                                style={{
                                                    background: selectedType === 'I' ? '#3279F5' : '#fff',
                                                    color: selectedType === 'I' ? '#fff' : '#4A5568'
                                                }}
                                            >개인별</Button>
                                        </ButtonGroup>
                                    </div>
                                    <div>
                                        <SearchBox placeholder={'소속명 검색'} value={searchValue}
                                                   style={{backgroundColor: '#fff'}}
                                                   onChange={(e) => setSearchValue(e.target.value)}
                                                   onKeyDown={(e) => e.key === 'Enter' && setSearchName(e.target.value)}></SearchBox>
                                    </div>
                                </Panel>

                                <TableContainer>
                                    <TableWrapper>
                                        <Table id={'report-table'}>
                                            <thead>
                                            <tr>
                                                <th rowSpan={2}>구분</th>
                                                <th rowSpan={2}>현장명</th>
                                                <th rowSpan={2}>{selectedType === 'E' ? '소속명' : '이름'}</th>
                                                <th rowSpan={2}>{selectedType === 'E' ? '출역인원' : '출역일수'}</th>
                                                <th colSpan={3}>공수</th>
                                                <th colSpan={3}>금액</th>
                                            </tr>
                                            <tr>
                                                <th>금일</th>
                                                <th>월누계</th>
                                                <th>총누계</th>
                                                <th>금일</th>
                                                <th>월누계</th>
                                                <th>총누계</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {report.map((v, index) => {
                                                if (report.length !== 1 && index === report.length - 1) return;
                                                if (v.attendanceList.length === 0) return;
                                                return (
                                                    <React.Fragment key={index}>
                                                        {v.attendanceList.map((e, i) => {
                                                            // eslint-disable-next-line no-lone-blocks
                                                            {
                                                                if (i === 0) {
                                                                    return <tr key={i}>
                                                                        <td rowSpan={v.attendanceList.length + 1}>
                                                                            <div
                                                                                style={{padding: '8px 0'}}>{index + 1}</div>
                                                                        </td>
                                                                        <td style={{textAlign: 'left'}}
                                                                            rowSpan={v.attendanceList.length}>{v.site_nm}</td>
                                                                        <td style={{textAlign: 'left'}}>{e.cmpny_nm}</td>
                                                                        <td>{e.attend_cnt}</td>
                                                                        <td>{e.man_day}</td>
                                                                        <td>{e.month_man_day}</td>
                                                                        <td>{e.total_man_day}</td>
                                                                        <td className={'table-right'}>{e.salary_amt.toLocaleString()}원</td>
                                                                        <td className={'table-right'}>{e.month_salary_amt.toLocaleString()}원</td>
                                                                        <td className={'table-right'}>{e.total_salary_amt.toLocaleString()}원</td>
                                                                    </tr>;

                                                                } else {
                                                                    return <tr key={i}>
                                                                        <td style={{textAlign: 'left'}}>{e.cmpny_nm}</td>
                                                                        <td>{e.attend_cnt}</td>
                                                                        <td>{e.man_day}</td>
                                                                        <td>{e.month_man_day}</td>
                                                                        <td>{e.total_man_day}</td>
                                                                        <td className={'table-right'}>{e.salary_amt.toLocaleString()}원</td>
                                                                        <td className={'table-right'}>{e.month_salary_amt.toLocaleString()}원</td>
                                                                        <td className={'table-right'}>{e.total_salary_amt.toLocaleString()}원</td>
                                                                    </tr>;
                                                                }
                                                            }
                                                        })}
                                                        <tr>
                                                            <td colSpan={2}>소계</td>
                                                            <td>{calculateSum(v.attendanceList, 'attend_cnt')}</td>
                                                            <td>{calculateSum(v.attendanceList, 'man_day')}</td>
                                                            <td>{calculateSum(v.attendanceList, 'month_man_day')}</td>
                                                            <td>{calculateSum(v.attendanceList, 'total_man_day')}</td>
                                                            <td className={'table-right'}>{calculateSum(v.attendanceList, 'salary_amt')}원</td>
                                                            <td className={'table-right'}>{calculateSum(v.attendanceList, 'month_salary_amt')}원</td>
                                                            <td className={'table-right'}>{calculateSum(v.attendanceList, 'total_salary_amt')}원</td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                            </tbody>
                                            {/* footer */}
                                            <tfoot>

                                            <tr style={{height: '24px'}}>
                                                <th colSpan={6}>합계</th>
                                                <th>{report[report.length - 1].attendanceList[0].total_man_day}</th>
                                                <th className={'table-right'}>{report[report.length - 1].attendanceList[0].salary_amt.toLocaleString()}원</th>
                                                <th className={'table-right'}>{report[report.length - 1].attendanceList[0].month_salary_amt.toLocaleString()}원</th>
                                                <th className={'table-right'}>{report[report.length - 1].attendanceList[0].total_salary_amt.toLocaleString()}원</th>
                                            </tr>

                                            </tfoot>
                                        </Table>
                                    </TableWrapper>
                                </TableContainer>
                                <div style={{padding: '30px'}}/>
                            </Board>
                            :
                            <EmptyReport>
                                <div>
                                    <p><EmptyIcon src="/assets/icons/check-circle-broken.svg"/></p>
                                    <p>현장목록에서</p>
                                    <p>현장명을 검색해주세요</p>
                                </div>
                            </EmptyReport>}

                </Content>

                <Modal
                    opend={opendExport}
                    closeModal={() => setOpendExport(false)}
                    header={'출력 미리보기'}
                    okText={'출력'}
                    handleOk={() => {
                        handlePrint();
                    }}
                    widthCheck={'70%'}
                >
                    <LaborAttendanceStatusPagePrint ref={componentRef} data={{report: report, title: titleName}}/>
                </Modal>
            </div>
        </AxiosNavigate>
    );
}

const Content = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
        flex-direction: row;
    }
    @media only screen and (max-width: 992px) {
        .loading-wrap {
            width: 100vw;
            left: 0;
            bottom: 0;
            top: unset;
            height: 400px;
        }
    }
`;

const Info = styled.div`
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (min-width: 992px) {
        flex-direction: column;
    }
`;

const CalendarContainer = styled.div`
    width: 100%;
    padding: 20px 24px 32px 24px;
    border-radius: 16px;
    border: 1px solid #E2E8F0;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

    @media only screen and (min-width: 768px) {
        width: 30%;
    }

    @media only screen and (min-width: 992px) {
        width: 280px;
    }
`;

const Button = styled.div`
    display: flex;
    height: 32px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #fff;
    color: #4A5568;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
`;


const EmptyReport = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
        margin: 0;
        padding: 0;
        color: #A0AEC0;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 992px) {
        min-height: 333px;
        margin-top: 24px;
    }
`;

const EmptyIcon = styled.img`
`;

const Board = styled.div`
    border-radius: 16px;
    background: #F7FAFC;
    width: 100%;
    margin-top: 24px;

    @media only screen and (min-width: 992px) {
        margin-top: 0;
    }
`;


const Panel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 32px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #CBD5E0;
    overflow: hidden;
    background: #fff;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {

    }
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;

const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: center;
        //padding: 8px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #e2e8f0;
        text-align: center;
    }

    > tr > th,
    tr:last-child td {
        border-bottom: none;
    }

    td {
        background: #fff;
    }

    tfoot th {
        border-bottom: none !important;
        //font-weight: bold;
        text-align: right;
    }

`;

const BoardHeader = styled.div`
    margin-bottom: 14px;
    margin-top: 28px;

    h2, h5 {
        margin: 0;
    }

    h5 {
        margin-top: 4px;
    }
`;

export default LaborAttendanceStatus;