import styled from '@emotion/styled';
import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {HOST_URL} from '../bin/env';
import axiosInstance from '../library/axios-index.js';
import {checkRole, numberOnly} from '../bin/common';
import {useRecoilState} from 'recoil';
import {companyState} from '../library/recoil/atoms/companyState';
import SearchAddress from '../compontents/SearchAddress';
import ImageUploader from 'react-image-upload';
import {RoleType} from '../bin/enum';
import {useCookies} from 'react-cookie';
import {useForm} from 'react-hook-form';
import {checkBizRegNum, checkCorpRegNum} from '../library/company-api';

function UpdateCompanyInfo() {
    const [companyValue, setCompanyValue] = useRecoilState(companyState);

    const navigate = useNavigate();
    const location = useLocation();


    const [tab, setTab] = useState(0);
    const [idCheck, setIdCheck] = useState(true);
    const [pwCheck, setPwCheck] = useState('');
    const [phoneCheck, setPhoneCheck] = useState({phone_num: '', check: false});
    const [certKey, setCertKey] = useState({certKey: '', check: false});
    const [zoneCode, setZoneCode] = useState(companyValue?.postal_cd);
    const [logitude, setLogitude] = useState(companyValue?.logitude);
    const [latitude, setLatitude] = useState(companyValue?.latitude);
    const [cookies] = useCookies(['role']);
    const [radioButton, setRadioButton] = useState(1);

    const [allPhotos, setAllPhotos] = useState({
        logo_img: companyValue?.logo_img,
        stamp_img: companyValue?.stamp_img,
        biz_reg_img: companyValue?.biz_reg_img,
    });

    const {register, reset, getValues, setValue, setFocus, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            corp_reg_num1: companyValue?.corp_reg_num?.slice(0, 6),
            corp_reg_num2: companyValue?.corp_reg_num?.slice(6, companyValue?.corp_reg_num?.length),
            biz_reg_num1: companyValue?.biz_reg_num?.slice(0, 3),
            biz_reg_num2: companyValue?.biz_reg_num?.slice(3, 5),
            biz_reg_num3: companyValue?.biz_reg_num?.slice(5, companyValue?.biz_reg_num?.length),
            corp_id: companyValue?.corp_id,
            corp_pw: companyValue?.corp_pw,
            corp_nm: companyValue?.corp_nm,
            ceo_nm: companyValue?.ceo_nm,
            addr1: companyValue?.addr1,
            addr2: companyValue?.addr2,
            phone_num: companyValue?.phone_num,
            fax_num: companyValue?.fax_num,
            manager_nm: companyValue?.manager_nm,
            manager_phone_num: companyValue?.manager_phone_num,
            email_addr: companyValue?.email_addr,
            biz_type: companyValue?.biz_type,
            biz_sector: companyValue?.biz_sector,
            corp_seq: companyValue?.corp_seq,
            plan_id: location?.state?.plan,
            //certkey_num: '',
        }
    });
    const handleAddrAndZoneCode = (fullAddr, zoneCode = '') => {
        setValue('addr1', fullAddr);
        setZoneCode(zoneCode);
    };
    //뒤로가기
    useEffect(() => {

        const handlePopState = e => {

            navigate('/setting/company');
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };

    }, []);

    // 주소 찾기
    const handleLogitudeAndLatitude = (logitude, latitude) => {
        setLogitude((logitude));
        setLatitude((latitude));
    };

    const handleTab = useCallback((tab) => {

        if (tab === 3) {
            navigate('/welcome', {
                state: {
                    plan: getValues('plan_id'),
                },
                replace: true,
            });
            return;
        }

        setTab(tab);

    }, [tab]);

    const getValue = (arr, name) => {
        if (!arr.find(e => e.name === name)) {
            return '';
        } else {
            return arr.find(e => e.name === name).value;
        }
    };

// 계정 라디오 버튼
    const handleRadioButton = useCallback((e) => {
        if (e.target.id == 'prevIdRadioButton') {
            setRadioButton(1);
            setIdCheck(true);
            setValue('corp_id', companyValue?.corp_id);
        } else if (e.target.id == 'newIdRadioButton') {
            setRadioButton(2);
            setValue('corp_id', '');
            setIdCheck(false);

        }

    }, [radioButton]);
    // form 실행
    const onSubmit = async (e) => {

        // 모든 입력값의 앞뒤 공백 제거 및 빈 값은 null로 설정
        const processedData = Object.keys(e).reduce((acc, key) => {
            const trimmedValue = typeof e[key] === 'string' ? e[key].trim() : e[key];
            acc[key] = trimmedValue === '' ? null : trimmedValue;
            return acc;
        }, {});

        if (tab === 0) {

            const corpRegNum = e.corp_reg_num1 + e.corp_reg_num2;
            const bizRegNum = e.biz_reg_num1 + e.biz_reg_num2 + e.biz_reg_num3;

            // 중복된 법인 등록번호 체크
            if (corpRegNum !== '' && corpRegNum !== companyValue?.corp_reg_num) {
                if (await checkCorpRegNum(corpRegNum)) {
                    setFocus('corp_reg_num1');
                    alert('중복된 법인등록번호가 존재합니다');
                    return;
                }
            }

            // 중복된 사업자등록번호 체크
            if (bizRegNum !== '' && bizRegNum !== companyValue?.biz_reg_num) {
                if (await checkBizRegNum(bizRegNum)) {
                    setFocus('biz_reg_num1');
                    alert('중복된 사업자등록번호가 존재합니다');
                    return;
                }
            }


            if (!allPhotos?.stamp_img) {
                alert('법인인감을 등록해 주세요');
                return;
            }
            if (!allPhotos?.biz_reg_img) {
                alert('사업자등록증을 등록해 주세요');
                return;
            }
            // 두번째 탭으로 이동
            setTab(1);
            return;
        } else if (tab === 1) {
            if (phoneCheck.check === false || certKey.check === false) {
                alert('휴대폰 번호 인증을 해주세요');
                return;
            }


            if (radioButton === 1) {
                processedData.corp_pw = null;
            } else {
                if (!idCheck) {
                    alert('아이디 중복 확인해 주세요');
                    return;
                }
            }

            delete processedData.certkey_num;


            try {
                const result = await fetchModify({
                    ...processedData,
                    ...allPhotos,
                    postal_cd: zoneCode,
                    logitude: logitude,
                    latitude: latitude,
                });
                if (result.code === 200) {
                    // 수정하거나 추가된 회사 정보 recoil 저장
                    setCompanyValue((prevState) => ({
                        ...prevState,
                        ...processedData,
                        ...allPhotos,
                        postal_cd: zoneCode,
                        logitude: logitude,
                        latitude: latitude,
                    }));
                    handleTab(3);
                }

            } catch (e) {
                alert(e);
                throw e;
            }

        }
    };

    //form 오류시 실행
    const onError = (errors) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstError = errorKeys[0];
            setFocus(firstError);
            if (errors[firstError].type === 'noSpaces') {
                setValue(firstError, '');
            }
            alert(errors[firstError]?.message || 'Form validation error');
        }
    };


    const fetchModify = async (formData) => {
        const token = localStorage.getItem('admin_token');
        const {data} = await axiosInstance.post(`${HOST_URL}/company/modifyInfo`, {
            lang: 'ko',
            uuid: 'string',
            token,
            ...formData,
            corp_seq: formData.corp_seq,
            biz_reg_num: formData.biz_reg_num1 + formData.biz_reg_num2 + formData.biz_reg_num3,
            corp_reg_num: formData.corp_reg_num1 + formData.corp_reg_num2,
        });

        return data;

    };

    // 아이디 중복 확인
    const checkId = async () => {
        if (idCheck === true) return;
        if (getValues('corp_id') === '') {
            alert('아이디를 입력해주세요');
            return;
        }

        const regex = /^(?=.*[A-Za-z])[A-Za-z0-9]{6,12}$/;
        if (regex.test(getValues('corp_id')) === false) {
            alert('아이디는 6~12자리 영문, 숫자만 가능합니다.');
            return;
        }

        await axiosInstance.post('/company/checkCompanyId', {
            lang: 'ko',
            uuid: 'string',
            corp_id: getValues('corp_id')
        }).then(({data: {result: result}}) => {
            if (result.existed === false) {
                setIdCheck(true);
                alert('사용가능한 아이디 입니다');
            } else {
                alert('이미 사용중인 아이디 입니다');
            }
        }).catch(({response: {data: {message}}}) => {
            alert(message);
        });
    };

    // 인증번호 전송
    const sendPhoneMessage = async () => {

        if (phoneCheck.check === true) return;
        if (getValues('manager_phone_num') === '') {
            alert('휴대폰 번호를 입력해주세요');
            return;
        }

        try {
            await axiosInstance.post('/sms/smsSend', {
                lang: 'ko',
                phone_numb: getValues('manager_phone_num'),
                platform: 'WEB',
                check: false,
            });

            setPhoneCheck({phone_num: getValues('manager_phone_num'), check: true});
            setTimeout(() => {
                if (certKey.check === false) {
                    setPhoneCheck({phone_num: getValues('manager_phone_num'), check: false});
                    setCertKey({certKey: '', check: false});
                }
            }, 180000);
        } catch (e) {
            alert(e.response.data.message);
        }
        setFocus('certkey_num');
    };

    // 인증번호 확인
    const checkCertKey = async () => {
        if (certKey.certKey === '') {
            alert('인증번호를 입력해주세요');
            return;
        }

        await axiosInstance.post('/sms/appSmsCert', {
            lang: 'ko',
            phone_numb: getValues('manager_phone_num'),
            cert_numb: certKey.certKey,
        }).then(({data: {result: result}}) => {
            if (result.done === true) {
                setCertKey({certKey: certKey.certKey, check: true});
                setFocus('email_addr');
                alert('인증되었습니다');
            } else {
                setTimeout(() => {
                    alert('인증번호가 일치하지 않습니다');
                }, 2);

                setValue('certkey_num', '');
                setFocus('certkey_num');
            }
        }).catch(({response: {data}}) => {
            setTimeout(() => {
                alert(data.message);
            }, 2);
            setValue('certkey_num', '');
            setFocus('certkey_num');
        });

    };

    //이미지 삭제
    const handleDeleteFile = async (formName) => {

        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }

        setAllPhotos((prevState) => ({
            ...prevState,
            [formName]: null
        }));
    };

    // 이미지 업로드
    const handleUploadFile = async (formName, e) => {
        if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
            return;
        }

        const formData = new FormData();
        formData.append('files', e.file);

        const {data: {result}} = await axios({
            method: 'post',
            url: `${HOST_URL}/upload`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const img = result[0];

        setAllPhotos((prevState) => ({
            ...prevState,
            [formName]: img
        }));


    };
    // 숫자만 입력
    return (
        <Container>
            <Wrapper>
                {tab != 3 && <Header>

                    <Step>
                        <Circle visibled={0 <= tab}>
                            <img src="/assets/icons/building-06.svg"/>
                        </Circle>
                        <StepText>
                            회사확인
                        </StepText>
                    </Step>

                    <Step>
                        <Circle visibled={1 <= tab}>
                            <img src="/assets/icons/user-check-02.svg"/>
                        </Circle>
                        <StepText>
                            관리자확인
                        </StepText>
                    </Step>

                </Header>}
                <form onSubmit={handleSubmit(onSubmit, onError)}>


                    {tab === 0 && <div>

                        <Card style={{padding: '20px 0 0 0'}}>
                            <Title>
                                <h3>회사확인</h3>
                            </Title>

                            <Content>
                                <Field>
                                    <label>
                                        법인등록번호 <span>*</span>
                                        <br/>
                                        <Input
                                            name="corp_reg_num1"
                                            {...register('corp_reg_num1', {
                                                required: '법인등록번호를 확인해주세요',
                                                maxLength: {
                                                    value: 6,
                                                    message: '법인등록번호를 확인해주세요'
                                                }
                                            })}
                                            style={{width: '47%'}}
                                            type="text"
                                            maxLength={'6'}
                                            onChange={(e) => {
                                                numberOnly(e);
                                                if (e.target.value.length >= e.target.maxLength) {
                                                    setFocus('corp_reg_num2');
                                                }
                                            }}
                                        /> - <Input
                                        {...register('corp_reg_num2', {
                                            required: '법인등록번호를 확인해주세요',
                                            maxLength: {
                                                value: 7,
                                                message: '법인등록번호를 확인해주세요'
                                            }
                                        })}
                                        name="corp_reg_num2"
                                        style={{width: '47%'}}
                                        type="text"
                                        maxLength={'7'}
                                        onChange={(e) => {
                                            numberOnly(e);
                                        }}
                                    />
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        사업자등록번호 <span>*</span>
                                        <br/>
                                        <Input
                                            {...register('biz_reg_num1', {
                                                required: '사업자등록번호를 확인해주세요',
                                                minLength: {
                                                    value: 3,
                                                    message: '사업자등록번호를 확인해주세요'
                                                }

                                            })}
                                            name="biz_reg_num1"
                                            type="text"
                                            maxLength={'3'}
                                            onChange={(e) => {
                                                numberOnly(e);
                                                if (e.target.value.length >= e.target.maxLength) {
                                                    setFocus('biz_reg_num2');

                                                }
                                            }}
                                        /> - <Input
                                        name="biz_reg_num2"
                                        {...register('biz_reg_num2', {
                                            required: '사업자등록번호를 확인해주세요',
                                            minLength: {
                                                value: 2,
                                                message: '사업자등록번호를 확인해주세요'
                                            }
                                        })}
                                        type="text"
                                        maxLength={'2'}
                                        onChange={(e) => {
                                            numberOnly(e);
                                            if (e.target.value.length >= e.target.maxLength) {
                                                setFocus('biz_reg_num3');

                                            }
                                        }}
                                    /> - <Input
                                        name="biz_reg_num3"
                                        {...register('biz_reg_num3', {
                                            required: '사업자등록번호를 확인해주세요',
                                            minLength: {
                                                value: 5,
                                                message: '사업자등록번호를 확인해주세요'
                                            }
                                        })}
                                        type="text"
                                        maxLength={'5'}
                                        onChange={(e) => {
                                            numberOnly(e);
                                            if (e.target.value.length >= e.target.maxLength) {
                                                const nextInput = e.target.nextElementSibling;
                                                if (nextInput) {
                                                    nextInput.focus();
                                                }
                                            }
                                        }}
                                    />
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        법인명 <span>*</span>
                                        <br/>
                                        <Input name="corp_nm" fulled type="text"   {...register('corp_nm', {
                                            validate: {
                                                register: (value) => value !== '' || '법인명을 입력해 주세요',
                                                noSpaces: (value) => value.trim() !== '' || '법인명을 입력해 주세요'
                                            }
                                        })}
                                        />
                                    </label>
                                </Field>
                                <Field>
                                    <label>
                                        대표이사명 <span>*</span>
                                        <br/>
                                        <Input name="ceo_nm" fulled type="text"  {...register('ceo_nm', {
                                            validate: {
                                                register: (value) => value !== '' || '대표이사명을 입력해 주세요',
                                                noSpaces: (value) => value.trim() !== '' || '대표이사명을 입력해 주세요'
                                            }
                                        })}
                                        />
                                    </label>
                                </Field>
                                <Field>
                                    <label>
                                        업태 <span>*</span>
                                        <br/>
                                        <Input name="biz_type" fulled type="text"
                                               {...register('biz_type', {
                                                   validate: {
                                                       register: (value) => value !== '' || '업태를 입력해 주세요',
                                                       noSpaces: (value) => value?.trim() !== '' || '업태를 입력해 주세요'
                                                   }
                                               })}
                                        />
                                    </label>
                                </Field>
                                <Field>
                                    <label>
                                        종목 <span>*</span>
                                        <br/>
                                        <Input name="biz_sector" fulled type="text"
                                               {...register('biz_sector', {
                                                   validate: {
                                                       register: (value) => value !== '' || '종목을 입력해 주세요',
                                                       noSpaces: (value) => value?.trim() !== '' || '종목을 입력해 주세요'
                                                   }
                                               })}
                                        />
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        사업장 소재지 <span>*</span>
                                        <br/>
                                        <SearchAddress setAddrAndZoneCode={handleAddrAndZoneCode}
                                                       setLongitudeAndLatitude={handleLogitudeAndLatitude}>
                                        </SearchAddress>
                                        <Input name="addr1" fulled type="text" placeholder="주소찾기를 이용하세요" readOnly={true}
                                               {...register('addr1', {
                                                   required: '주소를 입력해주새요'
                                               })}
                                        />
                                        <Input name="addr2" fulled type="text" placeholder="상세 주소" maxLength={50}
                                               {...register('addr2')}
                                        />
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        대표전화 <span>*</span>
                                        <br/>
                                        <Input
                                            name="phone_num"
                                            fulled type="text"
                                            {...register('phone_num', {
                                                validate: {
                                                    register: (value) => value.length >= 8 || '대표전화를 입력해 주세요',
                                                    //  noSpaces: (value) => /^(01[016789]{1})?[0-9]{3,4}?[0-9]{4}$/.test(value.trim()) || '대표전화 형식이 올바르지 않습니다.'
                                                }
                                            })}
                                            maxLength={11}
                                            onChange={(e) => {
                                                numberOnly(e);
                                            }}

                                        />
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        팩스번호
                                        <br/>
                                        <Input
                                            name="fax_num"
                                            fulled type="text"
                                            maxLength={11}
                                            {...register('fax_num')}
                                            onChange={(e) => {
                                                numberOnly(e);
                                            }}

                                        />
                                    </label>
                                </Field>
                                {/*<br/>*/}
                                {/*<h5>첨부자료</h5>*/}
                                <Field>
                                    <label>
                                        회사 로고
                                        <br/>
                                        <Uploader>
                                            <ImageUploader
                                                onFileRemoved={(e) => {

                                                    if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                                        handleDeleteFile('logo_img');
                                                    }
                                                }}
                                                onFileAdded={(e) => {
                                                    if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {

                                                        handleUploadFile('logo_img', e);
                                                    }
                                                }}
                                                deleteIcon={allPhotos?.logo_img ?
                                                    <ImageDelete className="delete-label" onClick={(event) => {
                                                        event.preventDefault();
                                                    }}>
                                                        <ImageButton style={{color: '#E53E3E'}}><Icon
                                                            src="/assets/icons/trash-04.svg"/> 삭제</ImageButton>
                                                    </ImageDelete> : <div></div>}
                                                uploadIcon={!allPhotos?.logo_img ? <>
                                                    <ImagePreview>
                                                        <img src="/assets/icons/image-03.svg" alt={'upload'}/>
                                                        <ImageButton>이미지 업로드</ImageButton>
                                                    </ImagePreview>
                                                    <br/>
                                                </> : <>
                                                    <ImageWrapper className="upload-label">
                                                        <ImageButton>이미지 재업로드</ImageButton>
                                                    </ImageWrapper>
                                                </>}
                                            />
                                            <ImageDefault src={allPhotos?.logo_img}/>
                                            {allPhotos?.logo_img && <div className="hover-wrapper"></div>}
                                        </Uploader>
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        사업자등록증 사본 <span>*</span>
                                        <br/>
                                        <Uploader>
                                            <ImageUploader
                                                onFileRemoved={(e) => {

                                                    if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                                        handleDeleteFile('biz_reg_img');
                                                    }
                                                }}
                                                onFileAdded={(e) => {
                                                    if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {

                                                        handleUploadFile('biz_reg_img', e);
                                                    }
                                                }}
                                                deleteIcon={allPhotos?.biz_reg_img ?
                                                    <ImageDelete className="delete-label" onClick={(event) => {
                                                        event.preventDefault();
                                                    }}>
                                                        <ImageButton style={{color: '#E53E3E'}}><Icon
                                                            src="/assets/icons/trash-04.svg"/> 삭제</ImageButton>
                                                    </ImageDelete> : <div></div>}
                                                uploadIcon={!allPhotos?.biz_reg_img ? <>
                                                    <ImagePreview>
                                                        <img src="/assets/icons/image-03.svg" alt={'upload'}/>
                                                        <ImageButton>이미지 업로드</ImageButton>
                                                    </ImagePreview>
                                                    <br/>
                                                </> : <>
                                                    <ImageWrapper className="upload-label">
                                                        <ImageButton>이미지 재업로드</ImageButton>
                                                    </ImageWrapper>
                                                </>}
                                            />
                                            <ImageDefault src={allPhotos.biz_reg_img}/>
                                            {allPhotos.biz_reg_img && <div className="hover-wrapper"></div>}
                                        </Uploader>
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        법인직인 사본 <span>*</span>
                                        <br/>
                                        <Uploader>
                                            <ImageUploader
                                                onFileRemoved={(e) => {

                                                    if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                                        handleDeleteFile('stamp_img');
                                                    }
                                                }}
                                                onFileAdded={(e) => {
                                                    if (checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
                                                        handleUploadFile('stamp_img', e);
                                                    }
                                                }}
                                                deleteIcon={allPhotos?.stamp_img ?
                                                    <ImageDelete className="delete-label" onClick={(event) => {
                                                        event.preventDefault();
                                                    }}>
                                                        <ImageButton style={{color: '#E53E3E'}}><Icon
                                                            src="/assets/icons/trash-04.svg"/> 삭제</ImageButton>
                                                    </ImageDelete> : <div></div>}
                                                uploadIcon={!allPhotos?.stamp_img ? <>
                                                    <ImagePreview>
                                                        <img src="/assets/icons/image-03.svg" alt={'upload'}/>
                                                        <ImageButton>이미지 업로드</ImageButton>
                                                    </ImagePreview>
                                                    <br/>
                                                </> : <>
                                                    <ImageWrapper className="upload-label">
                                                        <ImageButton>이미지 재업로드</ImageButton>
                                                    </ImageWrapper>
                                                </>}
                                            />
                                            <ImageDefault src={allPhotos?.stamp_img}/>
                                            {allPhotos?.stamp_img && <div className="hover-wrapper"></div>}
                                        </Uploader>
                                    </label>
                                </Field>

                            </Content>
                        </Card>

                        {/*<Button onClick={handleConfirmCompanyInfo}>*/}
                        {/*    다음*/}
                        {/*</Button>*/}

                    </div>}

                    {tab === 1 && <div>
                        <Card style={{padding: '20px 0 0 0'}}>
                            <Title>
                                <h3>관리자 확인</h3>
                            </Title>
                            <Content style={{marginBottom: '28px'}}>
                                <p>앞으로 시스템을 관리 할 관리자의 정보를 입력해주세요.</p>
                            </Content>

                            <Content>

                                <Field>
                                    <label>계정</label>
                                    <div style={{display: 'flex', alignItems: 'center', height: '30px'}}>
                                        <input type={'radio'} id={'prevIdRadioButton'} checked={radioButton === 1}
                                               onChange={(e) => handleRadioButton(e)}/>
                                        <h4>&nbsp;&nbsp;기존 계정 사용</h4>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', height: '30px'}}>
                                        <input type={'radio'} id={'newIdRadioButton'} checked={radioButton === 2}
                                               onChange={(e) => handleRadioButton(e)}/>
                                        <h4>&nbsp;&nbsp;새로운 계정 변경</h4>
                                    </div>
                                </Field>

                                <Field>
                                    <label>
                                        아이디 <span>*</span>
                                        <br/>

                                        {radioButton === 1 ? <em>{getValues('corp_id')}</em> : <>
                                            <Input
                                                name="corp_id"
                                                style={{width: '70%'}}
                                                {...register('corp_id', {
                                                    validate: {
                                                        register: (value) => value !== '' || '아이디를 입력해 주세요',
                                                        noSpaces: (value) => /^[A-Za-z0-9]{6,12}$/.test(value.trim()) || '아이디 형식이 올바르지 않습니다.'
                                                    }
                                                })}
                                                disabled={idCheck}
                                                placeholder="6~12자리 영문, 숫자"

                                            />
                                            <FieldButton
                                                onClick={checkId}
                                                style={{width: '22%'}}
                                                disabled={idCheck}
                                            >중복확인</FieldButton>
                                        </>
                                        }

                                    </label>
                                </Field>
                                {radioButton === 2 && (<> <Field>
                                    <label>
                                        비밀번호 <span>*</span>
                                        <br/>
                                        <Input
                                            name="corp_pw"
                                            fulled type="password"
                                            placeholder="영문, 숫자, 특수문자를 포함한 8~16자리"
                                            {...register('corp_pw',
                                                {
                                                    required: '비밀번호를 입력해주세요',
                                                    pattern: {
                                                        value: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/,
                                                        message: '비밀번호는 8~15 자리 영문,숫자,특수문자를 포함해야 합니다.'
                                                    }
                                                })}
                                        />
                                        <div style={{color: 'red', fontSize: '12px'}}>
                                            {errors?.corp_pw?.message}
                                        </div>
                                    </label>
                                </Field>

                                    <Field>
                                        <label>
                                            비밀번호 확인 <span>*</span>
                                            <br/>
                                            <Input
                                                name="corp_pw2"
                                                fulled type="password"
                                                onChange={(ev) => {
                                                    setPwCheck(ev.target.value);
                                                }}

                                            />
                                            {
                                                getValues('corp_pw') !== pwCheck &&
                                                <div style={{color: 'red', fontSize: '12px'}}>
                                                    비밀번호가 일치하지 않습니다.
                                                </div>
                                            }

                                        </label>
                                    </Field></>)}


                                <h5>관리자 정보</h5>

                                <Field>
                                    <label>
                                        이름 <span>*</span>
                                        <br/>
                                        <Input name="manager_nm" fulled type="text" {...register('manager_nm', {
                                            validate: {
                                                register: (value) => value !== '' || '이름을 입력해 주세요',
                                                noSpaces: (value) => value?.trim() !== '' || '이름을 입력해 주세요'
                                            }
                                        })}

                                        />
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        휴대폰번호 <span>*</span>
                                        <br/>
                                        <Input
                                            {...register('manager_phone_num')}
                                            name="manager_phone_num"
                                            style={{width: '70%'}}
                                            type="text"
                                            placeholder="- 제외한 숫자만 입력해주세요"
                                            maxLength={11}
                                            // disabled={phoneCheck.check}
                                            disabled={false}
                                            onChange={(ev) => {
                                                numberOnly(ev);
                                                setCertKey({certKey: '', check: false});
                                                setPhoneCheck(prevState => ({check: false}));
                                                setValue('certkey_num', '');
                                            }}
                                        />
                                        <FieldButton onClick={sendPhoneMessage} style={{width: '22%'}}
                                                     disabled={phoneCheck.check}>인증번호 전송</FieldButton>

                                        <Input
                                            {...register('certkey_num')}
                                            name="certkey_num"
                                            style={{width: '70%'}}
                                            type="text"
                                            maxLength={6}
                                            placeholder="인증번호 6자리를 입력해주세요"
                                            //  value={certKey.certKey}
                                            // disabled={certKey.check}
                                            onChange={(ev) => {
                                                numberOnly(ev);
                                                setCertKey({certKey: ev.target.value, check: false});

                                            }}
                                        />
                                        <FieldButton onClick={checkCertKey} style={{width: '22%'}}
                                                     disabled={certKey.check}>인증하기</FieldButton>
                                    </label>
                                </Field>

                                <Field>
                                    <label>
                                        이메일 <span>*</span>
                                        <br/>
                                        <Input
                                            name="email_addr"
                                            fulled type="text"
                                            {...register('email_addr', {
                                                validate: {
                                                    register: (value) => value !== '' || '이메일을 입력해 주세요',
                                                    noSpaces: (value) => value?.trim() !== '' || '이메일을 입력해 주세요'
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: '이메일 형식이 올바르지 않습니다.'
                                                }
                                            })}
                                        />
                                        {errors?.email_addr && <div style={{color: 'red', fontSize: '12px'}}>
                                            {errors?.email_addr?.message}
                                        </div>}

                                    </label>
                                </Field>
                            </Content>

                        </Card>

                        {/*<Button onClick={handleConfirmCompanyAdmin}>*/}
                        {/*    확인*/}
                        {/*</Button>*/}
                    </div>}

                    <Button type="submit">
                        {tab === 0 ? '다음' : '확인'}
                    </Button>
                </form>
            </Wrapper>

        </Container>
    );
}

export default UpdateCompanyInfo;

const Container = styled.div`
    background: #F7FAFC;
    min-height: 100vh;
    height: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 60px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
`;

const Step = styled.div``;

const Circle = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    margin-bottom: 4px;
    //cursor: pointer;

    img {
        width: 24px;
        height: 24px;
    }

    background: ${({visibled}) => visibled ? '#3279F5' : '#BFD5FC'};
`;

const StepText = styled.div`
    color: #2D3748;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const Button = styled.button`
    display: flex;
    width: 100%;
    height: 32px;
    margin-top: 24px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3279F5;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    padding: 0 24px;

    h3 {
        margin: 0;
        padding: 0;
    }
`;

const Card = styled.div`
    background: #fff;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    padding-bottom: 20px;
`;

const Content = styled.div`
    margin: 16px 0;
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 24px;

    h5 {
        margin: 0;
        margin-bottom: 16px;
        color: #2D3748;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
    }

    p {
        margin: 0;
        padding: 0;
        color: #4A5568;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    span {
        color: #E53E3E
    }
`;

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    background: #F7FAFC;
    padding: 24px;
    margin-bottom: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;


const Input = styled.input`
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 30%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #FFF;
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    margin-bottom: 4px;
    margin-top: 6px;

    :focus {

    }

    ${({fulled}) => fulled ? `width: 100%;` : ''}
`;

const Field = styled.div`
    margin-bottom: 16px;

    em {
        font-style: normal;
        color: #000;
        margin-top: 5px;
        display: block;
        font-size: 16px;
    }
`;

const FieldButton = styled.div`
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: inline-block;
    padding: 8px;
    border-radius: 6px;
    background: #4A5568;
    margin-left: 8px;
    cursor: pointer;
    text-align: center;

    ${props => props.disabled && `
  background: #CBD5E0;
  cursor: default;
`}
`;

const ImagePreview = styled.div`
    display: flex;
    //padding: 80px 0;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 6px;
    border: 1px dashed #CBD5E0;
    background: #FFF;
    margin: auto;
    justify-content: center;
    width: 200px;
    height: 200px;
    //margin: 0 12px;
    cursor: pointer;
`;

const ImageWrapper = styled.div`
    position: absolute;
    //top: 50%;
    //left: 10%;
    //top: 20px;
    bottom: 20%;
    transform: translateX(-50%);
    left: 50%;
    z-index: 999;
`;

const ImageDelete = styled.div`
    position: absolute;
    top: 20px;
    transform: translateX(-50%);
    left: 50%;
    z-index: 999;
`;

const ImageButton = styled.div`
    color: #2D3748;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    display: flex;
    height: 32px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #CBD5E0;
    background: #FFF;
    cursor: pointer;
`;

const Uploader = styled.div`
    cursor: pointer;
    //background: red;
    width: 200px;
    margin: auto auto 30px;
    text-wrap: nowrap;
    height: 200px;
    //margin: auto;
    box-sizing: border-box;

    .uploader__file {
        display: none;

    }

    .delete-label,
    .upload-label {
        display: none;
        //display: inline-block;
    }

    position: relative;

    .hover-wrapper {
        border-radius: 6px;
        border: 1px solid #CBD5E0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    //background: rgba(0, 0, 0, 0.30);

    &:hover {
        //display: flex;

        .hover-wrapper {
            background: rgba(0, 0, 0, 0.30);
        }

        .delete-label,
        .upload-label {
            display: inline-block;
        }
    }
`;

const ImageDefault = styled.img`
    width: inherit;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const Icon = styled.img`
    margin-right: 6px;
`;
