import styled from '@emotion/styled';
import {Header} from '../../compontents/Header';
import React, {useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import Pagination from 'react-js-pagination';
import SideModal from '../../compontents/SideModal';
import dayjs from 'dayjs';
import {PagingFooter} from '../../compontents/PagingFooter';
import axiosInstance from '../../library/axios-index';
import {idNumToCurrentOld} from '../../bin/common';
import DownLoadExcel from '../../library/handle-excel';
import AxiosNavigate from '../../library/axios-navigate';
import SearchBox from '../../compontents/Search';
import SelectedFilter from '../../compontents/SelectedFilter';
import PagesList from '../../compontents/PagesList';
import {useMutation} from '@tanstack/react-query';
import {getCompanyFilter} from '../../library/admin-react-query';


const selectStyles = {
    control: base => ({
        ...base,
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #E2E8F0',
        },
    })
};

const options = [
    {value: 20, label: '20'},
    {value: 30, label: '30'},
    {value: 50, label: '50'},
    {value: 100, label: '100'}
];

const sortOptions = [
    {value: '1', label: '이름'},
    {value: '2', label: '국적'},
];

const filterOptions = [
    {value: '1', label: '전체 사용자'},
    {value: '2', label: '현재 사용자'},
    {value: '3', label: '탈퇴 사용자'}
];

function User() {

    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [searchTxt, setSearchTxt] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState('');
    const [filter, setFilter] = useState('1');
    const [nations, setNations] = useState({});
    const [companyFilterOption, setCompanyFilterOption] = useState(null);
    const [companyFilter, setCompanyFilter] = useState(null);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [opendDetailUser, setOpendDetailUser] = useState(false);

    // 엑셀 다운로드
    const handleExcel = () => {
        if (users.length < 1) {
            alert('사용자가 없습니다.');
            return;
        }

        DownLoadExcel({txt: '사용자관리', date: dayjs().format('YYYY-MM-DD'), type: 1});

    };

    const handleSelectedUserInfo = (user_seq) => {
        const selectedUserInfo = users.find((user) => user.user_seq === user_seq);
        setSelectedUser(selectedUserInfo);
    };

    // 회사 목록 필터 어드민용
    const {mutate: getCompanyFilterItem} = useMutation({
        mutationKey: ['getCompanyFilter'],
        mutationFn: getCompanyFilter,
        onSuccess: (data) => {
            if (companyFilterOption === null) {
                data.unshift({value: null, label: '전체 회사'});
                setCompanyFilterOption(data);
            }

        },
        onSettled: () => {

        },
        onError: (error) => {
            throw error;
        }
    });


    // 리스트 로드
    const loadUsers = async () => {

        const token = localStorage.getItem('admin_token');

        const {data: {result: {list, count}}} = await axiosInstance.post(`/admin/userList`, {
            lang: 'ko',
            uuid: 'string',
            token,
            search_txt: searchTxt.replaceAll(' ', ''),
            page,
            limit,
            sort,
            filter,
            companyFilter: companyFilter,
        });


        setUsers(list);
        setTotalCount(count);

        return list;

    };

    // 데이터 로드
    useEffect(() => {

        (async () => {

            const token = localStorage.getItem('admin_token');

            const nationResp = await axiosInstance.post(`/code/codeList`, {
                lang: 'ko',
                uuid: 'string',
                token,
                mst_cd_id: 'M0009'
            });

            setNations(nationResp.data.result.reduce((acc, cur) => {

                acc[`${cur.dtl_cd_id}`] = cur.dtl_cd_nm;

                return acc;
            }, {}));

        })();
        // 확인 완료
        getCompanyFilterItem(1);
    }, []);


    useEffect(() => {
        (async () => {
            try {
                await loadUsers();
            } catch (e) {

            }
        })();
    }, [searchTxt, page, limit, sort, filter, companyFilter]);


    return (
        <AxiosNavigate>
            <div>
                <Header>
                    <div>
                        사용자 관리
                    </div>
                </Header>

                <Panel>
                    <div>
                        <SearchBox placeholder={'이름 검색'} onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
                                if (e.target.value.length > 0 && e.target.value.trim().length === 0) {
                                    alert('이름을 입력해 주세요');
                                    setSearchValue(e.target.value.trim());
                                    return;
                                }

                                setSearchTxt(e.target.value);
                                setSearchValue(e.target.value.trim());
                            }
                        }} value={searchValue} onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}></SearchBox>

                    </div>
                    <div>
                        <ButtonGroup>
                            <Button onClick={handleExcel} style={{borderColor: '#CBD5E0', borderRadius: '4px'}}><Icon
                                src="/assets/icons/excel.svg"/> 엑셀파일</Button>
                            <SelectedFilter placeholder={'정렬'} options={sortOptions} onChange={(e) => {
                                setPage(1);
                                setSort(e.value);
                            }}
                            >
                            </SelectedFilter>
                            <SelectedFilter placeholder={filterOptions[0].label} options={filterOptions}
                                            onChange={(e) => {
                                                setPage(1);
                                                setFilter(e.value);
                                            }}>
                            </SelectedFilter>
                            <SelectedFilter
                                placeholder={companyFilterOption ? companyFilterOption[0].label : ''}
                                options={companyFilterOption}
                                onChange={(e) => {
                                    setPage(1);
                                    setCompanyFilter(e.value);
                                }}>
                            </SelectedFilter>
                        </ButtonGroup>
                    </div>
                </Panel>

                <TableContainer>
                    <TableWrapper>
                        <Table id="report-table">
                            <thead>
                            <tr>
                                <th rowSpan={1}>구분</th>
                                <th rowSpan={1}>계정</th>
                                <th rowSpan={1}>소셜구분</th>
                                <th rowSpan={1}>이름</th>
                                <th rowSpan={1}>국적</th>
                                <th rowSpan={1}>나이</th>
                                <th rowSpan={1}>휴대전화</th>
                                <th rowSpan={1}>회사</th>
                                <th rowSpan={1}>소속(현장)</th>
                                <th rowSpan={1}>탈퇴여부</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((e, i) => <tr key={i}>
                                <td>{i + limit * (page - 1) + 1}</td>
                                {/*<td>{i+1}</td>*/}
                                <td>{e.social_email_addr}</td>
                                <td>{e.entry_site}</td>
                                <td>
                                    <div style={{
                                        // width: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>{e.user_nm}<Button
                                        onClick={() => {
                                            handleSelectedUserInfo(e.user_seq);
                                            setOpendDetailUser(true);
                                        }
                                        }
                                        style={{marginLeft: '8px'}}
                                    >상세보기</Button>
                                    </div>
                                </td>
                                <td>{nations[e.nationality]}</td>
                                <td>{e.id_num ? +idNumToCurrentOld(e.id_num) : 0}세</td>
                                <td>{e.phone_num}</td>
                                <td>{e.tb_user_site_maps.length > 0 ? e.tb_user_site_maps[0].site_seq2.corp_seq2.corp_nm : ''}</td>
                                <td>
                                    <div style={{
                                        whiteSpace: 'nowrap',
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                        rowGap: '6px'
                                    }}>
                                        {e.tb_user_site_maps.map((siteMap, i) => {
                                            if (siteMap.appr_type == 'EY' || siteMap.appr_type == 'XR')
                                                return <Badge key={i}
                                                              style={{justifyContent: 'center'}}>{siteMap.site_seq2.site_nm}</Badge>;
                                        })}
                                    </div>
                                </td>
                                <td>{e.withdrawal_yn === 'Y' ? '탈퇴' : ''}</td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </TableWrapper>
                </TableContainer>

                <PagingFooter>
                    <div></div>
                    <Pagination
                        prevPageText={(<Icon src="/assets/icons/chevron-left.svg"/>)}
                        nextPageText={(<Icon src="/assets/icons/chevron-right.svg"/>)}
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        hideFirstLastPages={true}
                        onChange={(e) => setPage(e)}
                    />
                    <PagesList options={options}
                               onChange={(e) => {
                                   setLimit(e.value);
                                   setPage(1);
                               }}></PagesList>

                </PagingFooter>

                {!selectedUser ? null :
                    <SideModal
                        opend={opendDetailUser}
                        closeModal={() => setOpendDetailUser(false)}
                        header={'사용자 상세정보'}
                        buttons={<></>}
                    >
                        <Content>
                            <figure>
                                <h6>이름</h6>
                                <h5>{selectedUser.user_nm}</h5>
                            </figure>
                            <figure>
                                <h6>나이</h6>
                                <h5>{selectedUser.id_num ? +idNumToCurrentOld(selectedUser.id_num) : 0}세</h5>
                            </figure>
                            <figure>
                                <h6>국적</h6>
                                <h5>{nations[selectedUser.nationality]}</h5>
                            </figure>
                            <figure>
                                <h6>혈액형</h6>
                                <h5>{selectedUser.blood_type}형</h5>
                            </figure>
                            <figure>
                                <h6>연락처</h6>
                                <h5>{selectedUser.phone_num}</h5>
                            </figure>
                            <figure style={{marginTop: '15px', marginBottom: '15px'}}>
                                <h6>주소</h6>
                                <h5 style={{width: '60%'}}>{selectedUser.addr1 + ' ' + selectedUser.addr2}  </h5>
                            </figure>
                            <figure>
                                <h6>통장</h6>
                            </figure>
                            <figure>
                                <h6 style={{marginLeft: '5px'}}> - 은행명/계좌번호</h6>
                                <h5>{selectedUser.bank_nm !== null && selectedUser.bank_acct !== null ? selectedUser.bank_nm + ' / ' + selectedUser.bank_acct : ''}</h5>
                                {/*<h5>{selectedUser.bank_nm} / {selectedUser.bank_acct}</h5>*/}
                            </figure>
                            <figure>
                                <h6 style={{marginLeft: '5px'}}> - 예금주</h6>
                                <h5>{selectedUser.bank_user_nm}</h5>
                            </figure>
                            <figure>
                                <h6 style={{marginLeft: '5px'}}> - 본인과의 관계</h6>
                                <h5>{selectedUser.bank_user_rel}</h5>
                            </figure>
                            <figure>
                                <h6>신분증</h6>
                            </figure>
                            <figure>
                                <h6 style={{marginLeft: '5px'}}> - 이름</h6>
                                <h5>{selectedUser.id_user_nm}</h5>
                            </figure>
                            <figure>
                                <h6 style={{marginLeft: '5px'}}> - 등록번호</h6>
                                <h5>{selectedUser.id_num}</h5>
                            </figure>

                            <figure>
                                <h6>피부양자 등록여부</h6>
                                <h5>{selectedUser.resident_photo_01 !== null ? '등록' : '미등록'}</h5>
                            </figure>

                        </Content>

                    </SideModal>
                }
            </div>
        </AxiosNavigate>
    );
}

const Panel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 32px 0px 32px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #CBD5E0;
    overflow: hidden;
`;

const SearchIcon = styled.img`
    padding-left: 12px;
`;

const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :focus {

    }
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const Button = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    min-height: 32px;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6px;
    border: 1.125px solid #E2E8F0;
    background: #FFF;

    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    cursor: pointer;

    //margin-top: 5px;

`;

const Select = styled(ReactSelect)`
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    border-radius: 6px;
    background: #FFF;
    margin-top: 5px;

    div {
        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;
    }
`;

const Icon = styled.img`
    width: 16px;
`;

const TableContainer = styled.div`
    padding: 0px 32px;
    margin-top: 28px;
`;

const TableWrapper = styled.div`
    border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    th, td {
        text-align: left;
        padding: 8px;
        font-size: 13px;
        font-weight: 300;
        border-right: 1px solid #CBD5E0;
        border-bottom: 1px solid #CBD5E0;
        white-space: nowrap;
    }

    th {
        background-color: #F7FAFC;
    }

    tr:last-child td {
        border-bottom: none;
    }


    td:first-of-type {
        text-align: center;
    }

`;


const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;

const Content = styled.div`
    figure {
        margin: 0;
        display: flex;
        align-items: center;
        height: 34px;
    }

    h6, h5 {
        margin: 0;
    }

    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        width: 140px;
    }

    h5 {
        color: rgba(0, 0, 0, 0.92);
        font-size: 14px;
        font-style: normal;
        white-space: pre-wrap;
        font-weight: 500;
        line-height: 20px;
    }

    hr {
        background: #EDF2F7;
        color: #EDF2F7;
        border: 1px solid #EDF2F7;
        margin: 12px 0;
    }
`;

const CalendarContainer = styled.div`
    width: 100%;
    background: #FFF;

    @media only screen and (min-width: 992px) {
        width: 376px;
    }
`;

const FieldGroup = styled.div`
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 0 14px;
`;

const Field = styled.div`
    h6 {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }

    h5 {
        color: #171923;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 16px 0;
    }


    width: ${({fulled}) => fulled ? `100%` : '48%'};

    @media only screen and (max-width: 992px) {
        width: 100%;
    }
`;

const FieldInput = styled.input`
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #FFF;
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    margin-bottom: 12px;
    margin-top: 6px;

    :focus {

    }
`;
const Badge = styled.div`
    color: #1A202C;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    padding: 2px 6px;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    background: #D7FBE2;
    margin-left: 6px;
`;

export default User;