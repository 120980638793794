import styled from '@emotion/styled';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Checkbox from 'react-custom-checkbox';
import axios from 'axios';
import {HOST_URL} from '../bin/env';
import axiosInstance from '../library/axios-index.js';
import SearchAddress from '../compontents/SearchAddress';
import Modal from '../compontents/Modal';
import ReactSelect from 'react-select';
import {checkInput, numberOnly} from '../bin/common';
import PopUpModal from '../compontents/PopUpModal';


const selectStyles = {
    control: base => ({
        ...base,
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #E2E8F0',
        },
    })
};

function SignUpNew() {

    const inputRef = useRef([]);
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);
    // const [tab, setTab] = useState(0);
    const [terms, setTerms] = useState({
        restriction: false,
        privacy: false,
        location: false,
        conditions: false,
        service: false
    });
    const [idCheck, setIdCheck] = useState(false);
    const [pwCheck, setPwCheck] = useState('');
    const [phoneCheck, setPhoneCheck] = useState({phone_num: '', check: false});
    const [certKey, setCertKey] = useState({certKey: '', check: false});
    const [openTerms, setOpenTerms] = useState(false);
    const [termDetail, setTermDetail] = useState('test Term');
    const [selectWorkField, setSelectWorkField] = useState(null);

    const [openPopup, setOpenPopup] = useState(false);
    const [popUpData, setPopupData] = useState({response: false, userData: null, content: null});

    const [workFiledOption, setWorkFieldOption] = useState(null);

    const [addr, setAddr] = useState('');
    const [zoneCode, setZoneCode] = useState('');
    const [logitude, setLogitude] = useState('');
    const [latitude, setLatitude] = useState('');

    const [formData, setFormData] = useState({
        // 회사 기본 정보
        corp_id: null,
        corp_pw: null,
        corp_nm: null,
        ceo_nm: null,
        manager_nm: null,
        manager_phone_num: null,
        // 현장 정보
        site_nm: null,
        const_nm: null,
        work_field: null,
        site_manager_nm: null,
        site_manager_phone_num1: '010',
        site_manager_phone_num2: null,
        site_manager_phone_num3: null,
        site_manager_phone_num: null,
        site_manager_user_seq: null,
        addr1: null,
        addr2: null,
        postal_cd: null,
        logitude: null,
        latitude: null,

        // 임시 소장 정보
        site_manager_nm_temp: null,
        site_manager_phone_num_temp: null,

        // fax_num: '',
        // email_addr: '',
        // manager_rank: '',
        // biz_reg_img: null,

    });

    const handleAddrAndZoneCode = (fullAddr, zoneCode = '') => {
        setAddr(fullAddr);
        setZoneCode(zoneCode);
    };

    const handleLogitudeAndLatitude = (logitude, latitude) => {
        setLogitude(logitude);
        setLatitude(latitude);
    };

    const handleTab = useCallback((tab) => {

        if (tab === 4) {

            navigate('/welcome', {
                state: {
                    plan: 'M0015D0001',
                },
                replace: true,
            });
            return;
        }

        setTab(tab);

    }, [tab]);

    const handleConfirmTerms = useCallback(() => {
        terms.restriction && terms.privacy && terms.location
        && terms.conditions && terms.service ? handleTab(1) : alert('필수 선택 항목이 비어있습니다');
    }, [terms]);

    const getValue = (arr, name) => arr.find(e => e.name === name).value;

    const checkDefaultInfo = useCallback(() => {

        const inputs = Array.from(document.querySelectorAll('input'));
        if (idCheck === false) {
            alert('아이디 중복 확인을 해주세요');
            return;
        }
        if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(formData.corp_pw)) {
            alert('비밀번호는 8~15 자리 영문,숫자,특수문자를 포함해야 합니다.');
            return;
        }

        if (formData.corp_pw !== pwCheck) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        if (checkInput(formData.corp_nm)) {
            alert('회사명을 입력해 주세요');
            inputRef.current[0].value = '';
            inputRef.current[0].focus();
            return;
        }

        if (checkInput(formData.ceo_nm)) {
            alert('대표자명을 입력해 주세요');
            inputRef.current[1].value = '';
            inputRef.current[1].focus();
            return;
        }
        if (checkInput(formData.manager_nm)) {
            inputRef.current[2].value = '';
            inputRef.current[2].focus();
            alert('이름을 입력해 주세요');
            return;
        }


        if (phoneCheck.check === false || certKey.check === false) {
            alert('휴대폰 번호 인증을 해주세요');
            return;
        }

        setFormData(prevState => ({
            ...prevState,
            corp_nm: formData.corp_nm.trim(),
            ceo_nm: formData.ceo_nm.trim(),
            manager_nm: formData.manager_nm.trim(),
        }));
        handleTab(2);
    }, [formData, idCheck, phoneCheck, certKey]);

    const checkSiteInfo = useCallback(() => {

        const inputs = Array.from(document.querySelectorAll('input'));

        if (checkInput(formData.const_nm)) {
            inputRef.current[3].value = '';
            inputRef.current[3].focus();
            alert('공사명을 입력해 주세요');
            return;
        }

        if (checkInput(formData.site_nm)) {
            inputRef.current[4].value = '';
            inputRef.current[4].focus();
            alert('현장명을 입력해 주세요');
            return;
        }


        if (!addr) {
            inputRef.current[5].value = '';
            inputRef.current[5].focus();
            alert('현장 주소를 입력해 주세요');
            return;
        }
        if (checkInput(formData.addr2, false)) {
            inputRef.current[5].value = '';
            inputRef.current[5].focus();
            alert('현장 주소를 입력해 주세요');
            return;
        }


        if (!selectWorkField) {
            alert('공종 선택해 주세요');
            return;
        }

        setFormData(prevState => ({
            ...prevState,
            const_nm: formData.const_nm.trim(),
            site_nm: formData.site_nm.trim(),
            addr2: formData.addr2 ? formData.addr2.trim() : null,
        }));


        handleTab(3);
    }, [formData, selectWorkField]);

    const handleFindManager = useCallback(async () => {

        const inputs = Array.from(document.querySelectorAll('input'));
        const phoneNum = getValue(inputs, 'site_manager_phone_num1') + '-'
            + getValue(inputs, 'site_manager_phone_num2') + '-'
            + getValue(inputs, 'site_manager_phone_num3');

        if (checkInput(formData.site_manager_nm)) {
            inputRef.current[6].value = '';
            inputRef.current[6].focus();
            alert('현장소장 이름을 입력해 주세요');
            return;
        }

        if (phoneNum.length < 13) {
            alert('핸드폰 번호를 확인해주세요');
            return;
        }

        setFormData(prevState => ({
            ...prevState,
            site_manager_nm: formData.site_manager_nm.trim(),
        }));


        const {data: {result}} = await axiosInstance.post('/company/get/manager-user-info', {
            user_nm: formData.site_manager_nm,
            phone_num: phoneNum.replaceAll('-', '')
        });

        let userData = null;
        let content = null;
        let response;

        if (Object.keys(result).length != 0) {
            response = true;
            userData = {
                user_seq: result.user_seq,
                user_nm: result.user_nm,
                phone_num: result.phone_num,
                photo: result.photo
            };
            content = '현장소장을 등록합니다';
        } else {
            response = false;
            userData = {user_seq: null, user_nm: formData.site_manager_nm, phone_num: phoneNum, photo: null};
            content = '대상자가 일사에 가입되어 있지 않습니다.\n먼저 등록하시겠습니까?\n\n(아래 정보로 일사 가입 요청 문자가 전송됩니다)';
        }

        setPopupData({response: response, userData: userData, content: content});

        setOpenPopup(true);

    }, [formData, popUpData]);

    const handleFindManagerOK = useCallback(async () => {
        if (popUpData.response) {
            formData.site_manager_nm_temp = null;
            formData.site_manager_phone_num_temp = null;
            formData.site_manager_nm = popUpData.userData.user_nm;
            formData.site_manager_phone_num = popUpData.userData.phone_num.replaceAll('-', '');
            formData.site_manager_user_seq = popUpData.userData.user_seq;
        } else {
            formData.site_manager_nm = null;
            formData.site_manager_phone_num = null;
            formData.site_manager_nm_temp = popUpData.userData.user_nm;
            formData.site_manager_phone_num_temp = popUpData.userData.phone_num.replaceAll('-', '');
            formData.site_manager_user_seq = popUpData.userData.user_seq;
        }

        await fetchSignup(formData);

        handleTab(4);

    }, [formData, popUpData]);

    const handleSignUp = useCallback(async () => {

        formData.site_manager_nm = null;
        formData.site_manager_phone_num1 = null;
        formData.site_manager_phone_num = null;
        formData.site_manager_nm_temp = null;
        formData.site_manager_phone_num_temp = null;

        await fetchSignup(formData);

        handleTab(4);
    }, [formData, addr, latitude, logitude]);

    const handleSelect = (value) => {

        setSelectWorkField(value);
    };
    const fetchSignup = async (formData) => {

        const inputs = Array.from(document.querySelectorAll('input'));

        formData.addr1 = addr;
        formData.logitude = logitude;
        formData.latitude = latitude;
        formData.postal_cd = zoneCode;

        setFormData(formData);

        const {data} = await axios.post(`${HOST_URL}/company/v2/sign-up`, {
            ...formData,
        });

        // return data

    };

    // 아이디 중복 확인
    const checkId = async () => {
        if (idCheck === true) return;
        if (formData.corp_id === '') {
            alert('아이디를 입력해주세요');
            return;
        }

        const regex = /^(?=.*[A-Za-z])[A-Za-z0-9]{6,12}$/;
        if (regex.test(formData.corp_id) === false) {
            alert('아이디는 6~12자리 영문, 숫자만 가능합니다.');
            return;
        }

        await axiosInstance.post('/company/checkCompanyId', {
            lang: 'ko',
            uuid: 'string',
            corp_id: formData.corp_id
        }).then(({data: {result: result}}) => {
            if (result.existed === false) {
                setIdCheck(true);
                alert('사용가능한 아이디 입니다');
            } else {
                alert('이미 사용중인 아이디 입니다');
            }
        }).catch(({response: {data: {message}}}) => {
            alert(message);
        });
    };

    // 인증번호 전송
    const sendPhoneMessage = async () => {
        if (phoneCheck.check === true) return;
        if (!formData.manager_phone_num) {
            alert('휴대폰 번호를 입력해주세요');
            return;
        }

        try {
            await axiosInstance.post('/sms/smsSend', {
                lang: 'ko',
                phone_numb: formData.manager_phone_num,
                platform: 'WEB',
            });

            inputRef.current[8].focus();
            setPhoneCheck({phone_num: formData.manager_phone_num, check: true});
            setTimeout(() => {
                if (certKey.check === false) {
                    setPhoneCheck({phone_num: formData.manager_phone_num, check: false});
                }
            }, 180000);
        } catch (e) {
            alert(e.response.data.message);
        }

    };

    // 인증번호 확인
    const checkCertKey = async () => {
        if (!certKey.certKey) {
            alert('인증번호를 입력해주세요');
            return;
        }

        await axiosInstance.post('/sms/appSmsCert', {
            lang: 'ko',
            phone_numb: formData.manager_phone_num,
            cert_numb: certKey.certKey,
        }).then(({data: {result: result}}) => {
            if (result.done === true) {
                setCertKey({certKey: certKey.certKey, check: true});
                alert('인증되었습니다');
            } else {
                inputRef.current[8].value = '';
                setTimeout(() => {
                    alert('인증번호가 일치하지 않습니다');
                }, 5);
                inputRef.current[8].focus();
            }
        }).catch(({response: {data}}) => {
            inputRef.current[8].value = '';
            setTimeout(() => {
                alert(data.message);
            }, 5);
            inputRef.current[8].focus();
        });

    };

    useEffect(() => {
        (async () => {
            const {data: {result}} = await axiosInstance.post('/code/codeList', {
                mst_cd_id: 'M0003',
                attribute1: 'Y'
            });
            const workFieldOption = result.map((workField) => {
                return {value: workField.dtl_cd_id, label: workField.dtl_cd_nm};
            });
            setWorkFieldOption(workFieldOption);
        })();
    }, []);

    return (
        <Container>
            <Wrapper>
                {tab != 5 && <Header>
                    <Step>
                        <Circle visibled={0 <= tab}>
                            <img src="/assets/icons/file-06.svg"/>
                        </Circle>
                        <StepText>
                            약관동의
                        </StepText>
                    </Step>

                    <Step>
                        <Circle visibled={1 <= tab}>
                            <img src="/assets/icons/user-check-02.svg"/>
                        </Circle>
                        <StepText>
                            기본정보
                        </StepText>
                    </Step>

                    <Step>
                        <Circle visibled={2 <= tab}>
                            <img src="/assets/icons/building-06.svg"/>
                        </Circle>
                        <StepText>
                            현장정보
                        </StepText>
                    </Step>

                    <Step>
                        <Circle visibled={3 <= tab}>
                            <img src="/assets/icons/user-check-02.svg"/>
                        </Circle>
                        <StepText>
                            소장등록
                        </StepText>
                    </Step>

                    {/* <Step>
              <Circle visibled={4 <= tab}>
                <img src='/assets/icons/flag-05.svg' />
              </Circle>
              <StepText>
                가입완료
              </StepText>
            </Step> */}
                </Header>}

                {tab === 0 && <div>
                    <Card>
                        <CardHeader>
                            <Checkbox
                                onChange={(checked) => {
                                    setTerms({
                                        restriction: checked,
                                        privacy: checked,
                                        location: checked,
                                        conditions: checked,
                                        service: checked,
                                    });
                                }}
                                icon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            backgroundColor: '#3279F5',
                                            alignSelf: 'stretch',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <img style={{width: '12px', height: '12px'}}
                                                 src="/assets/icons/ignore.svg"/>
                                        </div>
                                    </div>
                                }
                                borderColor="#3279F5"
                                borderRadius={20}
                                style={{overflow: 'hidden'}}
                                label={<div style={{paddingLeft: '12px', cursor: 'pointer'}}>이용약관에 모두 동의합니다</div>}
                                size={20}
                            />
                        </CardHeader>
                        <div>
                            <div>
                                <Title>
                                    <Checkbox
                                        checked={terms.restriction}
                                        onChange={(checked) => setTerms((e) => {
                                            e.restriction = checked;
                                            return e;
                                        })}
                                        icon={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    backgroundColor: '#3279F5',
                                                    alignSelf: 'stretch',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <img style={{width: '12px', height: '12px'}}
                                                         src="/assets/icons/ignore.svg"/>
                                                </div>
                                            </div>
                                        }
                                        borderColor="#3279F5"
                                        borderRadius={20}
                                        style={{overflow: 'hidden'}}
                                        label={<div style={{paddingLeft: '12px', cursor: 'pointer'}}>서비스 이용목적 제한방침 동의
                                            (필수)</div>}
                                        size={20}
                                    />
                                </Title>
                                <Content>
                                    본 이용목적 제한 방침은 본 서비스의 사용에 관한 허용되는 행위와 그렇지 않은 행위에 대한 명확한 가이드라인을 제시합니다.
                                    본 정책을 일부러 혹은 반복적으로 위반하거나 다른 최종이용자, 이용사업자, 본 서비스, 혹은 제 3자에게 피해를 입힐 우려가 있는 경우, 우리는 당신의
                                    액세스를 제한, 정지 혹은 종료할 수 있습니다.
                                    {/*본 정책은 합리적인 필요에 따라 변경될 수 있으니 정기적으로 업데이트와 변경 내용을 확인해주시기 바랍니다. 여기서 사용된 용어 중에서 본 정책에서 정의되지 않은 용어는 이용사업자 이용약관 혹은 최종이용자 이용약관을 참고하시기 바랍니다.*/}
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button style={{
                                            width: '16%', height: '30px', marginTop: '0px',
                                            fontSize: '12px', padding: '5px 5px'
                                        }}
                                                onClick={() => {
                                                    setTermDetail(`${HOST_URL}/rules/service-use-purpose-restriction-policy-web.html`);
                                                    setOpenTerms(true);
                                                }
                                                }>상세보기</Button>
                                    </div>
                                </Content>


                                <Title>
                                    <Checkbox
                                        checked={terms.privacy}
                                        onChange={(checked) => setTerms((e) => {
                                            e.privacy = checked;
                                            return e;
                                        })}
                                        icon={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    backgroundColor: '#3279F5',
                                                    alignSelf: 'stretch',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <img style={{width: '12px', height: '12px'}}
                                                         src="/assets/icons/ignore.svg"/>
                                                </div>
                                            </div>
                                        }
                                        borderColor="#3279F5"
                                        borderRadius={20}
                                        label={<div style={{paddingLeft: '12px', cursor: 'pointer'}}>개인정보 처리 방침 동의
                                            (필수)</div>}
                                        style={{overflow: 'hidden'}}
                                        size={20}
                                    />
                                </Title>
                                <Content>
                                    넷스프린트 주식회사는(이하 "공급사업자”라 함) 정보주체(이하 “최종이용자”라 함)의 개인정보보호를 중요시하며, 개인정보를 보호하기 위해서 “정보통신망
                                    이용촉진 및 정보보호 등에 관한 법률”, “개인정보 보호법”,
                                    “위치정보의 보호 및 이용 등에 관한 법률” 등 ...
                                    {/*정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며 관련 법령에 의거한 개인정보 처리방침을 정하여 최종이용자의 권익 보호에 최선을 다하고 있습니다.*/}
                                    {/*공급사업자는 개인정보 처리방침을 통하여 최종이용자께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.*/}
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button style={{
                                            width: '16%',
                                            height: '30px',
                                            marginTop: '0px',
                                            fontSize: '12px',
                                            padding: '5px 5px'
                                        }}
                                                onClick={() => {
                                                    setTermDetail(`${HOST_URL}/rules/privacy-policy-web.html`);
                                                    setOpenTerms(true);
                                                }}>상세보기</Button>
                                    </div>
                                </Content>

                                <Title>
                                    <Checkbox
                                        checked={terms.location}
                                        onChange={(checked) => setTerms((e) => {
                                            e.location = checked;
                                            return e;
                                        })}
                                        icon={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    backgroundColor: '#3279F5',
                                                    alignSelf: 'stretch',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <img style={{width: '12px', height: '12px'}}
                                                         src="/assets/icons/ignore.svg"/>
                                                </div>
                                            </div>
                                        }
                                        borderColor="#3279F5"
                                        borderRadius={20}
                                        style={{overflow: 'hidden'}}
                                        label={<div style={{paddingLeft: '12px', cursor: 'pointer'}}>위치기반서비스 이용 동의
                                            (필수)</div>}
                                        size={20}
                                    />
                                </Title>
                                <Content>
                                    본 약관은 주식회사 넷스프린트(이하 “공급사업자”)가 제공하는 위치기반서비스에 대해
                                    공급사업자 위치기반서비스를 이용하는 개인위치정보주체(이하 “최종이용자”)간의 권리·의무 및 책임사항, 기타 필요한 사항 규정을 목적으로 합니다.
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button style={{
                                            width: '16%',
                                            height: '30px',
                                            marginTop: '0px',
                                            fontSize: '12px',
                                            padding: '5px 5px'
                                        }}
                                                onClick={() => {
                                                    setTermDetail(`${HOST_URL}/rules/location-based-service-terms-and-conditions-web.html`);
                                                    setOpenTerms(true);
                                                }}>상세보기</Button>
                                    </div>
                                </Content>

                                <Title>
                                    <Checkbox
                                        checked={terms.conditions}
                                        onChange={(checked) => setTerms((e) => {
                                            e.conditions = checked;
                                            return e;
                                        })}
                                        icon={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    backgroundColor: '#3279F5',
                                                    alignSelf: 'stretch',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <img style={{width: '12px', height: '12px'}}
                                                         src="/assets/icons/ignore.svg"/>
                                                </div>
                                            </div>
                                        }
                                        borderColor="#3279F5"
                                        borderRadius={20}
                                        style={{overflow: 'hidden'}}
                                        label={<div style={{paddingLeft: '12px', cursor: 'pointer'}}>이용사업자 이용 동의
                                            (필수)</div>}
                                        size={20}
                                    />
                                </Title>
                                <Content>
                                    이 이용사업자 이용약관(이하 “본 약관”)은 넷스프린트 주식회사(이하 “공급사업자”)가 제공하는
                                    모바일 클라우드 기반 현장관리 솔루션 “일사(일과사람)” 및 부가서비스(이하 “본 서비스”)의 이용과 관련하여,
                                    공급사업자와 본 서비스를 이용하고자 하는 이용사업자(이하 “이용사업자”) 간의 계약관계에서 발생하는 권리와 의무,
                                    그 밖에 필요한 기본적인 사항을 규정함을 목적으로 한다.
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button style={{
                                            width: '16%',
                                            height: '30px',
                                            marginTop: '5px',
                                            fontSize: '12px',
                                            padding: '5px 5px'
                                        }}
                                                onClick={() => {
                                                    setTermDetail(`${HOST_URL}/rules/user-terms-and-conditions-web.html`);
                                                    setOpenTerms(true);
                                                }}>상세보기</Button>
                                    </div>
                                </Content>

                                <Title>
                                    <Checkbox
                                        checked={terms.service}
                                        onChange={(checked) => setTerms((e) => {
                                            e.service = checked;
                                            return e;
                                        })}
                                        icon={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    backgroundColor: '#3279F5',
                                                    alignSelf: 'stretch',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <img style={{width: '12px', height: '12px'}}
                                                         src="/assets/icons/ignore.svg"/>
                                                </div>
                                            </div>
                                        }
                                        borderColor="#3279F5"
                                        borderRadius={20}
                                        style={{overflow: 'hidden'}}
                                        label={<div style={{paddingLeft: '12px', cursor: 'pointer'}}>최종이용자 이용 동의
                                            (필수)</div>}
                                        size={20}
                                    />
                                </Title>
                                <Content>
                                    본 최종이용자 이용약관(이하 “본 약관”)은 주식회사 넷스프린트(이하 “공급사업자” 또는 “당사”) 가 제공하는 모바일 클라우드 기반 현장관리 솔루션
                                    “일사” 및 부가서비스(이하 “본 서비스”)의 이용과 관련하여, 귀하(“최종이용자”)의 접근 및 이용에 필요한 기본적 사항을 규정합니다.
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button style={{
                                            width: '16%',
                                            height: '30px',
                                            marginTop: '0px',
                                            fontSize: '12px',
                                            padding: '5px 5px'
                                        }}
                                                onClick={() => {
                                                    setTermDetail(`${HOST_URL}/rules/user-terms-of-service-web.html`);
                                                    setOpenTerms(true);
                                                }}>상세보기</Button>
                                    </div>
                                </Content>

                            </div>
                        </div>
                    </Card>

                    <Button onClick={handleConfirmTerms}>
                        동의하고 가입신청
                    </Button>
                </div>}


                {tab === 1 && <div>

                    <Card style={{padding: '20px 0'}}>
                        <Title>
                            <h3>기본 정보</h3>
                        </Title>

                        <Content>

                            <Field>
                                <label>
                                    아이디 <span>*</span>
                                    <br/>
                                    <Input
                                        name="corp_id"
                                        style={{width: '70%'}}
                                        disabled={idCheck}
                                        ref={el => (inputRef.current[7] = el)}
                                        placeholder="6~12자리 영문, 숫자"
                                        onChange={
                                            (ev) => {
                                                setFormData(prevState => ({
                                                    ...prevState,
                                                    corp_id: ev.target.value
                                                }));
                                            }
                                        }/>
                                    <FieldButton
                                        onClick={checkId}
                                        style={{width: '22%'}}
                                        disabled={idCheck}
                                    >중복확인</FieldButton>
                                </label>
                            </Field>

                            <Field>
                                <label>
                                    비밀번호 <span>*</span>
                                    <br/>
                                    <Input
                                        name="corp_pw"
                                        fulled type="password"
                                        placeholder="영문, 숫자, 특수문자를 포함한 8~16자리"
                                        onChange={(ev) => {
                                            setFormData(prevState => ({
                                                ...prevState,
                                                corp_pw: ev.target.value
                                            }));
                                        }}
                                    />
                                    {formData.corp_pw === '' || !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(formData.corp_pw) &&
                                        <div style={{color: 'red', fontSize: '12px'}}>
                                            비밀번호는 8~15 자리 영문,숫자,특수문자를 포함해야 합니다.
                                        </div>}
                                </label>
                            </Field>

                            <Field>
                                <label>
                                    비밀번호 확인 <span>*</span>
                                    <br/>
                                    <Input
                                        name="corp_pw2"
                                        fulled type="password"
                                        onChange={(ev) => {
                                            setPwCheck(ev.target.value);
                                        }}
                                    />
                                    {formData.corp_pw !== pwCheck &&
                                        <div style={{color: 'red', fontSize: '12px'}}>
                                            비밀번호가 일치하지 않습니다.
                                        </div>}
                                </label>
                            </Field>

                            <Field>
                                <label>
                                    회사명 <span>*</span>
                                    <br/>
                                    <Input name="corp_nm" ref={el => (inputRef.current[0] = el)}
                                           fulled type="text"
                                           onChange={(ev) => {
                                               setFormData(prevState => ({
                                                   ...prevState,
                                                   corp_nm: ev.target.value
                                               }));
                                           }}
                                    />
                                </label>
                            </Field>
                            <Field>
                                <label>
                                    대표자명 <span>*</span>
                                    <br/>
                                    <Input name="ceo_nm" ref={el => (inputRef.current[1] = el)}
                                           fulled type="text"
                                           onChange={(ev) => {
                                               setFormData(prevState => ({
                                                   ...prevState,
                                                   ceo_nm: ev.target.value
                                               }));
                                           }}
                                    />
                                </label>
                            </Field>

                            <Field>
                                <label>
                                    이름 <span>*</span>
                                    <br/>
                                    <Input name="manager_nm" ref={el => (inputRef.current[2] = el)}
                                           fulled type="text"
                                           onChange={(ev) => {
                                               setFormData(prevState => ({
                                                   ...prevState,
                                                   manager_nm: ev.target.value
                                               }));
                                           }}
                                    />
                                </label>
                            </Field>
                            <Field>
                                <label>
                                    휴대폰번호 <span>*</span>
                                    <br/>
                                    <Input
                                        name="manager_phone_num"
                                        style={{width: '70%'}}
                                        type="text"
                                        placeholder="- 제외한 숫자만 입력해주세요"
                                        maxLength={11}
                                        //   disabled={phoneCheck.check}
                                        onChange={(ev) => {
                                            numberOnly(ev);
                                            setFormData(prevState => ({
                                                ...prevState,
                                                manager_phone_num: ev.target.value,

                                            }));

                                            setPhoneCheck({phone_num: ev.target.value, check: false});
                                            setCertKey({certKey: '', check: false});
                                        }}
                                    />
                                    <FieldButton onClick={sendPhoneMessage} style={{width: '22%'}}
                                                 disabled={phoneCheck.check}>인증번호 전송</FieldButton>

                                    <Input
                                        name="certKey_num"
                                        style={{width: '70%'}}
                                        type="text"
                                        placeholder="인증번호 6자리를 입력해주세요"
                                        maxLength={6}
                                        ref={el => (inputRef.current[8] = el)}
                                        value={certKey?.certKey || ''}
                                        //  disabled={certKey.check}
                                        onChange={(ev) => {
                                            numberOnly(ev);
                                            setCertKey({certKey: ev.target.value, check: false});
                                        }}
                                    />
                                    <FieldButton onClick={checkCertKey} style={{width: '22%'}}
                                                 disabled={certKey.check}
                                    >인증하기</FieldButton>
                                </label>
                            </Field>

                        </Content>

                    </Card>

                    <Button onClick={checkDefaultInfo}>
                        확인
                    </Button>
                </div>}

                {tab === 2 &&
                    <div>
                        <div>
                            <Card style={{padding: '20px 0'}}>
                                <Title>
                                    <h3>현장정보</h3>
                                </Title>
                                <Content>
                                    현장에 대한 정보를 입력해 주세요.
                                </Content>
                                <Content>
                                    <Field>
                                        <label>
                                            공사명 <span>*</span>
                                            <br/>
                                            <Input name="const_nm" fulled type="text"
                                                   ref={el => (inputRef.current[3] = el)}
                                                   onChange={
                                                       (ev) => setFormData(prevState => ({
                                                           ...prevState,
                                                           const_nm: ev.target.value
                                                       }))
                                                   }/>
                                        </label>
                                    </Field>
                                    <Field>
                                        <label>
                                            현장명 <span>*</span>
                                            <br/>
                                            <Input name="site_nm" fulled
                                                   ref={el => (inputRef.current[4] = el)}
                                                   type="text" onChange={
                                                (ev) => setFormData(prevState => ({
                                                    ...prevState,
                                                    site_nm: ev.target.value
                                                }))
                                            }/>
                                        </label>
                                    </Field>
                                    <Field>
                                        <label>
                                            현장 주소 <span>*</span>
                                            <br/>
                                            <SearchAddress setAddrAndZoneCode={handleAddrAndZoneCode}
                                                           setLongitudeAndLatitude={handleLogitudeAndLatitude}>
                                            </SearchAddress>
                                            <Input name="addr1" fulled type="text" placeholder="주소찾기를 이용하세요"
                                                   readOnly={true} value={addr}/>
                                            <Input name="addr2" fulled type="text" placeholder="상세 주소" maxLength={50}
                                                   ref={el => (inputRef.current[5] = el)}
                                                   onChange={
                                                       (ev) => setFormData(prevState => ({
                                                           ...prevState,
                                                           addr2: ev.target.value
                                                       }))}
                                            />
                                        </label>
                                    </Field>
                                    <Field>
                                        <label>
                                            공종 선택 <span>*</span>
                                            {/*<br />*/}
                                            {/*<SelectWrapper>*/}
                                            <Select
                                                styles={selectStyles}
                                                name={'work_field'}
                                                placeholder={<div style={{display: 'flex', alignItems: 'center'}}>
                                                </div>}
                                                options={workFiledOption}
                                                onChange={(e) => {
                                                    handleSelect(e.value);
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        work_field: e.value
                                                    }));
                                                }}
                                            />
                                            {/*</SelectWrapper>*/}
                                        </label>
                                    </Field>
                                    <Field>
                                        더 많은 기능을 활요하기 위해 서비스 이용 중 '현장관리' &gt; '상세보기' &gt; '수정하기 에서 현장에 대한 상세 정보를 입력해주세요.
                                    </Field>
                                </Content>
                            </Card>
                            <Button onClick={() => checkSiteInfo()}>
                                확인
                            </Button>
                        </div>
                    </div>
                }

                {tab === 3 &&
                    <div>
                        <div>
                            <Card style={{padding: '20px 0'}}>
                                <Title>
                                    <h3>현장소장 등록</h3>
                                </Title>
                                <Content>
                                    현장소장을 등록해주세요
                                </Content>
                                <Content>
                                    <Field>
                                        <label>
                                            이름 <span>*</span>
                                            <br/>
                                            <Input name="site_manager_mn" fulled type="text" onChange={
                                                (ev) => {
                                                    setFormData(prevState => ({
                                                        ...prevState,
                                                        site_manager_nm: ev.target.value
                                                    }));
                                                }
                                            } ref={el => (inputRef.current[6] = el)}/>
                                        </label>
                                    </Field>
                                    <Field>
                                        <label>
                                            휴대전화 <span>*</span>
                                            <br/>
                                        </label>
                                        <Input
                                            defaultValue={formData.site_manager_phone_num1}
                                            name="site_manager_phone_num1"
                                            type="text"
                                            maxLength={'4'}
                                            onChange={(e) => {
                                                numberOnly(e);
                                                if (e.target.value.length >= e.target.maxLength) {
                                                    const nextInput = e.target.nextElementSibling;
                                                    if (nextInput) {
                                                        nextInput.focus();
                                                    }
                                                }
                                            }}
                                        /> -
                                        <Input
                                            defaultValue={formData.site_manager_phone_num2}
                                            name="site_manager_phone_num2"
                                            type="text"
                                            maxLength={'4'}
                                            onChange={(e) => {
                                                numberOnly(e);
                                                if (e.target.value.length >= e.target.maxLength) {
                                                    const nextInput = e.target.nextElementSibling;
                                                    if (nextInput) {
                                                        nextInput.focus();
                                                    }
                                                }
                                            }}
                                        /> -
                                        <Input
                                            defaultValue={formData.site_manager_phone_num3}
                                            name="site_manager_phone_num3"
                                            type="text"
                                            maxLength={'4'}
                                            onChange={(e) => {
                                                numberOnly(e);
                                                if (e.target.value.length >= e.target.maxLength) {
                                                    const nextInput = e.target.nextElementSibling;
                                                    if (nextInput) {
                                                        nextInput.focus();
                                                    }
                                                }
                                            }}
                                        />
                                        <FieldButton onClick={
                                            async () => {
                                                await handleFindManager();
                                            }
                                        }>소장검색</FieldButton>
                                    </Field>
                                    <br/>
                                    <Field>
                                        아직 현장 소장이 지정되지 않으셨나요?
                                        <br/><br/>
                                        가입 완료 이후,<br/><br/>
                                        '현장관리' &gt; '상세보기' &gt; '수정하기' &gt; '현장소장 등록'으로 현장소장을 등록 하실 수 있습니다.
                                    </Field>

                                </Content>

                            </Card>
                            <Button onClick={async () => {
                                await handleSignUp();
                            }}>
                                나중에 등록하기
                            </Button>
                        </div>
                    </div>
                }

                {tab === 4 && <div>

                    <div>

                        <Card style={{
                            padding: '20px 0',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>

                            <img src="/assets/icons/check-circle-broken-02.svg"/>

                            <Title style={{marginTop: '24px'}}>
                                <h3>가입완료</h3>
                            </Title>
                            <Content style={{textAlign: 'center', marginTop: '8px'}}>
                                <p>환영합니다!</p>
                                <p>회원가입을 축하합니다.</p>
                                <p>스마트폰에서 일사 앱으로 현장을 관리하세요.</p>
                                <p>(앱 첫화면 상단 오른쪽 '관리자로 전환'을 클릭해 현장을 앱으로 관리 할 수 있습니다.)</p>
                                <p>근로계약서가 유효하려면 '법인 직인' 등록이 필수입니다.</p>
                            </Content>
                        </Card>

                    </div>

                    <Button onClick={() => handleTab(5)}>
                        확인
                    </Button>
                </div>}

            </Wrapper>

            <Modal
                opend={openTerms}
                closeModal={() => setOpenTerms(false)}
                header={'상세 정보'}
                okText={'확인'}
                cancelText={''}
                handleOk={() => {
                }}
                widthCheck={'55%'}
            >
                <iframe id="termDetails" style={{width: '100%', height: '600px'}} frameborder="0"
                        src={termDetail}></iframe>
            </Modal>

            <PopUpModal
                opend={openPopup}
                closeModal={() => setOpenPopup(false)}
                header={'현장소장 등록'}
                okText={'등록하기'}
                cancelText={'취소'}
                handleOk={async () => {
                    await handleFindManagerOK();
                    setOpenPopup(false);
                }}
                widthCheck={'30%'}
                heightCheck={'40%'}
            >
                <Title style={{whiteSpace: 'pre-wrap'}}>{popUpData.content}</Title>
                <Title style={{whiteSpace: 'pre-wrap', color: 'red', marginTop: '10px'}}>
                    {popUpData.response == false ? '* 회원가입 완료 시 문자가 전송됩니다.' : ''}
                </Title>
                <br/>

                {
                    popUpData?.userData != null ?
                        <LaborCard>
                            <Avatar style={{
                                marginRight: '3px',
                                width: '64px',
                                height: '64px',
                                backgroundImage: `url(${popUpData?.userData.photo || '/assets/images/profile.png'})`
                            }}/>
                            <LaborCardContent>
                                {/*<h5>{JSON.stringify(popUpData)}</h5>*/}
                                <h4>{popUpData?.userData.user_nm} / {popUpData?.userData.phone_num}</h4>
                            </LaborCardContent>
                        </LaborCard>
                        :
                        <LaborCard>
                            <div/>
                        </LaborCard>
                }


            </PopUpModal>
        </Container>
    );
}

export default SignUpNew;

const Container = styled.div`
    background: #F7FAFC;
    min-height: 100vh;
    height: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 60px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
`;

const Step = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Circle = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    margin-bottom: 4px;
    //cursor: pointer;

    img {
        width: 24px;
        height: 24px;
    }

    background: ${({visibled}) => visibled ? '#3279F5' : '#BFD5FC'};
`;

const StepText = styled.div`
    color: #2D3748;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const Button = styled.button`
    display: flex;
    width: 100%;
    height: 32px;
    margin-top: 24px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3279F5;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    border: none;
    text-decoration: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    padding: 0 24px;

    h3 {
        margin: 0;
        padding: 0;
    }
`;

const Card = styled.div`
    background: #fff;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    padding-bottom: 20px;
`;

const Content = styled.div`
    margin: 16px 0;
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 24px;

    h5 {
        margin: 0;
        margin-bottom: 16px;
        color: #2D3748;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
    }

    p {
        margin: 0;
        padding: 0;
        color: #4A5568;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    span {
        color: #E53E3E
    }
`;

const CardHeader = styled.div`
    display: flex;
    align-items: center;
    background: #F7FAFC;
    padding: 24px;
    margin-bottom: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    border-radius: 12px 12px 0 0;
    line-height: 24px;
`;


const Input = styled.input`
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 20%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #FFF;
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    margin-bottom: 4px;
    margin-top: 6px;

    :focus {

    }

    ${({fulled}) => fulled ? `width: 100%;` : ''}
`;

const Field = styled.div`
    margin-bottom: 16px;

`;

const FieldButton = styled.div`
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: inline-block;
    padding: 8px;
    border-radius: 6px;
    background: #4A5568;
    margin-left: 8px;
    cursor: pointer;
    text-align: center;

    ${props => props.disabled && `
  background: #CBD5E0;
  cursor: default;
`}
`;

const SelectWrapper = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #718096;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-right: 6px;
    }
`;
const Select = styled(ReactSelect)`
    color: #4A5568;
    leading-trim: both;
    text-edge: cap;
    border-radius: 6px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    background: #FFF;
    margin-top: 5px;

    div {
        cursor: pointer;
        color: #4A5568;
        border-color: #E2E8F0;
    }
`;

const Avatar = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-left: 8px;
`;

const LaborCard = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 14px 0;
    //cursor: pointer;

    //&:hover {
    //  background-color: #EBF2FE !important;
    //  border-radius: 4px;
    //}
`;

const LaborCardContent = styled.div`
    h5, h6 {
        margin: 0;
    }

    h5 {
        color: #4A5568;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px
    }

    h6 {
        color: #718096;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
`;