import styled from '@emotion/styled';
import {forwardRef, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {addCommas} from '../../bin/common';

const ExpenseStatusPagePrint = forwardRef((props, ref) => {
    const [isImprests, setIsImprests] = useState([]);
    const [isDailyCashAmt, setIsDailyCashAmt] = useState({});
    // console.log('ExpenseStatusPagePrint');

    const list = props.data.imprests;
    const amt = props.data.amt;
    const dailyCashAmt = props.data.dailyCashAmt;
    useEffect(() => {
        setIsImprests(list.slice().reverse());
        setIsDailyCashAmt(dailyCashAmt.slice().reverse());
    }, [list, amt, dailyCashAmt]);
    return (
        <Container ref={ref}>

            <Header>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Title>{dayjs(props.data.date).format('YYYY년 MM월')}분 전도금 사용내역서</Title>

                    <Text>
                        <strong>현장명 </strong> <span>{props.data.site}</span>
                    </Text>
                </div>

                <div>
                </div>
            </Header>

            <TableContainer>
                <TableWrapper>
                    <Table>
                        <thead>

                        <tr>
                            <th rowSpan={2}>사용일자</th>
                            <th rowSpan={2}>적요</th>
                            <th rowSpan={2}>입금</th>
                            <th colSpan={3}>지출</th>
                            <th rowSpan={2}>현금잔액</th>
                            <th rowSpan={2}>비고</th>
                        </tr>
                        <tr>
                            <th>법인카드</th>
                            <th>현금</th>
                            <th className={'print_border'}>계좌이체</th>
                        </tr>
                        </thead>
                        <tbody>

                        {/* 리스트 출력 */}
                        {!isDailyCashAmt || isImprests?.length > 0 && isImprests.map((e, i) => (
                            <tr key={i}>
                                <td className={''}>
                                    {dayjs(e.imp_dt).format('YYYY년 M월 D일')}
                                </td>
                                <td className={'table-left'}>{e.class_seq2.class_nm}</td>
                                <td className={'table-right'}>{e.imp_type === 'I' ? e.imp_amt.toLocaleString() + '원' : ''}</td>
                                <td className={'table-right'}>{e.imp_type === 'S' && e.method === 'M0007D0001' ? e.imp_amt.toLocaleString() + '원' : '-'}</td>
                                <td className={'table-right'}>{e.imp_type === 'S' && e.method === 'M0007D0002' ? e.imp_amt.toLocaleString() + '원' : '-'}</td>
                                <td className={'table-right'}>{e.imp_type === 'S' && e.method === 'M0007D0003' ? e.imp_amt.toLocaleString() + '원' : '-'}</td>
                                <td className={'table-right'}>{addCommas(isDailyCashAmt[i].amt)}원</td>
                                <td style={{textAlign: 'center'}}>{e.desc}</td>
                            </tr>
                        ))}

                        <tr>
                            <th>월계</th>
                            <th></th>
                            <th className={'table-right'}>{amt.monthly_input_amt.toLocaleString()}원</th>
                            <th colSpan={3} className={'table-right'}>{amt.monthly_spend_amt.toLocaleString()}원</th>
                            <th className={'table-right'}>{amt.monthly_balance.toLocaleString()}원</th>
                            <th></th>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <th>누계</th>
                            <th></th>
                            <th className={'table-right'}>{amt.total_input_amt.toLocaleString()}원</th>
                            <th colSpan={3} className={'table-right'}>{amt.total_spend_amt.toLocaleString()}원</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </tfoot>

                    </Table>
                </TableWrapper>
            </TableContainer>

        </Container>
    );
});

const Container = styled.div`
    // border: 1px solid #E2E8F0;
    background: #FFF;
    padding: 20px 24px;
    @page {
        size: A4 portrait !important;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    @media print {
        table th {
            background-color: #EDF2F7 !important;
            -webkit-print-color-adjust: exact;
        }

        .table-wrapper {
            break-after: page;
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const Title = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
    display: flex;
`;

const Badge = styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: flex-start;
    border-radius: 6px;
    background: #F7FAFC;

    &:not(:last-child) {
        margin-right: 4px;
    }
`;

const Label = styled.div`
    color: #718096;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    margin-right: 4px;
`;

const Text = styled.div`
    color: #171923;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
`;

const TableContainer = styled.div`
`;

const TableWrapper = styled.div`
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #000;
    overflow-x: auto;
    box-sizing: border-box;
`;
const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;


    th, td {
        text-align: left;
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;

        &:not(:last-child) {
            //border-bottom: none; 

        }
    }

    th {
        background-color: #EDF2F7;
        color: #4A5568;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;

    }

    td {
        color: #1A202C;
        padding: 5px;
    }

    tr:last-child td {
        //border-bottom: none;
    }

    tfoot th {
        border-bottom: none;
        font-weight: bold;
        text-align: right;
    }

    tr {
        height: 20px;
    }
`;

export default ExpenseStatusPagePrint;